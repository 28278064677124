const CityReportsTable = ({reports}) => {
  return (
    <div className="flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                >
                  ГОРОД
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  КОЛ-ВО ПРЕДМЕТОВ
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  КОЛ-ВО ГРУПП
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  УЧАСТНИКОВ
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  МАЛЬЧИКОВ
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280] pl-3 pr-4 sm:pr-6"
                >
                  ДЕВОЧЕК
                </th>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {reports.map((item) => (

                <tr key={item.id}>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                    {item['Город']}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                    {item['Кол-во предметов']}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                    {item['Кол-во групп']}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                    {item['Участники']}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                    {item['Мальчики']}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-center pl-3 pr-4 sm:pr-6">
                    {item['Девочки']}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityReportsTable;
