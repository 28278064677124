import React from "react";

const ClusterReportsTable = ({ reports, direction }) => {
  return (
    <div className="flow-root overflow-x-auto">
      <div className="-my-2 -mx-4 sm:-mx-6  lg:-mx-8">
        <div className="block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
            <table className="min-w-full divide-y divide-gray-300 ">
              <thead className="bg-gray-50 ">
                <tr>
                  <th
                    scope="col"
                    className="py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                  >
                    ГОРОД
                  </th>
                  {direction.map(item =>
                    <th key={item.direction.id}
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    {item.direction.name.toUpperCase()}
                  </th>)}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {reports.map((item) => (
                  <tr key={item['Город']}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
                      {item['Город']}
                    </td>
                    {direction.map(obj =>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                      {item['Направления'].length === 0 ? '' : item['Направления'].map(title => title['Название'] === obj.direction.name ? title['Кол-во групп'] : '')}
                    </td>)}
                    {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">*/}
                    {/*  {item['']}*/}
                    {/*</td>*/}
                    {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">*/}
                    {/*  {item['']}*/}
                    {/*</td>*/}
                    {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">*/}
                    {/*  {item['']}*/}
                    {/*</td>*/}
                    {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">*/}
                    {/*  {item['']}*/}
                    {/*</td>*/}
                    {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">*/}
                    {/*  {item['']}*/}
                    {/*</td>*/}
                    {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">*/}
                    {/*  {item['']}*/}
                    {/*</td>*/}
                    {/*<td className="whitespace-nowrap px-3 py-4 text-xs text-center pl-3 pr-4 sm:pr-6">*/}
                    {/*  {item['']}*/}
                    {/*</td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClusterReportsTable;
