import {ReactComponent as ClipBoardIcon} from "../../assets/icons/main-clipboard-icon.svg";
import {ReactComponent as LibIcon} from "../../assets/icons/main-lib-icon.svg";
import {ReactComponent as UserGroup} from "../../assets/icons/main-usergroup-icon.svg";
import {ReactComponent as MenIcon} from "../../assets/icons/main-men-icon.svg";
import {ReactComponent as WomenIcon} from "../../assets/icons/main-women-icon.svg";
import {ReactComponent as UserIcon} from "../../assets/icons/main-user-icon.svg";
import {ReactComponent as GroupsIcon} from "../../assets/icons/total-groups-icon.svg";
import {ReactComponent as SubjectsIcon} from "../../assets/icons/total-subjects-icon.svg";
import {ReactComponent as DirectionsIcon} from "../../assets/icons/total-directions-icon.svg";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import AuthContext from "../../context/AuthProvider";


const Main = () => {

  const {token} = useContext(AuthContext);

  const [role, setRole] = useState('')
  const [statsData, setStatsData] = useState([])
  const [briefData, setBriefData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const getGeneral = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get('https://api.upshift.colibri.tj/api/home/general', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setStatsData(res.data)
      setIsLoading(false)
    } catch (err) {
      if (err.status === 403) {
        alert('Вы не авторизованы')
        setIsLoading(false)
        window.location.reload()
      }
    }
  }
  const getBrief = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get('https://api.upshift.colibri.tj/api/home/brief', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setBriefData(res.data)
      setIsLoading(false)
    } catch (err) {
      if (err.status === 403) {
        alert('Вы не авторизованы')
        window.location.reload()
        setIsLoading(false)

      }
    }
  }
  const general = ['Администратор', 'Ментор', 'Партнёр']
  const brief = ['Учитель', 'ЦДО', 'Республиканский ЦДО']

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (!user?.position) {
      setRole(user?.role[0])
      if (general.some(item => item === role)) {
        getGeneral()
      } else {
        getBrief()
      }
    }
  }, [role])

  const stats = [
    {
      id: 1,
      name: "Количество проектов",
      stat: statsData.total_projects,
      icon: ClipBoardIcon,
    },
    {
      id: 2,
      name: "Общий бюджет",
      stat: statsData.total_budget,
      icon: LibIcon,
    },
    {
      id: 3,
      name: "Общее количество участников",
      stat: statsData.total_members,
      icon: UserGroup,
    },
    {
      id: 4,
      name: "Количество мальчиков",
      stat: statsData.total_boys,
      icon: MenIcon,
    },
    {
      id: 5,
      name: "Количество девочек",
      stat: statsData.total_girls,
      icon: WomenIcon,
    },
    {
      id: 6,
      name: "Количество партнёров",
      stat: statsData.total_partners,
      icon: UserIcon,
    },
  ];

  const briefArr = [
    {
      id: 1,
      name: "Общее количество мальчиков",
      stat: briefData.total_boys,
      icon: MenIcon,
    },
    {
      id: 2,
      name: "Общее количество девочек",
      stat: briefData.total_girls,
      icon: WomenIcon,
    },
    {
      id: 3,
      name: "Общее количество участников",
      stat: briefData.total_member,
      icon: UserGroup,
    },
    {
      id: 4,
      name: "Общее количество групп",
      stat: briefData.total_groups,
      icon: GroupsIcon,
    },
    {
      id: 5,
      name: "Общее количество предметов",
      stat: briefData.total_subjects,
      icon: SubjectsIcon,
    },
    {
      id: 6,
      name: "Общее количество направлений",
      stat: briefData.total_directions,
      icon: DirectionsIcon,
    },
  ];

  return (
    <>
      <h1 className="text-2xl font-semibold text-primary">Главное</h1>
      <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {(general.some(item => item === role) ? stats : briefArr).map((item) => (
          <div
            key={item.id}
            className="overflow-hidden flex flex-col rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:py-6"
          >
            <div className="flex items-center w-full gap-5">
              <div
                className="flex items-center relative w-[48px] h-[48px] justify-center rounded-md bg-primary p-[15px]">
                <item.icon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-6 w-6"/>
              </div>
              <div className="flex flex-col">
                <h2 className="text-sm text-[#6B7280] font-medium">
                  {item.name}
                </h2>
                <span className="text-2xl text-[#111827] font-semibold">
                  {item.stat}
                </span>
              </div>
            </div>
          </div>
        ))}
        {/*{(role === ' Учитель' || 'ЦДО' || 'Республиканский ЦДО') ? brief.map((item) => (*/}
        {/*  <div*/}
        {/*    key={item.id}*/}
        {/*    className="overflow-hidden flex flex-col rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:py-6"*/}
        {/*  >*/}
        {/*    <div className="flex items-center w-full gap-5">*/}
        {/*      <div className="flex items-center relative w-[48px] h-[48px] justify-center rounded-md bg-primary p-[15px]">*/}
        {/*        <item.icon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-6 w-6" />*/}
        {/*      </div>*/}
        {/*      <div className="flex flex-col">*/}
        {/*        <h2 className="text-sm text-[#6B7280] font-medium">*/}
        {/*          {item.name}*/}
        {/*        </h2>*/}
        {/*        <span className="text-2xl text-[#111827] font-semibold">*/}
        {/*          {item.stat}*/}
        {/*        </span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*))  : null}*/}
      </dl>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr className="divide-x">
                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs tracking-wider font-medium text-[#6B7280]"
                  >
                    {statsData.length !== 0 ? `НАЗВАНИЕ` : 'ЦДО'}
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-6 text-center text-xs tracking-wider font-medium text-[#6B7280]"
                  >
                    {statsData.length !== 0 ? 'КОЛИЧЕСТВО ПРОЕКТОВ' : 'ГРУППЫ'}
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-6 text-center text-xs tracking-wider font-medium text-[#6B7280]"
                  >
                    {statsData.length !== 0 ? 'БЮДЖЕТ' : 'УЧАСТНИКИ'}
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {statsData.length !== 0 &&
                  general.some(item => item === role) && statsData.partner_data.map((project, index) => (
                    <tr key={index} className="divide-x">
                      <td className="text-left whitespace-nowrap py-4 px-6 text-sm font-medium text-primary">
                        {statsData ? project?.name : briefData ? project?.pec_name : ''}
                      </td>
                      <td className="text-center whitespace-nowrap py-4 px-6 text-sm text-[#6B7280]">
                        {statsData ? project?.project_count : briefData ? project?.group_count : ''}
                      </td>
                      <td className="text-center whitespace-nowrap py-4 px-6 text-sm text-[#6B7280]">
                        {statsData ? project?.total_project_budget : briefData ? project?.group_members_count : ''}
                      </td>
                    </tr>))}
                {briefData.length !== 0 &&
                  brief.some(item => item === role) && briefData?.pec_data?.map((item, index) =>
                    <tr key={index} className="divide-x">
                      <td className="text-left whitespace-nowrap py-4 px-6 text-sm font-medium text-primary">
                        {item?.pec_name}
                      </td>
                      <td className="text-center whitespace-nowrap py-4 px-6 text-sm text-[#6B7280]">
                        {item?.group_count}
                      </td>
                      <td className="text-center whitespace-nowrap py-4 px-6 text-sm text-[#6B7280]">
                        {item?.group_members_count}
                      </td>
                    </tr>)}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
