import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  showEdit: false
}

const projectInfoSlice = createSlice({
  name: 'projectInfoSlice',
  initialState,
  reducers: {
    setShowEdit (state, action) {
      state.showEdit = action.payload
    }
  }
})

export const {setShowEdit} = projectInfoSlice.actions

export default projectInfoSlice.reducer