import {Fragment, useContext} from "react";
import {Menu, Transition} from "@headlessui/react";
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as SettingIcon} from "../../assets/icons/setting-icon.svg";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderDropdown() {
  const {token, setToken} = useContext(AuthContext);
  const navigate = useNavigate();

  const logOut = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "auth/logout",
        {},
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      setToken("");
      localStorage.removeItem("token");
      localStorage.removeItem("user")
      navigate("/login");
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  };

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="flex items-center justify-center">
          <SettingIcon/>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 mt-2 w-[93px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({active}) => (
                <Link
                  to="/profile"
                  className={classNames(
                    active ? "bg-gray-100 text-[#374151]" : "text-[#374151]",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Профиль
                </Link>
              )}
            </Menu.Item>
            <form onSubmit={(e) => logOut(e)}>
              <Menu.Item>
                {({active}) => (
                  <button
                    type="submit"
                    onClick={(e) => logOut(e)}
                    className={classNames(
                      active ? "bg-gray-100 text-[#374151]" : "text-[#374151]",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    Выйти
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
