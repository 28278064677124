import {useEffect, useState} from "react";
import {ReactComponent as DownloadIcon} from "../../assets/icons/download-icon.svg";
import {useGetSubjectReportQuery} from "../../redux/api/reportsApi";
import {SubjectReportTable} from "./components/SubjectReportTable";
import html2pdf from "html2pdf.js";

const SubjectReports = () => {

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [subjectsReports, setSubjectsReports] = useState([])
  const [showButton, setShowButton] = useState(true)


  const {data: subjectsReportData, isLoading: subjectsLoading} = useGetSubjectReportQuery({startDate, endDate})

  useEffect(() => {
    if (subjectsLoading) {
      setSubjectsReports([])
    }
    if (!subjectsLoading && subjectsReportData) {
      setSubjectsReports(subjectsReportData)
    }
  }, [subjectsLoading, subjectsReportData])

  const handleConvertToPdf = (e) => {
    e.stopPropagation()
    const element = document.getElementById('subject-report')
    if (element) {
      setShowButton(false)
      html2pdf().from(element).save('subject-report.pdf')
    }
  }


  return (
    <div className="flex flex-col h-full" id='subject-report' onClick={()=>setShowButton(true)}>
      <div className="flex flex-col gap-4 mb-4">
        <h1 className="font-semibold ml-3 text-primary text-2xl">
          Отчет по предметам
        </h1>
        <div className="flex items-center justify-between">
          <div className="flex ml-3 gap-4 items-center">
            {showButton ? <input
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              className="block w-[50%] xl:min-w-[235px] rounded-md border-0 py-[11px] text-[25px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset"
            /> : <p>{startDate}</p>}
            {showButton ? <input
              type="date"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              className="block w-[50%] xl:min-w-[235px] rounded-md border-0 py-[11px] text-[25px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset"
            /> : <p>{endDate}</p>}
          </div>
          <div className="flex gap-4 items-center">
            {showButton && <button onClick={handleConvertToPdf} className="flex items-center gap-3 bg-[#EEF2FF] hover:bg-[#f5f7fc] rounded-md py-[9px] px-[15px]">
              <DownloadIcon/>
              <span className="font-medium text-primary">Скачать отчет</span>
            </button>}
          </div>
        </div>
      </div>
      <div className="flow-root" id='city-report'>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                  >
                    ПРЕДМЕТ
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    КОЛ-ВО ГРУПП
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    УЧАСТНИКОВ
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    МАЛЬЧИКОВ
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280] pl-3 pr-4 sm:pr-6"
                  >
                    ДЕВОЧЕК
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {subjectsReports.map(item => <SubjectReportTable key={item.id} item={item}/>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/*{totalReports > 8 && (*/}
      {/*  <Pagination*/}
      {/*    totalPage={totalReports / 8}*/}
      {/*    setCurrentPage={setCurrentPage}*/}
      {/*    totalObjects={totalReports}*/}
      {/*    to={8}*/}
      {/*    from={1}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default SubjectReports;
