import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const editProjectApi = createApi({
  reducerPath: 'editProjectApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.upshift.colibri.tj/api/`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  endpoints: build => ({
    editProject: build.mutation({
      query: ({id, ...body}) => ({
        url: `projects/${id}`,
        method: 'PUT',
        body,
      })
    }),
    editPhoto: build.mutation({
      query: ({id, ...body}) => ({
        url: `projects/${id}/update-image`,
        method: 'POST',
        body: body.photoFormData
      })
    }),
    editDoc: build.mutation({
      query: ({id, ...body}) => ({
        url: `projects/${id}/update-documents`,
        method: 'POST',
        body: body.docFormData
      })
    })
  })
})

export const {useEditProjectMutation, useEditPhotoMutation, useEditDocMutation} = editProjectApi;
