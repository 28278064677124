import {Link} from "react-router-dom";
import {ReactComponent as AddMemberIcon} from "../../../assets/icons/add-member-icon.svg";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit-icon.svg";
import {EditModal} from "../modals/EditModal";
import {useState} from "react";


export const NewCourseTable = ({group, roles}) => {

  const [showEdit, setShowEdit] = useState(false)

  const doneStatusStyle =
    "text-center inline-block  bg-[#D1FAE5] py-0.5 px-2.5 rounded-[10px] text-[#065F46] font-medium w-[107px]";
  const creatingStatusStyle =
    "text-center inline-block  bg-[#FEE2E2] py-0.5 px-2.5 rounded-[10px] text-[#991B1B] font-medium w-[107px]";
  // const planningStatusStyle =
  //   "text-center inline-block  bg-[#FEF3C7] py-0.5 px-2.5 rounded-[10px] text-[#92400E] font-medium w-[107px]";
  // const testingStatusStyle =
  //   "text-center inline-block  bg-[#F3F4F6] py-0.5 px-2.5 rounded-[10px] text-[#1F2937] font-medium w-[107px]";
  // const researchStatusStyle =
  //   "text-center inline-block  bg-[#DBEAFE] py-0.5 px-2.5 rounded-[10px] text-[#1E40AF] font-medium w-[107px]";

  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  return (
    <>
      <tr key={group.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
          {group.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
          {group.teacher.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
          {group.subject.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
          {group.members.length}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
          {new Date(group.start)
            .toLocaleDateString("en-gb")
            .replaceAll("/", ".")}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
          {new Date(group.end)
            .toLocaleDateString("en-gb")
            .replaceAll("/", ".")}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-xs text-center">
                      <span
                        className={group.status === "inactive" ? creatingStatusStyle : doneStatusStyle}>
                        {group.status === 'active' ? 'Активный' : 'Неактивный'}
                      </span>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
          {roles.includes('update-group') && <button onClick={() => setShowEdit(true)}
                   className="text-primary hover:text-secondary"
          >
            <EditIcon/>
          </button>}
          {roles.includes('show-group') && <Link to={`/groups/${group.id}`}>
            <button
              className="text-primary ml-3 hover:text-secondary"
            >
              <AddMemberIcon/>
            </button>
          </Link>}
        </td>
        <EditModal {...group} showEdit={showEdit} setShowEdit={setShowEdit}/>
      </tr>
    </>
  )
}
