import { ReactComponent as EyeIcon } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as CrossedEyeIcon } from "../../assets/icons/crossed-eye-icon.svg";
import { ReactComponent as PencilIcon } from "../../assets/icons/pencil-icon.svg";
import { Transition } from "@headlessui/react";
import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/AuthProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useGetProfileQuery} from "../../redux/api/profileApi";
import axios from "axios";
import qs from 'qs'
import {useNavigate} from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate()
  const [editingStatus, setEditingStatus] = useState(false);

  const [pwdChecked, setPwdChecked] = useState(false);

  const { userInfo } = useContext(AuthContext);
  const [errMsg, setErrMsg] = useState("");
  const [pwdType, setPwdType] = useState("password");
  const [repeatPwdType, setRepeatPwdType] = useState("password");
  const [profile, setProfile] = useState([])
  const [loading, setLoading] = useState(false)
  const [roleName, setRoleName] = useState('')

  const schema = yup.object().shape({
    full_name: yup.string().required("Поле не должно быть пустым"),
    phone: yup
      .string()
      .required("Поле не должно быть пустым")
      .matches(/^[0-9]+$/, "Необходимо ввести только цифры")
      .min(9, "Необходимо ввести 9 символов")
      .max(9, "Необходимо ввести 9 символов"),
    password: yup
      .string()
      .min(8, "Необходимо ввести минимум 8 символов"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
    email: yup.string().required("Поле не должно быть пустым"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {data:userProfileData, isLoading, refetch} = useGetProfileQuery(userInfo.id)

  useEffect(() => {
    if(!isLoading && userProfileData) {
      setProfile(userProfileData?.data?.user)
      setRoleName(userProfileData?.data?.user?.role[0])
      setValue('full_name', profile?.full_name)
      setValue('phone', profile?.phone)
      setValue('email', profile?.email)
    }
  },[isLoading, userProfileData, setValue, profile])


  const handlePwdEyeClick = () => {
    if (pwdType === "password") {
      setPwdType("text");
    } else {
      setPwdType("password");
    }
  };
  const handleRepeatPwdEyeClick = () => {
    if (repeatPwdType === "password") {
      setRepeatPwdType("text");
    } else {
      setRepeatPwdType("password");
    }
  };

  const handleCancel = () => {
    setEditingStatus(false);
  };

  const {token} = useContext(AuthContext);

  const updateProfile = async (data) => {
    const resultData = {}
    if(data.full_name !== profile?.full_name) {
      resultData.full_name = data.full_name
    }
    if(data.email !== profile?.email) {
      resultData.email = data.email
    }
    if (data.phone !== profile?.phone) {
      resultData.phone = data.phone
    }
    if(data.password === data.password_confirmation) {
      resultData.password = data.password
      resultData.password_confirmation = data.password_confirmation
    }
    try {
      setLoading(true)
      const serializedData = qs.stringify(resultData)
      await axios.put(`https://api.upshift.colibri.tj/api/users/${userInfo.id}`, serializedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      });
      setEditingStatus(false)
      refetch()
      navigate('/login')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      setLoading(false)
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
      setLoading(false)
    }
  };

  // const onSubmit = async (data) => {
  //   const formData = new FormData()
  //   formData.append('full_name', data.full_name)
  //   formData.append('email', data.email)
  //   formData.append('phone', data.phone)
  //   formData.append('password', data.password)
  //   try {
  //     await editProfile({id: userInfo.id, formData}).unwrap()
  //     // setEditingStatus(false)
  //     alert('Успех!')
  //   } catch (err) {
  //     alert('Ошибка при изменении профиля')
  //   }
  // };

  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between min-h-[38px]">
        <h1 className="font-semibold text-primary text-2xl">Профиль</h1>
        <Transition
          show={!editingStatus}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <button
            disabled={editingStatus}
            onClick={() => setEditingStatus(true)}
            className={`flex items-center justify-center py-[9px] px-4 gap-2 bg-white ring-1 ring-[#D1D5DB] rounded-md`}
          >
            <PencilIcon />
            <span className="text-sm font-medium text-[#374151]">Изменить</span>
          </button>
        </Transition>
      </div>
      <div className="flex flex-col min-h-[93%] bg-white rounded-lg mt-6 pt-8 px-6 w-full">
        <form onSubmit={handleSubmit(updateProfile)} className="flex flex-col">
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-6 w-full">
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  className="block text-sm font-medium text-[#374151]"
                  htmlFor="full_name"
                >
                  ФИО
                </label>
                <input
                  className={errors.full_name ? errInputText : inputTextStyle}
                  type="text"
                  {...register("full_name")}
                  disabled={!editingStatus}
                  onKeyDown={(e) => {
                    if (e.key >= 0 && e.key <= 9 && e.key !== " ") {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.full_name && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.full_name.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  className="block text-sm font-medium text-[#374151]"
                  htmlFor="role"
                >
                  Роль
                </label>
                <input
                  className='inputTextStyle'
                  type="text"
                  defaultValue={roleName}
                  disabled={true}
                />
              </div>
            </div>
            <div className="flex items-center gap-6 w-full">
              <div className="flex flex-col gap-1 relative w-[50%]">
                <label
                  className="block text-sm font-medium text-[#374151]"
                  htmlFor="address"
                >
                  Почта
                </label>
                <input
                  type="text"
                  id="address"
                  className={errors.email ? errInputText : inputTextStyle}
                  {...register("email")}
                  disabled={!editingStatus}
                />
                {errors.email && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  className="block text-sm font-medium text-[#374151]"
                  htmlFor="phone"
                >
                  Номер телефона
                </label>
                <div className="flex rounded-md">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-sm">
                    +992
                  </span>
                  <input
                    className={
                      errors.phone || errMsg
                        ? `${errInputText} rounded-l-none`
                        : `${inputTextStyle} rounded-l-none`
                    }
                    type="text"
                    id="phone"
                    {...register("phone")}
                    onKeyDown={() => setErrMsg("")}
                    disabled={!editingStatus}
                  />
                </div>
                {errors.phone && errors.phone && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.phone.message}
                  </p>
                )}
                {errMsg && !errors.phone && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errMsg}
                  </p>
                )}
              </div>
            </div>
            <Transition
              show={editingStatus}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="flex flex-col">
                <div className="flex items-center gap-2 mb-6">
                  <input
                    id="checkedPwd"
                    name="checkedPwd"
                    type="checkbox"
                    onChange={() => setPwdChecked(!pwdChecked)}
                    className="h-4 w-4 rounded border-gray-300 text-[#39044E] focus:ring-[#39044E]"
                  />
                  <label htmlFor="checkedPwd">Изменить пароль</label>
                </div>
                <div className={`flex items-center gap-6 w-full`}>
                  <div className="flex flex-col gap-1 w-[50%] relative">
                    <label
                      className="block text-sm font-medium text-[#374151]"
                      htmlFor="password"
                    >
                      Новый пароль
                    </label>
                    <input
                      type={pwdType}
                      defaultValue={""}
                      disabled={!pwdChecked}
                      {...register("password")}
                      className={
                        errors.password ? errInputText : inputTextStyle
                      }
                    />
                    <button
                      type="button"
                      className="absolute top-[33px] right-[13px]"
                      onClick={handlePwdEyeClick}
                    >
                      {pwdType === "password" ? (
                        <EyeIcon />
                      ) : (
                        <CrossedEyeIcon />
                      )}
                    </button>
                    {errors.password && (
                      <p className="text-xs text-red-600 absolute bottom-[-22px]">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col gap-1 w-[50%] relative">
                    <label
                      className="block text-sm font-medium text-[#374151]"
                      htmlFor="confirm_password"
                    >
                      Повторите пароль
                    </label>
                    <input
                      defaultValue={""}
                      type={repeatPwdType}
                      disabled={!pwdChecked}
                      {...register('password_confirmation')}
                      className={
                        errors.password_confirmation ? errInputText : inputTextStyle
                      }
                    />
                    <button
                      type="button"
                      className="absolute top-[33px] right-[13px]"
                      onClick={handleRepeatPwdEyeClick}
                    >
                      {repeatPwdType === "password" ? (
                        <EyeIcon />
                      ) : (
                        <CrossedEyeIcon />
                      )}
                    </button>
                    {errors.confirm_password && (
                      <p className="text-xs text-red-600 absolute bottom-[-22px]">
                        {errors.confirm_password.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Transition>
          </div>
          <Transition
            show={editingStatus}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className={`flex justify-end items-center mt-8 `}>
              <button
                onClick={handleCancel}
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-[#FFFFFF] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Отмена
              </button>
              <button disabled={loading}
                type="submit"
                className={loading ? "opacity-50 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30" : "opacity-100 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"}
              >
                Сохранить
              </button>
            </div>
          </Transition>
        </form>
      </div>
    </div>
  );
};

export default Profile;
