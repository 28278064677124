import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const subjectsApi = createApi({
  reducerPath: 'subjectsApi',
  tagTypes: 'Subjects',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.upshift.colibri.tj/api/`,
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getSubjects: build.query({
      query: (params) => `subjects?page=${params.page}`,
      providesTags:(result) =>
        result.data
          ? [
            ...result.data.map(({id}) => ({type: 'Subjects', id})),
            {type: 'Subjects', id: 'LIST'},
          ]
          : [{type: 'Subjects', id: 'LIST'}],
    }),
    getSearch: build.query({
      query: (params) => `search/subjects?keyword=${params.search}`
    }),
    addSubject:build.mutation({
      query: body => ({
        url: 'subjects',
        method: 'POST',
        body
      }),
      invalidatesTags: [{type: 'Subjects', id: 'LIST'}]
    }),
    editSubject: build.mutation({
      query:({id, ...body})=>({
        url: `subjects/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{type: 'Subjects', id: 'LIST'}]
    })
  })
})

export const {useGetSubjectsQuery, useGetSearchQuery, useAddSubjectMutation, useEditSubjectMutation} = subjectsApi;
