import {Transition} from "@headlessui/react";
import {ReactComponent as Steps} from "../../../../assets/img/secondSteps.svg";

import React from "react";
import {useFormContext} from "react-hook-form";

export const SecondStep = ({setPage, mentors, partners}) => {

  const {register, formState: {errors}} = useFormContext()



  return (
    <Transition
      appear={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className='px-4 pt-5 pb-4 sm:pb-6'>
        <div className="flex w-full gap-6 items-center mt-6">
          <div className="w-[50%] flex flex-col gap-1 relative">
            <label
              htmlFor="user_id"
              className="text-[#374151] text-sm font-medium"
            >
              Ментор
            </label>
            <select
              {...register("user_id")}
              className={errors.user_id ? 'errInputText' : 'inputTextStyle'}
            >
              {mentors.map((item) => {
                return (
                  <option key={item?.user?.id} value={item?.user?.id}>
                    {item?.user?.full_name}
                  </option>
                );
              })}
            </select>
            {errors.user_id && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.user_id.message}
              </p>
            )}
          </div>
          <div className="w-[50%] flex flex-col gap-1 relative">
            <label
              htmlFor="partner_id"
              className="text-[#374151] text-sm font-medium"
            >
              Партнёр
            </label>
            <select
              id="partner_id"
              name="partner_id"
              {...register("partner_id")}
              className={errors.partner_id ? 'errInputText' : 'inputTextStyle'}
            >
              {partners.map((item, index) => {
                return (
                  <option key={index} value={item?.partner?.id}>
                    {item?.partner?.name}
                  </option>
                );
              })}
            </select>
            {errors.partner_id && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.partner_id.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex w-full gap-6 items-center mt-6">
          <div className="w-[50%] flex flex-col gap-1 relative">
            <label htmlFor="start" className="text-[#374151] text-sm font-medium">
              Дата начала
            </label>
            <input
              type="date"
              {...register("start")}
              className={errors.start ? 'errInputText' : 'inputTextStyle'}
            />
            {errors.start && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.start.message}
              </p>
            )}
          </div>
          <div className="w-[50%] flex flex-col gap-1 relative">
            <label htmlFor="end" className="text-[#374151] text-sm font-medium">
              Дата завершения
            </label>
            <input
              type="date"
              {...register("end")}
              className={errors.end ? 'errInputText' : 'inputTextStyle'}
            />
            {errors.end && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.end.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full gap-1 mt-6 relative">
          <label
            htmlFor="background_of_problem"
            className="text-[#374151] text-sm font-medium"
          >
            Предпоссылки проблемы
          </label>
          <textarea
            rows="5"
            name="background_of_problem"
            id="background_of_problem"
            {...register("background_of_problem")}
            className={
              errors.background_of_problem ? 'errInputText' : 'inputTextStyle'
            }
          />
          {errors.background_of_problem && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.background_of_problem.message}
            </p>
          )}
        </div>
        <div className="flex items-center justify-center w-full mt-[42px]">
          <Steps/>
        </div>
      </div>
      <div className='flex p-6 justify-end bg-[#FBF9FB] max-w-full w-full mx-auto gap-4'>
        <button type='button' onClick={() => setPage(1)}
                className='px-8 py-3 rounded-md bg-white border border-[#D1D5DB] hover:bg-gray-50'>Назад
        </button>
        <button type='button' onClick={() => setPage(3)}
                className='px-8 py-3 rounded-md bg-[#39044E] text-white hover:bg-secondary'>Далее
        </button>
      </div>
    </Transition>
  )
}