import {useState, useEffect, useCallback, useContext} from "react";
import {ReactComponent as MagnifyingGlassIcon} from "../../assets/icons/search-icon.svg";
import Pagination from "../../components/pagination/Pagination";
import StaffTable from "../../components/tables/staffTable/StaffTable";
import Modal from "../../components/modals/modal/Modal";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import AuthContext from "../../context/AuthProvider";
import axiosInstance from "../../api/axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {useSearchStaffQuery} from "../../redux/api/staffApi";

const Staff = () => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [roles, setRoles] = useState([])
  const [pecName, setPecName] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [phone, setPhone] = useState('')

  const {token} = useContext(AuthContext);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
    setPecName(user?.pec?.name)
    setPartnerName(user?.partner?.name)
    setPhone(user?.phone)
  }, [])
  const handleAddBtn = () => {
    setUser({
      id: "",
      full_name: "",
      pec_id: "",
      pec: {
        id: "",
        pec_name: "",
      },
      phone: "",
      role_id: "",
      role: {
        id: "",
        name: "",
      },
      address: "",
      password: "",
      password_confirmation: "",
    });
    setOpen(true);
    setIsEditing(false);
  };

  const handleEditBtn = (data) => {
    setUser(data);
    setOpen(true);
    setIsEditing(true);
  };

  const getUsersByPage = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/users?page=${currentPage}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      setTimeout(() => {

        setUsers(response.data.data);
        setTotalUsers(response.data.meta.total);
        setTo(response.data.meta.to);
        setFrom(response.data.meta.from);
        setIsLoading(false);
      }, 300);
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  }, [currentPage, token]);


  // const filteredUsersForPec = users.filter(item => item?.user?.pec?.name === pecName && item?.user?.phone !== phone)
  // const filteredUsersForRepublicPec = users.filter(item => item?.user?.role[0] === 'ЦДО' || item?.user?.role[0] === 'Учитель' || item?.user?.role[0] === 'Республиканский ЦДО')
  const filteredUsersWithoutMe = users.filter(item => item?.user?.phone !== phone)

  // const filteredUsersForPartner = users.filter(item => item?.user?.partner?.name === partnerName)

  useEffect(() => {
    getUsersByPage();
  }, [getUsersByPage]);

  const updateUser = async (data, id) => {
    try {
      setIsLoading(true);
      await axiosInstance.put(`users/${id}`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(false);
      getUsersByPage();
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      } else if (error.response?.status === 422) {
        setErrMsg("Номер уже зарегистрирован");
      }
      setIsLoading(false);
    }
  };

  const addUser = async (data) => {
    try {
      setIsLoading(true);

      await axiosInstance.post("users", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(false);
      getUsersByPage();
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      } else if (error.response?.status === 422) {
        setErrMsg("Номер уже зарегистрирован");
      }
      setIsLoading(false);
    }
  };

  const deleteUser = async (id) => {
    try {
      setIsLoading(true);
      await axiosInstance.delete(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      getUsersByPage();
      setIsLoading(false);
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
      setIsLoading(false);
    }
  };
  const [search, setSearch] = useState('')
  const {data: searchData, isLoading: searchLoading} = useSearchStaffQuery({search})

  if (searchLoading) {
    return <p>Загрузка...</p>
  }
  const {data: searchStaff} = searchData


  const filteredSearchWithoutMe = searchStaff.filter(item => item?.user?.phone !== phone)

  return (
    <>
      <h1 className="font-semibold text-primary text-2xl">Сотрудники</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4">
        <div className="relative w-[90%]">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            id="search-field"
            className="block h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm focus:outline-none rounded-md"
            placeholder="По названию"
            type="search"
            name="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {roles.includes('store-users') && <button
          onClick={handleAddBtn}
          className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>}
        <Modal
          handleSaveBtn={isEditing ? updateUser : addUser}
          formName="users"
          open={open}
          setOpen={setOpen}
          data={user}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          user={user}
          loading={isLoading}
        />
      </div>
      {!searchLoading && search.length > 3 ?
        <StaffTable
          pecName={pecName}
          partnerName={partnerName}
          roles={roles}
          users={
            filteredSearchWithoutMe
          }
          deleteUser={deleteUser}
          handleEditBtn={handleEditBtn}
        /> :
        !isLoading && users.length > 0 ? (
          <StaffTable
            pecName={pecName}
            partnerName={partnerName}
            roles={roles}
            users={filteredUsersWithoutMe}
            deleteUser={deleteUser}
            handleEditBtn={handleEditBtn}
          />
        ) : !isLoading && users.length === 0 ? (
          <div className="flex">Таблица пуста</div>
        ) : (
          <div className="flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <Skeleton height={40} count={10}/>
              </div>
            </div>
          </div>
        )}

      {totalUsers > 8 && (
        <Pagination
          setCurrentPage={setCurrentPage}
          totalPage={totalUsers / 8}
          totalObjects={totalUsers}
          to={to}
          from={from}
        />
      )}
    </>
  );
};

export default Staff;
