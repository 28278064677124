import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const roleApi = createApi({
  reducerPath: 'roleApi',
  tagTypes: 'Role',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.upshift.colibri.tj/api',
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getRoles: build.query({
      query:(params) => `roles?page=${params.page}`,
      providesTags:(result) =>
        result.data
          ? [
            ...result.data.map(({id}) => ({type: 'Partners', id})),
            {type: 'Partners', id: 'LIST'},
          ]
          : [{type: 'Partners', id: 'LIST'}],
    }),
    getSearchRoles: build.query({
      query: (params) => `/search/roles?keyword=${params.search}`
    }),
    getAllRoles: build.query ({
      query: () => `get/all/roles`
    }),
    addRole: build.mutation({
      query: body => ({
        url: `roles`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{type: 'Partners', id: 'LIST'}]
    }),
    editRole: build.mutation({
      query: ({id, ...body}) => ({
        url: `roles/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{type: 'Partners', id: 'LIST'}]
    })
  })
})

export const {useGetRolesQuery, useAddRoleMutation, useEditRoleMutation, useGetAllRolesQuery, useGetSearchRolesQuery} = roleApi;
