import DeleteModal from "../../../pages/projects/components/deleteModal/DeleteModal";
import {useState} from "react";

const MembersToProjTable = ({id, full_name, phone, sex, project_id}) => {

  const [showDelete, setShowDelete] = useState(false)
  return (
      <tbody className="cursor-pointer divide-y divide-gray-200 bg-white">
        <tr key={id}>
          <td className={"whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6"}>
            {full_name}
          </td>
          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-[#6B7280]">
            +992 {phone}
          </td>
          <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-[#6B7280]">
            {sex === 'Ж' ? 'Женский' : 'Мужской'}
          </td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <DeleteModal project_id={project_id} id={id} showDelete={showDelete} setShowDelete={setShowDelete} title="участника" />
          </td>
        </tr>
      </tbody>
  );
};

export default MembersToProjTable;
