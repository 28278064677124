import {useFormContext} from "react-hook-form";

export const SelectInput = ({name, required_message, options, default_message}) => {
  const {register, formState: {errors}} = useFormContext()
  return (
    <>
      <select
        className={errors[name] ? 'errInputText' : 'inputTextStyle'}
        {...register(name,
          {
            required: `${required_message}`
          })}
      >
        <option value='' hidden>{default_message}</option>
        {options
          .map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
      </select>
      {errors[name] && (
        <p className="text-xs text-red-600 absolute bottom-[-22px]">
          {errors[name].message}
        </p>
      )}
    </>
  )
}