import "react-loading-skeleton/dist/skeleton.css";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort-icon.svg";

const MembersTable = ({ members, deleteMember, handleEditBtn, roles }) => {
  return (
    <div className="flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">

              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="flex items-center py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                    >
                      <span>ФИО</span>
                      <button className="ml-4">
                        <SortIcon />
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                    >
                      АДРЕС
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                    >
                      КОНТАКТЫ
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                    >
                      ПОЛ
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                    >
                      ГОД РОЖДЕНИЯ
                    </th>
                    <th
                      scope="col"
                      className="relative py-4 pl-3 pr-4 sm:pr-6 text-center"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {members.map((member) => (
                    <tr key={member.member.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
                        {member.member.full_name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                        {member.member.address}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                        +992 {member.member.phone}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
                        <span className="text-[#6B7280]">{member.member.sex}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
                        <span className="text-[#6B7280]">
                          {member.member.year_of_birth}
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
                        {roles.includes('update-users') && <button
                          onClick={() => handleEditBtn(member)}
                          className="text-primary hover:text-secondary"
                        >
                          <EditIcon/>
                        </button>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersTable;
