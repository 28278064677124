import {EditModal} from "./EditModal";
import {useState} from "react";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit-icon.svg";


export const DirectionsTable = ({name, id, refetch, roles}) => {
  const [showEdit, setShowEdit] = useState(false)
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
        {name}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
        {roles.includes('update-direction') && <button
          onClick={() => setShowEdit(true)}
          className="text-primary hover:text-secondary"
        >
          <EditIcon/>
        </button>}
        <EditModal refetch={refetch} name={name} id={id} showEdit={showEdit} setShowEdit={setShowEdit}/>
      </td>
    </tr>
  )
}