import React from "react";
import {useFormContext} from "react-hook-form";

export const ProjTextArea = ({required_message, name, defaultValue}) => {
  const {register, formState: {errors}} = useFormContext()
  return (
    <>
      <textarea
        rows="2"
        className='inputTextStyle'
        defaultValue={defaultValue}
        {...register(name,
          {
            required: `${required_message}`
          })}
      />
      {errors[name] && (
        <p className="text-xs text-red-600 absolute bottom-[-22px]">
          {errors[name].message}
        </p>
      )}
    </>
  )
}