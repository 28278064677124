import {Link} from "react-router-dom";
import MemberToProjForm from "../../../components/forms/memberToProjForm/MemberToProjForm";
import {useState} from "react";
import { ReactComponent as AddMemberIcon } from "../../../assets/icons/add-member-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import {EditModal} from "./editModal/EditModal";

export const NewProjectTable = ({roles, id, name, rent, transport_costs, start, end, status, project, members, media_file, documents, raw_materials, services, other, equipment
                                }) => {
  const [open, setOpen] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const openEditModal = () => {
    setShowEdit(true)
  }

  const doneStatusStyle =
    "text-center inline-block  bg-[#D1FAE5] py-0.5 px-2.5 rounded-[10px] text-[#065F46] font-medium w-[107px]";
  const creatingStatusStyle =
    "text-center inline-block  bg-[#FEE2E2] py-0.5 px-2.5 rounded-[10px] text-[#991B1B] font-medium w-[107px]";
  const planningStatusStyle =
    "text-center inline-block  bg-[#FEF3C7] py-0.5 px-2.5 rounded-[10px] text-[#92400E] font-medium w-[107px]";
  const testingStatusStyle =
    "text-center inline-block  bg-[#F3F4F6] py-0.5 px-2.5 rounded-[10px] text-[#1F2937] font-medium w-[107px]";
  const researchStatusStyle =
    "text-center inline-block  bg-[#DBEAFE] py-0.5 px-2.5 rounded-[10px] text-[#1E40AF] font-medium w-[107px]";

  return (
    <tr key={id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
        {roles.includes('show-project') ? <Link to={`/projects/${id}`}>{name}</Link> : name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {members?.length}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {Number(transport_costs) + Number(rent) + Number(other) + Number(services) + Number(raw_materials) + Number(equipment
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {new Date(start)
          .toLocaleDateString("en-gb")
          .replaceAll("/", ".")}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {new Date(end)
          .toLocaleDateString("en-gb")
          .replaceAll("/", ".")}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-center">
                      <span
                        className={
                          status === "создание"
                            ? creatingStatusStyle
                            : status === "планирование"
                              ? planningStatusStyle
                              : status === "завершение"
                                ? doneStatusStyle
                                : status === "исследование"
                                  ? researchStatusStyle
                                  : status === "тестирование"
                                    ? testingStatusStyle
                                    : ""
                        }
                      >
                        {status}
                      </span>
      </td>
      <td className="text-end items-center relative whitespace-nowrap py-4 pl-3 pr-4 text-sm sm:pr-6">
        {roles.includes('update-project') &&<button
          onClick={openEditModal}
          className="text-primary hover:text-secondary mr-4"
        >
          <EditIcon />
        </button> }
        {roles.includes('update-project') && <button
          onClick={() => setOpen(true)}
          className="text-primary hover:text-secondary"
        >
          <AddMemberIcon/>
        </button>}
      </td>
      <EditModal project={project} setShowEdit={setShowEdit} showEdit={showEdit} id={id}/>
      <MemberToProjForm
        project={project}
        id={id}
        open={open}
        setOpen={setOpen}
      />
    </tr>
  )
}