import {useCallback, useEffect, useState} from "react";
import CoursesTable from "../../components/tables/coursesTable/CoursesTable";
import Pagination from "../../components/pagination/Pagination";
import Modal from "../../components/modals/modal/Modal";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import {ReactComponent as MagnifyingGlassIcon} from "../../assets/icons/search-icon.svg";
import {useGetCoursesQuery, useGetSearchCoursesQuery} from "../../redux/api/coursesApi";
import {debounce} from "lodash";

const Courses = () => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [course, setCourse] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('')
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
  }, [])

  const handleChangeSearch = useCallback(
    debounce(str => {
      setSearch(str)
    }, 400), []
  )

  const handleSearch = (e) => {
    setValue(e.target.value)
    handleChangeSearch(e.target.value)
  }

  const {data: coursesData, isLoading: coursesLoading} = useGetCoursesQuery({page})
  const {data: searchCourses, isLoading: searchLoading} = useGetSearchCoursesQuery({search})

  const handleAddBtn = () => {
    setCourse({
      id: "",
      title: "",
      teacher: "",
      members: 0,
      cdo_id: "",
      startDate: "",
      finishDate: "",
      status: "планирование",
    });
    setOpen(true);
    setIsEditing(false);
  };

  const updateCourse = () => {
  };

  const addCourse = () => {
  };

  if (coursesLoading) {
    return <p>Загрузка...</p>
  }
  if (searchLoading) {
    return <p>Загрузка...</p>
  }
  const {data: courses} = coursesData
  const {data: searchData} = searchCourses

  return (
    <div className="relative">
      <h1 className="font-semibold text-primary text-2xl">Группы</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4 ">
        <div className="relative w-[90%]">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            className="block h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm rounded-md"
            placeholder="По названию"
            type="search"
            value={value}
            onChange={handleSearch}
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {roles.includes('store-group') && <button
          onClick={handleAddBtn}
          className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>}
        <Modal
          handleSaveBtn={isEditing ? updateCourse : addCourse}
          formName="courses"
          open={open}
          setOpen={setOpen}
          data={course}
        />
      </div>
      {search.length > 3 ?
        <CoursesTable roles={roles} courses={searchData}/> :
        <CoursesTable roles={roles} courses={courses}/>}
      {coursesData.meta.total > 8 && (
        <Pagination
          totalPage={coursesData.meta.total / 8}
          setCurrentPage={setPage}
          totalObjects={coursesData.meta.total}
          to={coursesData.meta.to}
          from={coursesData.meta.from}
        />
      )}
    </div>
  );
};

export default Courses;
