import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  startDate: '',
  endDate: ''
}

const cityReportSlice = createSlice({
  name: 'CityReport',
  initialState,
  reducers: {
    setStartDate (state, action) {
      state.startDate = action.payload
    },
    setEndDate (state, action) {
      state.endDate = action.payload
    }
  }
})

export const {setStartDate, setEndDate} = cityReportSlice.actions

export default cityReportSlice.reducer