import {Transition} from "@headlessui/react";
import { ReactComponent as Steps } from "../../../../assets/img/fourthSteps.svg";
import React from "react";
import {NumberInput} from "../inputs/NumberInput";
import {ProjTextArea} from "../inputs/ProjTextArea";

export const FourthStep = ({setPage, onClose, equipment, transport_costs, services, rent, raw_materials, other, tools, editLoading}) => {

  return (
    <Transition
      appear={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className='px-4 pt-5 pb-4 sm:pb-6'>

        <div className="flex flex-col gap-6 mt-6">
          <div className="flex items-center w-full gap-6">
            <div className="flex flex-col gap-1 w-[33%] relative">
              <label
                htmlFor="equipment"
                className="text-[#374151] text-sm font-medium"
              >
                Оборудования (TJS)
              </label>
              <NumberInput name='equipment' required_message='Заполните это поле'/>
            </div>
            <div className="flex flex-col gap-1 w-[33%] relative">
              <label
                htmlFor="transport_costs"
                className="text-[#374151] text-sm font-medium"
              >
                Транспортные расходы (TJS)
              </label>
              <NumberInput name='transport_costs' required_message='Заполните это поле'/>
            </div>
            <div className="flex flex-col gap-1 w-[33%] relative">
              <label
                htmlFor="services"
                className="text-[#374151] text-sm font-medium"
              >
                Услуги (TJS)
              </label>
              <NumberInput name='services' required_message='Заполните это поле'/>
            </div>
          </div>
          <div className="flex items-center w-full gap-6">
            <div className="flex flex-col gap-1 w-[33%] relative">
              <label
                htmlFor="rent"
                className="text-[#374151] text-sm font-medium"
              >
                Аренда (TJS)
              </label>
              <NumberInput name='rent' required_message='Заполните это поле'/>
            </div>
            <div className="flex flex-col gap-1 w-[33%] relative">
              <label
                htmlFor="raw_materials"
                className="text-[#374151] text-sm font-medium"
              >
                Сырье (TJS)
              </label>
              <NumberInput name='raw_materials' required_message='Заполните это поле'/>
            </div>
            <div className="flex flex-col gap-1 w-[33%] relative">
              <label
                htmlFor="other"
                className="text-[#374151] text-sm font-medium"
              >
                Прочее (TJS)
              </label>
              <NumberInput name='other' required_message='Заполните это поле'/>
            </div>
          </div>
          <div className="flex flex-col gap-1 relative">
            <label
              htmlFor="resource"
              className="text-[#374151] text-sm font-medium"
            >
              Ресуры
            </label>
            <ProjTextArea name='tools' required_message='Заполните это поле'/>
          </div>
        </div>
        <div className="flex items-center justify-center w-full mt-[42px]">
          <Steps/>
        </div>
      </div>
      <div className='flex p-6 justify-end bg-[#FBF9FB] max-w-full w-full mx-auto gap-4'>
        <button type='button' onClick={() => setPage(3)} className='px-8 py-3 rounded-md bg-white border border-[#D1D5DB] hover:bg-gray-50'>Назад</button>
        <button type='submit' disabled={editLoading} className={editLoading ? 'opacity-50 px-8 py-3 rounded-md bg-[#39044E] text-white hover:bg-secondary' :'opacity-100 px-8 py-3 rounded-md bg-[#39044E] text-white hover:bg-secondary'}>Сохранить</button>
      </div>
    </Transition>
  )
}