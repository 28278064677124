import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useGetAllPecsQuery} from "../../../redux/api/pecsApi";
import {useGetSubjectsQuery} from "../../../redux/api/subjectsApi";
import {TextInput} from "../../inputs/TextInput";
import {SpecialSelectInput} from "../../../pages/courses/components/inputs/SpecialSelectInput";
import {SelectInput} from "../../../pages/courses/components/inputs/SelectInput";
import {useAddCourseMutation} from "../../../redux/api/coursesApi";
import {useGetAllTeachersQuery} from "../../../redux/api/staffApi";

const CourseForm = ({setOpen, handleSaveBtn, data}) => {
  const {startDate, finishDate, status} =
    data;

  const [startDateState, setStartDateState] = useState(startDate);
  const [finishDateState, setFinishDateState] = useState(finishDate);
  const [teachers, setTeachers] = useState([])
  const {data: pecs, isLoading} = useGetAllPecsQuery()
  const {data: subjects, isLoading: subjectsLoading} = useGetSubjectsQuery({page: 1})
  const {data: teachersData, isLoading: teachersLoading} = useGetAllTeachersQuery()
  const [addCourse] = useAddCourseMutation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (teachersLoading) {
      setTeachers([])
    }
    if (!teachersLoading && teachersData) {
      setTeachers(teachersData.data)
    }
  }, [teachersLoading, teachersData])


  const statuses = [
    {label: "активный", value: 'active'},
    {label: "неактивный", value: 'inactive'}
  ];

  const onSubmit = async (data) => {
    setLoading(true)
    const cityId = pecs.find(item => (item.id) === Number(data.pec_id))
    const {city_id} = cityId
    data = {city_id: String(city_id), ...data}
    try {
      await addCourse(data).unwrap()
      setOpen(false)
      methods.reset()
      setLoading(false)
    } catch (err) {
      alert('Ошибка при создании группы')
      setLoading(false)
    }
  };


  const methods = useForm();


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  if (isLoading) {
    return <p>Загрузка...</p>
  }
  if (subjectsLoading) {
    return <p>Загрузка...</p>
  }


  return (
    <>
      <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
        <div className="sm:flex sm:items-start sm:flex-col">
          <h2 className="text-primary font-medium text-lg">
            {data.id ? "Редактирование" : "Добавление"} группы
          </h2>
          <p className="text-[#6B7280] text-sm mt-1">
            Для {data.id ? "редактирования" : "добавления"} группы заполните
            формы
          </p>
          <FormProvider {...methods}>
            <form
              className="flex flex-col w-full"
            >
              <div className="flex flex-col gap-6 w-full mt-6 mb-2">
                <div className="flex flex-col gap-1 flex-auto relative">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-[#374151]"
                  >
                    Название
                  </label>
                  <TextInput name='name' required_message='Заполните это поле'/>
                </div>
                <div className="flex flex-auto items-center gap-6">
                  <div className='flex-auto max-w-full w-full'>
                    <label>Предмет</label>
                    <SpecialSelectInput name='subject_id' required_message='Выберите предмет' options={subjects}/>
                  </div>
                  <div className='flex-auto max-w-full w-full'>
                    <label
                      htmlFor="teacher"
                      className="text-[#374151] text-sm font-medium"
                    >
                      Учитель
                    </label>
                    <select
                      className={methods.formState.errors.user_id ? 'errInputText' : 'inputTextStyle'}
                      {...methods.register('user_id',
                        {
                          required: 'Выберите преподавателя'
                        })}
                    >
                      <option value='' hidden>Выберите преподавателя</option>
                      {teachers
                        .map((item) => {
                          return (
                            <option key={item?.user?.id} value={item?.user?.id}>
                              {item.user.full_name}
                            </option>
                          );
                        })}
                    </select>
                    {methods.formState.errors?.user_id && (
                      <p className="text-xs text-red-600 absolute bottom-[-22px]">
                        {methods.formState.errors?.user_id?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-6">
                  <div className="flex flex-col gap-1 w-[50%] relative">
                    <label
                      htmlFor="cdo_id"
                      className="text-[#374151] text-sm font-medium"
                    >
                      ЦДО
                    </label>
                    <SelectInput name='pec_id' required_message='Выберите ЦДО' options={pecs}
                                 default_message='Выберите ЦДО'/>
                  </div>
                  <div className="flex flex-col gap-1 w-[50%] relative">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-[#374151]"
                    >
                      Статус
                    </label>
                    <select
                      id="status"
                      name="status"
                      defaultValue={status}
                      {...methods.register("status")}
                      className={methods.formState.errors.status ? 'errInputText' : 'inputTextStyle'}
                    >
                      <option value="" hidden>Выберите</option>
                      {statuses
                        .map((item, i) => {
                          return (
                            <option key={i} value={item.value}>
                              {capitalizeFirstLetter(item.label)}
                            </option>
                          );
                        })}
                    </select>
                    {methods.formState.errors.status && (
                      <p className="text-xs text-red-600 absolute bottom-[-22px]">
                        {methods.formState.errors.status.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-6">
                  <div className="flex flex-col gap-1 w-[50%] relative">
                    <label
                      htmlFor="startDate"
                      className="text-[#374151] text-sm font-medium"
                    >
                      Дата начала
                    </label>
                    <input
                      type="date"
                      max={finishDateState}
                      onChange={(e) => setStartDateState(e.target.value)}
                      {...methods.register("start", {
                        onChange: (e) => setStartDateState(e.target.value),
                      })}
                      className={methods.formState.errors.startDate ? 'errInputText' : 'inputTextStyle'}
                    />
                    {methods.formState.errors.startDate && (
                      <p className="text-xs text-red-600 absolute bottom-[-22px]">
                        {methods.formState.errors.startDate.message}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col gap-1 w-[50%] relative">
                    <label
                      htmlFor="finishDate"
                      className="text-[#374151] text-sm font-medium"
                    >
                      Дата завершения
                    </label>
                    <input
                      type="date"
                      defaultValue={finishDate}
                      min={startDateState}
                      {...methods.register("end", {
                        onChange: (e) => setFinishDateState(e.target.value),
                      })}
                      className={
                        methods.formState.errors.finishDate ? 'errInputText' : 'inputTextStyle'
                      }
                    />
                    {methods.formState.errors.finishDate && (
                      <p className="text-xs text-red-600 absolute bottom-[-22px]">
                        {methods.formState.errors.finishDate.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          onClick={methods.handleSubmit(onSubmit)}
          disabled={loading}
          type="submit"
          className={loading ? "opacity-50 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30" : "opacity-100 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"}
        >
          Сохранить
        </button>
        <button
          onClick={() => setOpen(false)}
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-[#fff] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Отмена
        </button>
      </div>
    </>
  );
};

export default CourseForm;
