import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort-icon.svg";

const CdosTable = ({ cdos, handleEditBtn, roles }) => {
  const activeStatusStyle =
    "inline-block text-center bg-[#D1FAE5] py-0.5 px-2.5 rounded-[10px] text-[#065F46] font-medium w-[94px]";
  const inactiveStatusStyle =
    "inline-block text-center bg-[#FEE2E2] py-0.5 px-2.5 rounded-[10px] text-[#991B1B] font-medium w-[94px]";


  return (
    <div className="flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="flex items-center py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                  >
                    <span>НАЗВАНИЕ</span>
                    <button className="ml-4">
                      <SortIcon />
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    МЕСТОПОЛОЖЕНИЕ
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    СТАТУС
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {cdos.map((cdo, i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
                      {cdo.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                      {cdo?.city_name?.city_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-xs text-center">
                      <span
                        className={
                          cdo.status === "active"
                            ? activeStatusStyle
                            : inactiveStatusStyle
                        }
                      >
                        {cdo.status === "active" ? "Активный" : "Неактивный"}
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
                      {roles.includes('update-pec')&&<button
                        onClick={() => handleEditBtn(cdo)}
                        className="text-primary hover:text-secondary"
                      >
                        <EditIcon/>
                      </button>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CdosTable;
