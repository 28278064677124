import {useState, useEffect, useCallback, useContext} from "react";
import Pagination from "../../components/pagination/Pagination";
import {ReactComponent as MagnifyingGlassIcon} from "../../assets/icons/search-icon.svg";
import PartnersTable from "../../components/tables/partnersTable/PartnersTable";
import Modal from "../../components/modals/modal/Modal";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import AuthContext from "../../context/AuthProvider";
import axiosInstance from "../../api/axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {useSearchPartnersQuery} from "../../redux/api/partnersApi";

const Partners = () => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [partner, setPartner] = useState({});
  const [partners, setPartners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [totalPartners, setTotalPartners] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([])
  const [search, setSearch] = useState('')
  const [searchPartners, setSearchPartners] = useState([])
  const [numberError, setNumberError] = useState(false)


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
  }, [])

  const {token} = useContext(AuthContext);

  const getPartnersByPage = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`partners?page=${currentPage}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      setTimeout(() => {
        setPartners(response?.data.data);
        setTotalPartners(response?.data.meta.total);
        setTo(response.data.to);
        setFrom(response.data.from);
        setIsLoading(false);
      }, 300);
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  }, [currentPage, token]);

  useEffect(() => {
    getPartnersByPage();
  }, [getPartnersByPage]);

  const handleAddBtn = () => {
    setPartner({
      id: "",
      name: "",
      address: "",
      phone: "",
      status: "inactive",
    });
    setOpen(true);
    setIsEditing(false);
  };

  const handleEditBtn = (data) => {
    setPartner(data);
    setOpen(true);
    setIsEditing(true);
  };

  const updatePartner = async (data, id) => {
    try {
      await axiosInstance.put(`partners/${id}`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(true);
      setNumberError(false)
      getPartnersByPage();
    } catch (error) {
      if (error.response.status === 422) {
        setNumberError(true)
      }
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  };

  const addPartner = async (data) => {
    try {
      await axiosInstance.post("partners", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(true);
      setNumberError(false)
      getPartnersByPage();
    } catch (error) {
      if (error.response.status === 422) {
        setNumberError(true)
      }
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  };

  const {data: searchPartnersData, isLoading: searchLoading} = useSearchPartnersQuery({search})
  useEffect(() => {
    if (!searchLoading && searchPartnersData) {
      setSearchPartners(searchPartnersData?.data)
    }
  }, [searchLoading, searchPartnersData])

  useEffect(() => {
    if (!open) {
      setNumberError(false)
    }
  }, [open])

  return (
    <>
      <h1 className="font-semibold text-primary text-2xl">Партнеры</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4">
        <div className="relative w-[90%]">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            value={search}
            onChange={e => setSearch(e.target.value)}
            id="search-field"
            className="block h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm focus:outline-none rounded-md"
            placeholder="По названию"
            type="search"
            name="search"
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {roles.includes('store-partner') && <button
          onClick={handleAddBtn}
          className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>}
        <Modal
          setNumberError={setNumberError}
          numberError={numberError}
          handleSaveBtn={isEditing ? updatePartner : addPartner}
          formName="partners"
          open={open}
          setOpen={setOpen}
          data={partner}
        />
      </div>
      {search.length > 3 ?
        <PartnersTable partners={searchPartners} roles={roles} handleEditBtn={handleEditBtn}/> :
        !isLoading && partners.length > 0 ? (
          <PartnersTable roles={roles} partners={partners} handleEditBtn={handleEditBtn}/>
        ) : !isLoading && partners.length === 0 ? (
          <p>Таблица пуста</p>
        ) : (
          <div className="flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <Skeleton height={40} count={10}/>
              </div>
            </div>
          </div>
        )}
      {totalPartners > 8 && search.length < 4 && (
        <Pagination
          setCurrentPage={setCurrentPage}
          totalPage={totalPartners / 8}
          totalObjects={totalPartners}
          to={to}
          from={from}
        />
      )}
    </>
  );
};

export default Partners;
