import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const sayohiApi=createApi({
    reducerPath: 'sayohiApi',
    tagTypes: 'Sayohi',
    baseQuery: fetchBaseQuery({
        baseUrl: `https://api.upshift.colibri.tj/api/`,
        prepareHeaders: (headers ) => {
            const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
        }
    }),
    endpoints: build => ({
        getSayohi: build.query({
            query:()=> `report/table3`
        }),

        postSayohi: build.mutation ({
            query: (data) => ({
                url: `report/table3`,
                method: "POST",
                body: data

            })
        })

    })

})

export const { useGetSayohiQuery, usePostSayohiMutation } = sayohiApi
