import axios from "axios";
const BASE_URL = "https://api.upshift.colibri.tj/api";

const token = JSON.parse(localStorage.getItem('token'))

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Authorization': `Bearer ${token}`
  }
});

export default axiosInstance;
