import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import CdoForm from "../../forms/cdoForm/CdoForm";
import PartnerForm from "../../forms/partnerForm/PartnerForm";
import ProjectForm from "../../forms/projectForm/ProjectForm";
import RoleForm from "../../forms/roleForm/RoleForm";
import StaffForm from "../../forms/staffForm/StaffForm";
import MemberForm from "../../forms/memberForm/MemberForm";
import CourseForm from "../../forms/courseForm/CourseForm";
import MemberToProjForm from "../../forms/memberToProjForm/MemberToProjForm";

const Modal = ({
                 loading,
                 handleSaveBtn,
                 formName,
                 open,
                 setOpen,
                 data,
                 setData,
                 errMsg = "",
                 setErrMsg,
                 onSubmitForm,
                 modalType,
                 readonly,
                 projects,
                 user,
                 numberError,
                 setNumberError
               }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[907px]">
                {formName === "cdo" && (
                  <CdoForm
                    handleSaveBtn={handleSaveBtn}
                    setOpen={setOpen}
                    data={data}
                    loading={loading}
                  />
                )}
                {formName === "partners" && (
                  <PartnerForm
                    setNumberError={setNumberError}
                    numberError={numberError}
                    handleSaveBtn={handleSaveBtn}
                    setOpen={setOpen}
                    data={data}
                  />
                )}
                {formName === "projects" && (
                  <ProjectForm
                    modalType={modalType}
                    onSubmitForm={onSubmitForm}
                    handleSaveBtn={handleSaveBtn}
                    setOpen={setOpen}
                    setData={setData}
                    data={data}
                    loading={loading}
                  />
                )}
                {formName === "roles" && (
                  <RoleForm handleSaveBtn={handleSaveBtn} setOpen={setOpen}/>
                )}
                {formName === "users" && (
                  <StaffForm
                    user={user}
                    handleSaveBtn={handleSaveBtn}
                    setOpen={setOpen}
                    dataData={data}
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                    loading={loading}
                  />
                )}
                {formName === "members" && (
                  <MemberForm
                    numberError={numberError}
                    readonly={readonly}
                    handleSaveBtn={handleSaveBtn}
                    setOpen={setOpen}
                    datadata={data}
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                    loading={loading}
                  />
                )}
                {formName === "membersToProj" && (
                  <MemberToProjForm
                    handleSaveBtn={handleSaveBtn}
                    setOpen={setOpen}
                    data={projects}
                  />
                )}
                {formName === "courses" && (
                  <CourseForm
                    handleSaveBtn={handleSaveBtn}
                    setOpen={setOpen}
                    data={data}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default Modal;
