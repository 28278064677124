import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useForm} from "react-hook-form";
import {useEditRoleMutation} from "../../../redux/api/roleApi";

export const EditModal = ({open, setOpen, permissions, name, id}) => {
  const {register, handleSubmit, formState: {errors}} = useForm()
  const [loading, setLoading] = useState(false)

  const [editRole] = useEditRoleMutation()


  const pages = [
    {
      id: 1,
      title: "Проекты",
      tag: "project",
    },
    {
      id: 2,
      title: "Группы",
      tag: "group",
    },
    {
      id: 3,
      title: "Пользователи",
      tag: "users",
    },
    {
      id: 4,
      title: "Участники",
      tag: "members",
    },
    {
      id: 5,
      title: "ЦДО",
      tag: "pec",
    },
    {
      id: 6,
      title: "Партнеры",
      tag: "partner",
    },
    {
      id: 7,
      title: "Направления",
      tag: "direction",
    },
    {
      id: 8,
      title: "Предметы",
      tag: "subject",
    },
    {
      id: 9,
      title: "Роли",
      tag: "roles",
    },
    {
      id: 10,
      title: "Отчеты",
      tag: "report",
    },
  ];
  const onSubmit = async(data) => {
    setLoading(true)
    const newObj = Object.keys(data).filter(value => data[value] === true)
    const result = {
      name: data.name,
      permissions: newObj
    }
    try {
      await editRole({id: id, ...result}).unwrap()
      setOpen(false)
      setLoading(false)
    } catch (err) {
      alert('Ошибка при изменении роли')
      setLoading(false)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[907px]">
                <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
                  <div className="sm:flex sm:items-start sm:flex-col">
                    <h2 className="text-primary font-medium text-lg">Изменить роль</h2>
                    <p className="text-[#6B7280] text-sm mt-1">
                      Для изменения роли заполните формы и отметите галочками доступы
                    </p>
                    <form
                      className="flex flex-col w-full mt-6 gap-7"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="flex flex-col w-full gap-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-[#374151]"
                        >
                          Название роли
                        </label>
                        <input
                          type="text"
                          defaultValue={name}
                          {...register('name',
                            {
                              required: 'Заполните это поле'
                            })}
                          className="block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm"
                        />
                        {errors.name && <p className='text-[red] text-[14px]'>{errors.name.message}</p>}
                      </div>
                      <div className="flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3 pl-4 pr-3 tracking-wide text-xs font-semibold text-[#6B7280] sm:pl-6"
                                  >
                                    ДОСТУПЫ
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-center tracking-wide text-xs font-semibold text-[#6B7280]"
                                  >
                                    ЧТЕНИЕ
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-center tracking-wide text-xs font-semibold text-[#6B7280]"
                                  >
                                    СОЗДАНИЕ
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-center tracking-wide text-xs font-semibold text-[#6B7280]"
                                  >
                                    РЕДАКТИРОВАНИЕ
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-center tracking-wide text-xs font-semibold text-[#6B7280]"
                                  >
                                    УДАЛЕНИЕ
                                  </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {pages.map((page) => (
                                  <tr key={page.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-primary sm:px-6">
                                      {page.title}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-[18px] text-sm text-center">
                                      <input
                                        {...register(`index-${page.tag}`)}
                                        defaultChecked={permissions.some(item => item === `index-${page.tag}`)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-[#39044E] focus:ring-[#39044E]"
                                      />
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-[18px] text-sm text-center">
                                      <input
                                        {...register(`store-${page.tag}`)}
                                        defaultChecked={permissions.some(item => item === `index-${page.tag}`)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-[#39044E] focus:ring-[#39044E]"
                                      />
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-[18px] text-sm text-center">
                                      <input
                                        {...register(`update-${page.tag}`)}
                                        defaultChecked={permissions.some(item => item === `update-${page.tag}`)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-[#39044E] focus:ring-[#39044E]"
                                      />
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-[18px] text-sm text-center">
                                      <input
                                        {...register(`delete-${page.tag}`)}
                                        defaultChecked={permissions.some(item => item === `delete-${page.tag}`)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-[#39044E] focus:ring-[#39044E]"
                                      />
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit(onSubmit)}
                    className="inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"
                  >
                    Сохранить
                  </button>
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-[#FFFFFF] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Отмена
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}