import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const homePageApi = createApi({
  reducerPath: 'homePageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.upshift.colibri.tj/api',
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getGeneral: build.query({
      query: () => `home/general`
    }),
    getBrief: build.query({
      query: () => `home/brief`
    })
  })
})

export const {useGetGeneralQuery, useGetBriefQuery} = homePageApi;
