export const SubjectReportTable = ({item}) => {
  return (
    <tr>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-left pl-6">
        {item['Предмет']}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {item['Кол-во групп']}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {item['Участники']}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {item['Мальчики']}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-center pl-3 pr-4 sm:pr-6">
        {item['Девочки']}
      </td>
    </tr>
  )
}