import {useState, useContext, useEffect, useCallback} from "react";
import {ReactComponent as MagnifyingGlassIcon} from "../../assets/icons/search-icon.svg";
import Pagination from "../../components/pagination/Pagination";
import MembersTable from "../../components/tables/membersTable/MembersTable";
import Modal from "../../components/modals/modal/Modal";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import AuthContext from "../../context/AuthProvider";
import axiosInstance from "../../api/axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {useGetSearchMembersQuery} from "../../redux/api/membersApi";

const Members = () => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [member, setMember] = useState({});
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [totalMembers, setTotalMembers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([])
  const [searchMembers, setSearchMembers] = useState([])
  const [numberError, setNumberError] = useState(false)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
  }, [])

  const [errMsg, setErrMsg] = useState("");

  const {token} = useContext(AuthContext);

  const getMemberssByPage = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`members?page=${currentPage}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        });
      setTimeout(() => {
        setMembers(response?.data.data);
        setTotalMembers(response?.data.meta.total);
        setTo(response.data.meta.to);
        setFrom(response.data.meta.from);
        setIsLoading(false);
      }, 300);
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  }, [currentPage, token]);

  const [search, setSearch] = useState('')

  const {data: searchData, isLoading: searchLoading} = useGetSearchMembersQuery({search})

  useEffect(() => {
      getMemberssByPage();
    }, [getMemberssByPage]
  )

  const addMember = async (data) => {
    try {
      setIsLoading(true)
      await axiosInstance.post("members", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(false);
      getMemberssByPage();
    } catch (error) {
      console.log(error)
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      } else if(error.response?.status === 500) {
        setNumberError(true)
      }
      setIsLoading(false)
    }
  };
  const updateMember = async (data, id) => {
    try {
      setIsLoading(true)
      await axiosInstance.put(`members/${id}`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(false);
      getMemberssByPage();
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      } else if(error.response?.status === 500) {
        setNumberError(true)
      }
      setIsLoading(false)
    }
  };

  const handleAddBtn = () => {
    setMember({
      id: "",
      full_name: "",
      phone: "",
      year_of_birth: "",
      sex: "",
      school: "",
      grade: "",
      location: "Душанбе",
      address: "",
    });
    setOpen(true);
    setIsEditing(false);
  };

  const handleEditBtn = (data) => {
    setMember(data);
    setOpen(true);
    setIsEditing(true);
  };

  useEffect(() => {
    if (!searchLoading && searchData) {
      setSearchMembers(searchData?.data)
    }
  }, [searchLoading, searchData])

  useEffect(() => {
    if(!open) {
      setNumberError(false)
    }
  },[open])


  return (
    <>
      <h1 className="font-semibold text-primary text-2xl">Участники</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4">
        <div className="relative w-[90%]">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            id="search-field"
            className="block h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm focus:outline-none rounded-md"
            placeholder="По названию"
            type="search"
            name="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {roles.includes('store-members') && <button
          onClick={handleAddBtn}
          className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>}
        <Modal
          numberError={numberError}
          handleSaveBtn={isEditing ? updateMember : addMember}
          formName="members"
          open={open}
          setOpen={setOpen}
          data={member}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          loading={isLoading}
        />
      </div>
      {/*{search.length >= 4 ?*/}
      {/*  <MembersTable members={searchMembers} handleEditBtn={handleEditBtn}/> :*/}
      {!searchLoading && search.length > 3 ?
        <MembersTable roles={roles}
                      members={searchMembers}
                      handleEditBtn={handleEditBtn}
        /> :
        !isLoading && members.length > 0 ?
          <MembersTable roles={roles} members={members} handleEditBtn={handleEditBtn}/>
          : !isLoading && (members.length === 0) ?
            <p>Таблица пуста</p> :

            <div className="flow-root">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <Skeleton height={40} count={10}/>
                </div>
              </div>
            </div>
      }

      {totalMembers > 8 && (
        <Pagination
          setCurrentPage={setCurrentPage}
          totalPage={totalMembers / 8}
          totalObjects={totalMembers}
          to={to}
          from={from}
        />
      )}
    </>
  );
};

export default Members;
