import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import {useState} from "react";
import {EditModal} from "./EditModal";

export const RolesNewTable = ({name, id, permissions, role}) => {
  const [showEdit, setShowEdit] = useState(false)
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6">
        {name}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6">
        <button onClick={() => setShowEdit(true)}
          className="text-primary hover:text-secondary"
        >
          <EditIcon />
        </button>
      </td>
      <EditModal name={name} id={id} permissions={permissions} open={showEdit} setOpen={setShowEdit}/>
    </tr>
  )
}