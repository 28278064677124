import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const pecsApi = createApi({
  reducerPath: 'pecsApi',
  tagTypes: 'Pecs',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.upshift.colibri.tj/api`,
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    }
  }),
  endpoints: build => ({
    getPecs: build.query({
      query: (params) => `pec?page=${params.page}`,
      providesTags: (result) =>
        result.data.data
          ? [
            ...result.data.data.map(({id}) => ({type: 'Directions', id})),
            {type: 'Directions', id: 'LIST'},
          ]
          : [{type: 'Directions', id: 'LIST'}],
    }),
    getAllPecs: build.query({
      query: () => `get/all/pecs`
    })
  })
})

export const {useGetPecsQuery, useGetAllPecsQuery} = pecsApi;
