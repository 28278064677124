import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import ReactPaginate from "react-paginate";

const Pagination = ({ setCurrentPage, totalPage, totalObjects, to, from }) => {
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div>
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{from}</span> to{" "}
          <span className="font-medium">{to}</span> of{" "}
          <span className="font-medium">{totalObjects}</span> results
        </p>
      </div>
      <ReactPaginate
        previousLabel={<ChevronLeftIcon />}
        nextLabel={<ChevronRightIcon />}
        breakLabel={"..."}
        pageCount={Math.ceil(totalPage)}
        marginPagesDisplayed={3}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={
          "isolate inline-flex -space-x-px rounded-md shadow-sm"
        }
        pageLinkClassName={
          "relative hidden items-center px-4 py-2 text-sm font-semibold text-[#6B7280] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
        }
        previousClassName={
          "relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        }
        previousLinkClassName={"h-5 w-5"}
        nextClassName={
          "relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        }
        nextLinkClassName={"h-5 w-5"}
        breakClassName={
          "relative hidden items-center px-4 py-2 text-sm font-semibold text-[#6B7280] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
        }
        activeLinkClassName={
          "bg-[#F0EEF7] ring-1 ring-inset ring-primary z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        }
      />
    </div>
  );
};

export default Pagination;
