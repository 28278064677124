import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  tagTypes: 'Projects',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.upshift.colibri.tj/api/`,
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    editProject: build.mutation({
      query: ({id, ...body}) => ({
        url: `projects/${id}`,
        method: 'PUT',
        body
      })
    }),
    editProjectFiles: build.mutation({
      query: ({id, ...body}) => ({
        url: `projects/${id}`,
        method: 'PUT',
        body: body.formData
      })
    }),
    getSearchProjects: build.query({
      query: (search) => `search/projects?keyword=${search}`
    }),
    getOneProject: build.query({
      query: (id) => `projects/${id}`
    })
  })
})

export const {useGetSearchProjectsQuery, useGetOneProjectQuery} = projectsApi
