export const locations = [
  {id: 1, name: "Душанбе"},
  {id: 2, name: "Бохтар"},
  {id: 3, name: "Вахдат"},
  {id: 4, name: "Гиссар"},
  {id: 5, name: "Гулистон"},
  {id: 6, name: "Истаравшан"},
  {id: 7, name: "Истиклол"},
  {id: 8, name: "Исфара"},
  {id: 9, name: "Канибадам"},
  {id: 10, name: "Куляб"},
  {id: 11, name: "Левакант"},
  {id: 12, name: "Нурек"},
  {id: 13, name: "Пенджикент"},
  {id: 14, name: "Рогун"},
  {id: 15, name: "Турсунзаде"},
  {id: 16, name: "Худжанд"},
  {id: 17, name: "Хорог"},
  {id: 18, name: "Кушониён"},
  {id: 19, name: "Дангара"},
  {id: 20, name: "Ашт"},
  {id: 21, name: "Шамсуддини Шохин"},
  {id: 22, name: "Шугнон"},
  {id: 23, name: "Вахш"},
  {id: 24, name: "Ч. Балх"},
  {id: 25, name: "Ёвон"},
  {id: 26, name: "Панч"},
  {id: 27, name: "Шахритуз"},
  {id: 28, name: "Кубодиён"},
  {id: 29, name: "Левакант"},
  {

    id: 30,
    name: "Н. Хусрав"
  },
  {
    id: 31,
    name: "А. Чоми"
  },
  {
    id: 32,
    name: "Чайхун"
  },
  {
    id: 33,
    name: "Дусти"
  },
  {
    id: 34,
    name: "Дарвоз"
  },
  {
    id: 35,
    name: "Хуросон"
  },
  {
    id: 36,
    name: "Турсунзода"
  },
  {
    id: 37,
    name: "Варзоб"
  },
  {
    id: 38,
    name: "Рудаки"
  },
  {
    id: 39,
    name: "Шахринав"
  },
  {
    id: 40,
    name: "Шахритуз"
  },
  {
    id: 41,
    name: "Файзобод"
  },
  {
    id: 42,
    name: "Нуробод"
  },
  {
    id: 43,
    name: "Точикобод"
  },
  {
    id: 44,
    name: "Сангвор"
  },
  {
    id: 45,
    name: "Рашт"
  },
  {
    id: 46,
    name: "Лахш"
  },
  {
    id: 47,
    name: "н. Сино"
  },
  {
    id: 48,
    name: "н. Сомони"
  },
  {
    id: 49,
    name: "н. Шохмансур"
  },
  {
    id: 50,
    name: "Бобочон Гафуров"
  },
  {
    id: 51,
    name: "н. Фирдавси"
  },
  {
    id: 52,
    name: "Мургоб"
  },
  {
    id: 53,
    name: "Ишкошим"
  },
  {
    id: 54,
    name: "Хатлон"
  },
  {
    id: 55,
    name: "Истаравшан"
  },
  {
    id: 56,
    name: "Согд"
  },{
    id: 57,
    name: "ГБАО"
  },{
    id: 58,
    name: "НТМ"
  },
];

export const statuses = [
  "планирование",
  "исследование",
  "создание",
  "тестирование",
  "завершение",
];


