import {configureStore} from "@reduxjs/toolkit";
import {directionsApi} from './api/directionsApi'
import {subjectsApi} from "./api/subjectsApi";
import {coursesApi} from "./api/coursesApi";
import {staffApi} from "./api/staffApi";
import {membersApi} from "./api/membersApi";
import {userApi} from "./api/userApi";
import {partnersApi} from "./api/partnersApi";
import {editProjectApi} from './api/editProjectApi'
import {pecsApi} from "./api/pecsApi";
import {reportsApi} from "./api/reportsApi";
import {roleApi} from "./api/roleApi";
import {profileApi} from "./api/profileApi";
import {projectsApi} from "./api/projectsApi";
import {homePageApi} from "./api/homePageApi";
import {mahfilhoApi} from "./api/mahfilhoApi";
import {kormandonApi} from "./api/kormandonApi";
import EditProject from './slices/EditProjectSlice'
import CityReport from './slices/cityReportSlice'
import ProjectInfo from './slices/projectInfoSlice'
import {sayohiApi} from "./api/sayohiApi";
import {modiApi} from "./api/modiApi";
import {reportStatusApi} from './api/reportStatusApi'

export const store = configureStore({
  reducer: {
    EditProject,
    CityReport,
    ProjectInfo,
    [directionsApi.reducerPath]: directionsApi.reducer,
    [subjectsApi.reducerPath]: subjectsApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [membersApi.reducerPath]: membersApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [partnersApi.reducerPath]: partnersApi.reducer,
    [editProjectApi.reducerPath]: editProjectApi.reducer,
    [pecsApi.reducerPath]: pecsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [homePageApi.reducerPath]: homePageApi.reducer,
    [mahfilhoApi.reducerPath]: mahfilhoApi.reducer,
    [kormandonApi.reducerPath]: kormandonApi.reducer,
    [sayohiApi.reducerPath]: sayohiApi.reducer,
    [modiApi.reducerPath]: modiApi.reducer,
    [reportStatusApi.reducerPath]: reportStatusApi.reducer,
  },
  middleware:
    (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        directionsApi.middleware,
        subjectsApi.middleware,
        coursesApi.middleware,
        staffApi.middleware,
        membersApi.middleware,
        userApi.middleware,
        partnersApi.middleware,
        editProjectApi.middleware,
        pecsApi.middleware,
        reportsApi.middleware,
        roleApi.middleware,
        profileApi.middleware,
        projectsApi.middleware,
        homePageApi.middleware,
        mahfilhoApi.middleware,
        kormandonApi.middleware,
        sayohiApi.middleware,
        modiApi.middleware,
        reportStatusApi.middleware,
      ])
})
