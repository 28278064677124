import {useFormContext} from "react-hook-form";

export const SpecialSelectInput = ({name, required_message, options, default_value}) => {
  const {register, formState: {errors}} = useFormContext()
  return(
    <>
      <select
        className={errors[name] ? 'errInputText' : 'inputTextStyle'}
        defaultValue={default_value || null}
        {...register(name,
          {
            required: `${required_message}`
          })}>
        <option value="" hidden>Предмет</option>
        {options.data.map((item, index) => <option key={index}
                                                    value={item.subject.id}>{item.subject.name}</option>)}
      </select>
      {errors[name] && <p className="text-xs text-red-600 absolute bottom-[-22px]">
      </p>}
    </>
  )
}
