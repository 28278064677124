import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  docsFromBack: true,
  selectedPhoto: null,
  selectedDocs: null,
}

const EditProjectSlice = createSlice({
  name: 'editProjectSlice',
  initialState,
  reducers: {
    setSelectedPhoto (state, action) {
      state.selectedPhoto = action.payload
    },
    setSelectedDocs (state, action) {
      state.selectedDocs = action.payload
    },

    setDocsFromBack (state, action) {
      state.docsFromBack = action.payload
    },

  }
})

export const { setSelectedDocs, setSelectedPhoto, setDocsFromBack } = EditProjectSlice.actions

export default EditProjectSlice.reducer