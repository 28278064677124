import {SubjectsTable} from "./components/SubjectsTable";
import Pagination from "../../components/pagination/Pagination";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import {useCallback, useEffect, useState} from "react";
import {CreateModal} from "./components/CreateModal";
import {ReactComponent as SortIcon} from "../../assets/icons/sort-icon.svg";
import {useGetSearchQuery, useGetSubjectsQuery} from "../../redux/api/subjectsApi";
import {debounce} from "lodash";


export const Subjects = () => {

  const [showCreate, setShowCreate] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('')
  const [roles, setRoles] = useState([])
  const [subjectsData, setSubjectsData] = useState([])
  const [searchData, setSearchData] = useState([])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
  }, [])

  const handleChangeSearch = useCallback(
    debounce(str => {
      setSearch(str)
    }, 500), []
  )

  const handleSearch = (e) => {
    setValue(e.target.value)
    handleChangeSearch(e.target.value)
  }

  const {data: subjects, isLoading: subjectsLoading, refetch} = useGetSubjectsQuery({page})
  const {data: searchSubjects, isLoading: searchLoading} = useGetSearchQuery({search})

  useEffect(() => {
    if (subjectsLoading) {
      setSubjectsData([])
    }
    if (searchLoading) {
      setSearchData([])
    }
    if (!subjectsLoading && subjects) {
      setSubjectsData(subjects?.data)
    }
    if (!searchLoading && searchSubjects) {
      setSearchData(searchSubjects?.data)
    }
  }, [searchLoading, subjectsLoading, subjects, searchSubjects])


  return (
    <>
      <h1 className="font-semibold text-primary text-2xl">Предметы</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4">
        <div className="relative w-[90%]">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            id="search-field"
            className="block h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm focus:outline-none rounded-md"
            placeholder="По названию"
            type="search"
            name="search"
            value={value}
            onChange={handleSearch}
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {roles.includes('store-subject') && <button onClick={() => setShowCreate(true)}
                                                    className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>}
      </div>
      <div className="flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="flex items-center py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                  >
                    <span>НАЗВАНИЕ</span>
                    <button className="ml-4">
                      <SortIcon/>
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    НАПРАВЛЕНИЯ
                  </th>
                  <th scope="col"
                      className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"></th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {search.length > 3 ?
                  searchData.map((item, index) =>
                    <SubjectsTable
                      roles={roles}
                      refetch={refetch}
                      key={index}
                      id={item.subject.id}
                      {...item.subject}
                    />) :
                  subjectsData.map((item, index) =>
                    <SubjectsTable
                      roles={roles}
                      refetch={refetch}
                      key={index}
                      id={item.subject.id}
                      {...item.subject}/>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/*{!isLoading && partners.length > 0 ? (*/}
      {/*) : !isLoading && partners.length === 0 ? (*/}
      {/*  <p>Таблица пуста</p>*/}
      {/*) : (*/}
      {/*  // <div className="flow-root">*/}
      {/*  //   <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">*/}
      {/*  //     <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">*/}
      {/*  //       <Skeleton height={40} count={10}/>*/}
      {/*  //     </div>*/}
      {/*  //   </div>*/}
      {/*  // </div>*/}
      {/*// )}*/}

      <Pagination
        setCurrentPage={setPage}
        totalPage={subjects?.meta?.total / 8}
        totalObjects={subjects?.meta?.total}
        to={subjects?.meta?.to}
        from={subjects?.meta?.from}
      />
      <CreateModal refetch={refetch} showCreate={showCreate} setShowCreate={setShowCreate}/>
    </>
  )
}