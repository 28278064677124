import {Fragment, useEffect, useState} from "react";
import {ReactComponent as PlusIcon} from "../../../assets/icons/plus-icon.svg";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import MembersToProjTable from "../../tables/membersToProjTable/MembersToProjTable";
import {useAddMemberToProjMutation, useGetMembersQuery, useGetSearchMembersQuery} from "../../../redux/api/membersApi";
import {Dialog, Transition} from "@headlessui/react";
import Select from "react-select";
import {useGetOneProjectQuery} from "../../../redux/api/projectsApi";

const MemberToProjForm = ({setOpen, open, id}) => {
  const [searchValue, setSearchValue] = useState("");

  const [students, setStudents] = useState([])
  const [searchStudents, setSearchStudents] = useState([])
  const [inputStuds, setInputStuds] = useState([])
  const [project, setProject] = useState([])
  const [loading, setLoading] = useState(false)


  const {data: members, isLoading: membersLoading} = useGetMembersQuery()
  const {data: searchMembers, isLoading: searchMembersLoading} = useGetSearchMembersQuery({searchValue})
  const {data: oneProject, isLoading: projectLoading, refetch} = useGetOneProjectQuery(id)
  const [addMember] = useAddMemberToProjMutation()

  useEffect(() => {
    if(!projectLoading && oneProject) {
      setProject(oneProject.data)
    }

    if(!membersLoading && members) {
      const memObj = members.data.map(item => ({label: item.member.full_name, value: item.member.id}))
      setStudents(memObj)
    }
    if(!searchMembersLoading && searchMembers) {
      const memObj = searchMembers.data.map(item => ({label: item.member.full_name, value: item.member.id}))
      setSearchStudents(memObj)
    }
  },[membersLoading, members, searchMembersLoading, searchMembers, searchValue, projectLoading, oneProject])

  const onClose = () => {
    setOpen(false)
    setInputStuds([])
  }
  const handleAddMember = async () => {
    const result = {
      member_ids: inputStuds.map(item => item.value)
    }
    try {
      setLoading(true)
      await addMember({id: id, ...result})
      setInputStuds([])
      refetch()
      setLoading(false)
    } catch (err) {
      alert('Ошибка при добавлении участника в проект')
      setLoading(false)
    }
  };
  const onSave = () => {
    window.location.reload()
  }

  const filteredOptions = students.filter(option => {
    return !project?.project?.members?.some(item => item.member.id === option.value)
  })

  const filteredSearch = searchStudents.filter(option => {
    return !project?.project?.members?.some(item => item.member.id === option.value)
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[907px]">
                <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
                  <div className="sm:flex sm:items-start sm:flex-col">
                    <h2 className="text-primary font-medium text-lg">
                      Добавление участников в проект
                    </h2>
                    <p className="text-[#6B7280] text-sm mt-1">
                      Для добавления участника в проект заполните формы
                    </p>
                    <div
                      className="flex flex-col w-full"
                    >
                      <div className="flex flex-col">
                        <div className="my-6 flex gap-4">
                          <div className="relative w-[100%]">
                            <Select
                              isMulti
                              value={inputStuds}
                              onChange={setInputStuds}
                              inputValue={searchValue}
                              onInputChange={setSearchValue}
                              options={searchValue.length > 3 ? filteredSearch : filteredOptions}
                              placeholder='Поиск и выбор'
                            />
                            {/*<input*/}
                            {/*  id="search-field"*/}
                            {/*  className="block  h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 ring-1 ring-[#D1D5DB] focus:ring-primary sm:text-sm focus:outline-none rounded-md"*/}
                            {/*  placeholder="По названию"*/}
                            {/*  type="search"*/}
                            {/*  name="search"*/}
                            {/*  onChange={(e) => handleSearch(e.target.value)}*/}
                            {/*/>*/}
                            <MagnifyingGlassIcon
                              className="pointer-events-none absolute inset-y-0 right-14 mr-3.5 h-full w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <button
                            disabled={inputStuds.length === 0 || loading}
                            onClick={handleAddMember}
                            className={`flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary ${
                              inputStuds.length === 0 || loading ? "opacity-40" : "opacity-100"
                            } rounded-md hover:bg-secondary`}
                          >
                            <PlusIcon/>
                            <span className="text-white text-base font-medium">
                    Добавить
                  </span>
                          </button>
                        </div>
                        <div className="flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 tracking-wider text-left text-xs font-medium text-[#6B7280] sm:pl-6"
                                    >
                                      ФИО
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 tracking-wider text-center text-xs font-medium text-[#6B7280]"
                                    >
                                      КОНТАКТЫ
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 tracking-wider text-center text-xs font-medium text-[#6B7280]"
                                    >
                                      ПОЛ
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                    ></th>
                                  </tr>
                                  </thead>
                                  {project?.project?.members?.map((item, index) =>
                                    <MembersToProjTable
                                      {...item} project_id={id} key={index} {...item.member}/>)}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    onClick={onSave}
                    className="inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"
                  >
                    Сохранить
                  </button>
                  <button
                    onClick={onClose}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-[#fff] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Отмена
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MemberToProjForm;
