import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const profileApi = createApi({
  reducerPath: 'profileApi',
  tagTypes: 'Profile',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.upshift.colibri.tj/api',
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    editProfile: build.mutation({
      query: ({id, ...body}) => ({
        url: `users/${id}`,
        method: 'PUT',
        body
      }),
    }),
    getProfile: build.query({
      query: (id) => `users/${id}`
    })
  })
})

export const {useGetProfileQuery, useEditProfileMutation} = profileApi;
