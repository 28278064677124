import { Route, Routes } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import Login from "./pages/login/Login";
import Projects from "./pages/projects/Projects";
import Staff from "./pages/staff/Staff";
import ProjectInfo from "./pages/projectInfo/ProjectInfo";
import Cdos from "./pages/cdos/Cdos";
import Partners from "./pages/partners/Partners";
import Roles from "./pages/roles/Roles";
import Profile from "./pages/profile/Profile";
import RequireAuth from "./components/requireAuth/RequireAuth";
import Main from "./pages/main/Main";
import Members from "./pages/members/Members";
import Courses from "./pages/courses/Courses";
import CityReports from "./pages/cityReports/CityReports";
import ClusterReports from "./pages/clusterReports/ClusterReports";
import SubjectReports from "./pages/subjectReports/SubjectReports";
import {Subjects} from "./pages/subjects/Subjects";
import {Directions} from "./pages/directions/DIrections";
import {AddToCoursePage} from "./pages/courses/AddToCoursePage";
import Mahfilho from "./pages/mahfilho/Mahfilho";
import Kormandon from "./pages/kormandon/Kormandon";
import Sayohi from "./pages/sayohi/Sayohi";
import Modi from "./pages/modi/Modi";

function App() {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<RootLayout />}>
          <Route path="/" element={<Main />} />
          <Route path="projects" element={<Projects />} />
          <Route path="staff" element={<Staff />} />
          <Route path="courses" element={<Courses />} />
          <Route path="members" element={<Members />} />
          <Route path="cdo" element={<Cdos />} />
          <Route path="partners" element={<Partners />} />
          <Route path="directions" element={<Directions />} />
          <Route path="subjects" element={<Subjects />} />
          <Route path="roles" element={<Roles />} />
          <Route path="city-reports" element={<CityReports />} />
          <Route path="cluster-reports" element={<ClusterReports />} />
          <Route path="subject-reports" element={<SubjectReports />} />
          <Route path="profile" element={<Profile />} />
          <Route path="mahfilho" element={<Mahfilho />} />
          <Route path="kormandon" element={<Kormandon />} />
          <Route path="sayohi" element={<Sayohi />} />
          <Route path="modi" element={<Modi />} />
          <Route path="/groups/:id" element={<AddToCoursePage />} />
        </Route>
        <Route path="/projects/:id" element={<ProjectInfo />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
