import React from 'react';
import {CircularProgress, Dialog} from "@mui/material";
import {useGetReportStatusQuery} from "../../redux/api/reportStatusApi";

export const ReportInfoModal = ({open, setOpen, userId}) => {


  const {data, isLoading} = useGetReportStatusQuery({userId}, {refetchOnMountOrArgChange: true})


  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      {isLoading ?
        <div className='flex items-center justify-center w-full h-full'>
          <CircularProgress/>
        </div>
        : <div className='flex items-center bg-white justify-center'>
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="flex items-center py-4 pl-4 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
              >
                <span>Название отчета</span>

              </th>
              <th
                scope="col"
                className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
              >
                Дата последнего редактирования
              </th>
              <th
                scope="col"
                className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
              >
                Статус
              </th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">

            <tr>
              <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-primary sm:pl-6 text-left">
                Муассисаҳо, маҳфилҳо
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                {data?.report1[0]?.updated_at.slice(0, -17) ?? ''}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                <span className={`px-2 py-1 rounded-[10px] ${data?.report1.length === 0 ? 'bg-red-300 text-red-700' : data?.report1[0]?.status && data?.report1[0]?.status === 'active' ? 'bg-yellow-300 text-yellow-700' : 'bg-green-300 text-green-700'} `}>{data?.report1.length === 0 ? 'Не заполнен' : data?.report1[0]?.status && data?.report1[0]?.status === 'active' ? 'В процессе' : 'Отправлен'}</span>
              </td>
            </tr>
            <tr>
              <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-primary sm:pl-6 text-left">
                Кормандони омўзгорӣ
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                {data?.report2[0]?.updated_at.slice(0, -17) ?? ''}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                <span className={`px-2 py-1 rounded-[10px] ${data?.report2.length === 0 ? 'bg-red-300 text-red-700' : data?.report2[0]?.status && data?.report2[0]?.status === 'active' ? 'bg-yellow-300 text-yellow-700' : 'bg-green-300 text-green-700'}`}>{data?.report2.length === 0 ? 'Не заполнен' : data?.report2[0]?.status && data?.report2[0]?.status === 'active' ? 'В процессе' : 'Отправлен'}</span>
              </td>
            </tr>
            <tr>
              <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-primary sm:pl-6 text-left">
                Сайёҳӣ ва лагерҳо
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                {data?.report3[0]?.updated_at.slice(0, -17) ?? ''}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                <span className={`px-2 py-1 rounded-[10px] ${data?.report3.length === 0 ? 'bg-red-300 text-red-700' : data?.report3[0]?.status && data?.report3[0]?.status === 'active' ? 'bg-yellow-300 text-yellow-700' : 'bg-green-300 text-green-700'}`}>{data?.report3.length === 0 ? 'Не заполнен' : data?.report3[0]?.status && data?.report3[0]?.status === 'active' ? 'В процессе' : 'Отправлен'}</span>
              </td>
            </tr>
            <tr>
              <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-primary sm:pl-6 text-left">
                Заминаи моддӣ
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                {data?.report4[0]?.updated_at.slice(0, -17) ?? ''}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                <span className={`px-2 py-1 rounded-[10px] ${data?.report4.length === 0 ? 'bg-red-300 text-red-700' : data?.report4[0]?.status && data?.report4[0]?.status === 'active' ? 'bg-yellow-300 text-yellow-700' : 'bg-green-300 text-green-700'}`}>{data?.report4.length === 0 ? 'Не заполнен' : data?.report4[0]?.status && data?.report4[0]?.status === 'active' ? 'В процессе' : 'Отправлен'}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>}
    </Dialog>
  );
};

