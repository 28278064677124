import {useFormContext} from "react-hook-form";

export const SelectInput = ({name, required_message, options, defaultValue}) => {
  const {register, formState: {errors}} = useFormContext()
  return (
    <>
      <select
        defaultValue={defaultValue}
        {...register(name,
          {
            required: `${required_message}`
          })}
        className='border rounded-md border-[#D1D5DB] max-w-full w-full flex-auto'>
        <option value="" hidden>Выберите направление</option>
        {options.map((item, index) => <option key={index} value={item.direction.id}>{item.direction.name}</option>)}
      </select>
      {errors[name] && <p className='font-medium text-red-700'>{errors[name]?.message}</p>}
    </>
  )
}