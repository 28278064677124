import { ReactComponent as Steps } from "../../../assets/img/secondSteps.svg";
import {useEffect, useState} from "react";
import { Transition } from "@headlessui/react";
import {useGetAllPartnersQuery, } from "../../../redux/api/partnersApi";
import {useGetAllMentorsQuery} from "../../../redux/api/staffApi";

const ProjectFormSecondStep = ({
  register,
  data,
  errors,
  watch,
}) => {

  const [mentors, setMentors] = useState([])
  const [partners, setPartners] = useState([])
  const{data:mentorsData, isLoading} = useGetAllMentorsQuery()
  const {data: partnersData, isLoading: partnersLoading} = useGetAllPartnersQuery()


  useEffect(() => {
    if(isLoading){
      setMentors([])
    }
    if(!isLoading && mentorsData) {
      setMentors(mentorsData?.data)
    }
    if(partnersLoading){
      setPartners([])
    }
    if(!partnersLoading && partnersData) {
      setPartners(partnersData?.data)
    }
  },[isLoading, mentorsData, partnersLoading, partnersData])


  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  const { partner_id, start, end, background_of_problem } = data;

  const [startDateState, setStartDateState] = useState(start);
  const [finishDateState, setFinishDateState] = useState(end);
  if(partnersLoading) {
    return <p>Загрузка...</p>
  }



  return (
    <Transition
      appear={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex w-full gap-6 items-center mt-6">
        <div className="w-[50%] flex flex-col gap-1 relative">
          <label
            htmlFor="user_id"
            className="text-[#374151] text-sm font-medium"
          >
            Ментор
          </label>
          <select
            {...register("user_id")}
            className={errors.user_id ? errInputText : inputTextStyle}
          >
            <option value="" hidden>Выберите</option>
            {mentors?.map((user,index) =>
                  <option key={index} value={user?.user?.id}>
                    {user?.user?.full_name}
                  </option>
              )}
          </select>
          {errors.user_id && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.user_id.message}
            </p>
          )}
        </div>
        <div className="w-[50%] flex flex-col gap-1 relative">
          <label
            htmlFor="partner_id"
            className="text-[#374151] text-sm font-medium"
          >
            Партнёр
          </label>
          <select
            id="partner_id"
            name="partner_id"
            defaultValue={partner_id}
            {...register("partner_id")}
            className={errors.partner_id ? errInputText : inputTextStyle}
          >
            <option value="" hidden>Выберите</option>
            {partners?.map((partnerItem) =>
                  <option key={partnerItem?.partner?.id} value={partnerItem?.partner?.id}>
                    {partnerItem?.partner?.name}
                  </option>
              )}
          </select>
          {errors.partner_id && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.partner_id.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full gap-6 items-center mt-6">
        <div className="w-[50%] flex flex-col gap-1 relative">
          <label htmlFor="start" className="text-[#374151] text-sm font-medium">
            Дата начала
          </label>
          <input
            type="date"
            name="start"
            id="start"
            defaultValue={start}
            max={finishDateState}
            onChange={(e) => setStartDateState(e.target.value)}
            {...register("start", {
              onChange: (e) => setStartDateState(e.target.value),
            })}
            className={errors.start ? errInputText : inputTextStyle}
          />
          {errors.start && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.start.message}
            </p>
          )}
        </div>
        <div className="w-[50%] flex flex-col gap-1 relative">
          <label htmlFor="end" className="text-[#374151] text-sm font-medium">
            Дата завершения
          </label>
          <input
            type="date"
            name="end"
            id="end"
            min={startDateState}
            defaultValue={end}
            {...register("end", {
              onChange: (e) => setFinishDateState(e.target.value),
            })}
            className={errors.end ? errInputText : inputTextStyle}
          />
          {errors.end && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.end.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full gap-1 mt-6 relative">
        <label
          htmlFor="background_of_problem"
          className="text-[#374151] text-sm font-medium"
        >
          Предпоссылки проблемы
        </label>
        <textarea
          rows="5"
          name="background_of_problem"
          id="background_of_problem"
          defaultValue={background_of_problem}
          {...register("background_of_problem")}
          className={
            errors.background_of_problem ? errInputText : inputTextStyle
          }
        />
        {errors.background_of_problem && (
          <p className="text-xs text-red-600 absolute bottom-[-22px]">
            {errors.background_of_problem.message}
          </p>
        )}
      </div>
      <div className="flex items-center justify-center w-full mt-[42px]">
        <Steps />
      </div>
    </Transition>
  );
};

export default ProjectFormSecondStep;
