import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: 'Users',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.upshift.colibri.tj/api`,
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getUsers: build.query({
      query: () => `/users`
    }),
    getOneUser: build.query({
      query: (id) => `users/${id}`
    })
  })
})

export const {useGetUsersQuery, useGetOneUserQuery} = userApi;
