import {useForm} from "react-hook-form";
import {useGetAllPecsQuery} from "../../../redux/api/pecsApi";
import {useGetSubjectsQuery} from "../../../redux/api/subjectsApi";
import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useEditCourseMutation} from "../../../redux/api/coursesApi";
import {useNavigate} from "react-router-dom";
import {useGetAllTeachersQuery} from "../../../redux/api/staffApi";

export const EditModal = ({showEdit, setShowEdit, name, start, end, status, id, subject_id, user_id, pec_id}) => {
  const navigate = useNavigate()
  const [pecs, setPecs] = useState([])
  const {data: pecsData, isLoading} = useGetAllPecsQuery()
  const {data: subjects, isLoading: subjectsLoading} = useGetSubjectsQuery({page: 1})
  const [editCourse] = useEditCourseMutation()
  const [teachers, setTeachers] = useState([])
  const {data: teachersData, isLoading: teachersLoading} = useGetAllTeachersQuery()
  useEffect(() => {
    if(teachersLoading) {
      setTeachers([])
    }
    if(!teachersLoading && teachersData) {
      setTeachers(teachersData.data)
    }
  },[teachersLoading,teachersData])

  const {register, handleSubmit, formState: {errors}, reset} = useForm();




  const statuses = [
    {label: "активный", value: 'active'},
    {label: "неактивный", value: 'inactive'},
  ];

  useEffect(() => {
    if(isLoading) {
      setPecs([])
    }
    if(!isLoading && pecsData) {
      setPecs(pecsData)
    }
  }, [isLoading, pecsData])

  const onSubmit = async (data) => {
    try {
      await editCourse({id: id, ...data}).unwrap()
      setShowEdit(false)
      reset()
    } catch (err) {
      alert('Ошибка при изменении группы')
      if(err.originalStatus === 403) {
        localStorage.removeItem('token')
        navigate('/login')
        alert('Вы не авторизованы')
      }
      // alert('Ошибка при изменении группы')
    }
  };

  const onClose = () => {
    setShowEdit(false)
    reset()
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (isLoading) {
    return <p>Загрузка...</p>
  }
  if (subjectsLoading) {
    return <p>Загрузка...</p>
  }


  return (
    <Transition.Root show={showEdit} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[907px]">
                <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
                  <div className="sm:flex sm:items-start sm:flex-col">
                    <h2 className="text-primary font-medium text-lg">
                      Изменение группы
                    </h2>
                    <p className="text-[#6B7280] text-sm mt-1">
                      Для изменения группы заполните
                      формы
                    </p>
                      <form
                        className="flex flex-col w-full"
                      >
                        <div className="flex flex-col gap-6 w-full mt-6 mb-2">
                          <div className="flex flex-col gap-1 flex-auto relative">
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-[#374151]"
                            >
                              Название
                            </label>
                            <input
                              type="text"
                              defaultValue={name}
                              {...register( 'name' ,
                                {
                                  required: 'Заполните это поле'
                                })}
                              className={errors.name ? 'errInputText' : 'inputTextStyle'}
                            />
                            {errors.name && (
                              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                {errors.name.message}
                              </p>
                            )}
                            {/*<TextInput name='name' required_message='Заполните это поле'/>*/}
                          </div>
                          <div className="flex flex-auto items-center gap-6">
                            <div className='flex-auto max-w-full w-full'>
                              <label>Предмет</label>
                              <select
                                className={errors.subject_id ? 'errInputText' : 'inputTextStyle'}
                                defaultValue={subject_id}
                                {...register('subject_id',
                                  {
                                    required: 'Выберите предмет'
                                  })}>
                                {subjects?.data?.map((item, index) => <option key={index}
                                                                           value={item.subject.id}>{item.subject.name}</option>)}
                              </select>
                              {errors[name] && <p className="text-xs text-red-600 absolute bottom-[-22px]">
                              </p>}
                            </div>

                            <div className='flex-auto max-w-full w-full'>
                              <label
                                htmlFor="teacher"
                                className="text-[#374151] text-sm font-medium"
                              >
                                Учитель
                              </label>
                              <select
                                defaultValue={user_id}
                                className={errors.user_id ? 'errInputText' : 'inputTextStyle'}
                                {...register('user_id',
                                  {
                                    required: 'Выберите преподавателя'
                                  })}
                              >
                                {teachers
                                  .map((item) => {
                                    return (
                                      <option key={item?.user?.id} value={item?.user?.id}>
                                        {item?.user?.full_name}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.user_id && (
                                <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                  {errors.user_id.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center gap-6">
                            <div className="flex flex-col gap-1 w-[50%] relative">
                              <label
                                htmlFor="cdo_id"
                                className="text-[#374151] text-sm font-medium"
                              >
                                ЦДО
                              </label>
                              <select
                                defaultValue={pec_id}
                                className={errors.pec_id ? 'errInputText' : 'inputTextStyle'}
                                {...register('pec_id',
                                  {
                                    required: 'Выберите ЦДО'
                                  })}
                              >
                                {pecs?.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.pec_id && (
                                <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                  {errors.pec_id.message}
                                </p>
                              )}
                              {/*<SelectInput name='pec_id' required_message='Выберите ЦДО' options={pecs}*/}
                              {/*             default_message='Выберите ЦДО'/>*/}
                            </div>
                            <div className="flex flex-col gap-1 w-[50%] relative">
                              <label
                                htmlFor="status"
                                className="block text-sm font-medium text-[#374151]"
                              >
                                Статус
                              </label>
                              <select
                                defaultValue={status}
                                {...register("status")}
                                className={errors.status ? 'errInputText' : 'inputTextStyle'}
                              >
                                {statuses
                                  .map((item, i) => {
                                    return (
                                      <option key={i} value={item.value}>
                                        {capitalizeFirstLetter(item.label)}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.status && (
                                <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                  {errors.status.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center gap-6">
                            <div className="flex flex-col gap-1 w-[50%] relative">
                              <label
                                htmlFor="startDate"
                                className="text-[#374151] text-sm font-medium"
                              >
                                Дата начала
                              </label>
                              <input
                                type="date"
                                defaultValue={start}
                                {...register("start", {
                                })}
                                className={errors.start ? 'errInputText' : 'inputTextStyle'}
                              />
                              {errors.start && (
                                <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                  {errors.start.message}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col gap-1 w-[50%] relative">
                              <label
                                htmlFor="finishDate"
                                className="text-[#374151] text-sm font-medium"
                              >
                                Дата завершения
                              </label>
                              <input
                                type="date"
                                defaultValue={end}

                                {...register("end", {
                                })}
                                className={
                                  errors.end ? 'errInputText' : 'inputTextStyle'
                                }
                              />
                              {errors.end && (
                                <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                  {errors.end.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                  </div>
                </div>
                <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"
                  >
                    Сохранить
                  </button>
                  <button
                    onClick={onClose}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-[#fff] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Отмена
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

