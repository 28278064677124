import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const reportStatusApi = createApi({
  reducerPath: 'reportStatusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.upshift.colibri.tj/api`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        headers.set('Accept', 'application/json')
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getReportStatus: build.query({
      query: (params) => `/report/user?user_id=${params.userId}`
    })
  })
})

export const {useGetReportStatusQuery} = reportStatusApi
