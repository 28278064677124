import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import {useState} from "react";
import {EditModal} from "./EditModal";


export const PartnersNewTable = ({id, name, address, phone, status, partner, roles}) => {
  const [showEdit, setShowEdit] = useState(false)
  const activeStatusStyle =
    "inline-block text-center bg-[#D1FAE5] py-0.5 px-2.5 rounded-[10px] text-[#065F46] font-medium w-[94px]";
  const inactiveStatusStyle =
    "inline-block text-center bg-[#FEE2E2] py-0.5 px-2.5 rounded-[10px] text-[#991B1B] font-medium w-[94px]";

  return (
    <tr key={id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
        {name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {address}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        +992 {phone}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-center">
                      <span
                        className={
                          status === "active"
                            ? activeStatusStyle
                            : inactiveStatusStyle
                        }
                      >
                        {status === "active"
                          ? "Активный"
                          : "Неактивный"}
                      </span>
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
        {roles?.includes('update-partner') && <button onClick={() => setShowEdit(true)}
                 className="text-primary hover:text-secondary"
        >
          <EditIcon/>
          <span className="sr-only">, {name}</span>
        </button>}
      </td>
      <EditModal {...partner} showEdit={showEdit} setShowEdit={setShowEdit}/>
    </tr>
  )
}