import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const membersApi = createApi({
  reducerPath: 'membersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.upshift.colibri.tj/api/',
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getSearchMembers: build.query({
      query: (params) => `/search/members?keyword=${params.search}`
    }),
    getMembers: build.query({
      query: (params) => `members`
    }),
    getAllMembers: build.query({
      query: () => `get/all/members`
    }),
    addMemberToProj: build.mutation({
      query: ({id, ...body}) => ({
        url: `projects/${id}/members`,
        method: 'POST',
        body
      }),
    }),
    deleteMemberFromProj: build.mutation({
      query: ({id, ...body}) => ({
        url: `projects/${id}/members/delete`,
        method: 'POST',
        body
      })
    }),
    deleteMemberFromGroup: build.mutation({
      query: ({id, ...body}) => ({
        url: `groups/${id}/members/delete`,
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useGetSearchMembersQuery,
  useGetMembersQuery,
  useAddMemberToProjMutation,
  useDeleteMemberFromProjMutation,
  useDeleteMemberFromGroupMutation,
  useGetAllMembersQuery
} = membersApi;
