import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useForm} from "react-hook-form";
import {useEditPartnerMutation, useGetPartnersQuery} from "../../../redux/api/partnersApi";

export const EditModal = ({showEdit, setShowEdit, name, phone, address, status, id}) => {

  const {register, handleSubmit, formState:{errors}, reset} = useForm()
  const {refetch: partnersRefetch} = useGetPartnersQuery()
  const [editPartner] = useEditPartnerMutation()

  const onSubmit = async (data) => {
    try {
      await editPartner({id: id, ...data})
      reset()
      setShowEdit(false)
      window.location.reload()
    } catch (err) {
      alert('Ошибка при изменении партнера')
    }
  }

  const validateInputField = (value) => {
    return value.length === 9 || 'Длина должна быть ровно 9 символов';
  };

  return (
    <Transition.Root show={showEdit} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setShowEdit}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[907px]">
                <div>
                  <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
                    <div className="sm:flex sm:items-start sm:flex-col">
                      <h2 className="text-primary font-medium text-lg">
                        Изменить Партнера
                      </h2>
                      <p className="text-[#6B7280] text-sm mt-1">
                        Для изменения партнёров заполните формы
                      </p>
                      <form
                        className="flex flex-col w-full mt-6 mb-2"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="flex items-center gap-6 w-full">
                          <div className="flex flex-col gap-1 w-[50%] relative">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-[#374151]"
                            >
                              Название
                            </label>
                            <input
                              type="text"
                              id="name"
                              defaultValue={name}
                              {...register("name")}
                              className={errors.name ? 'errInputText' : 'inputTextStyle'}
                            />
                            {errors.name && (
                              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                {errors.name.message}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col gap-1 w-[50%] relative">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-[#374151]"
                            >
                              Номер телефона
                            </label>
                            <div className="flex rounded-md">
                  <span
                    className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-sm">
                    +992
                  </span>
                              <input
                                type="text"
                                defaultValue={phone}
                                {...register("phone",
                                  {
                                    validate: validateInputField
                                  })}
                                className={
                                  errors.phone
                                    ? `errInputText rounded-l-none`
                                    : `inputTextStyle rounded-l-none`
                                }
                              />
                            </div>
                            {errors?.phone && (
                              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                {errors.phone.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-6 w-full mt-6">
                          <div className="flex flex-col gap-1 w-[50%] relative">
                            <label
                              htmlFor="address"
                              className="block text-sm font-medium text-[#374151]"
                            >
                              Адрес
                            </label>
                            <input
                              type="text"
                              id="address"
                              {...register("address",
                                {
                                  required: 'Заполните это поле'
                                })}
                              defaultValue={address}
                              className={errors.address ? 'errInputText' : 'inputTextStyle'}
                            />
                            {errors.address && (
                              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                {errors.address.message}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col gap-1 w-[50%] relative">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium text-[#374151]"
                            >
                              Статус
                            </label>
                            <select
                              id="status"
                              {...register("status")}
                              className={errors.status ? 'errInputText' : 'inputTextStyle'}
                            >
                              <option value={status}>
                                {status === "active" ? "Активный" : "Неактивный"}
                              </option>
                              <option value={status === "active" ? "inactive" : "active"}>
                                {status === "active" ? "Неактивный" : "Активный"}
                              </option>
                            </select>
                            {errors.status && (
                              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                                {errors.status.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Сохранить
                    </button>
                    <button
                      onClick={() => setShowEdit(false)}
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-[#FFFFFF] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Отмена
                    </button>
                  </div>

                </div>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}