import { useState } from "react";
import ProjectFormFirstStep from "./ProjectFormFirstStep";
import ProjectFormFourthStep from "./ProjectFormFourthStep";
import ProjectFormSecondStep from "./ProjectFormSecondStep";
import ProjectFormThirdStep from "./ProjectFormThirdStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ProjectForm = ({ setOpen, handleSaveBtn, data, setData, onSubmitForm, modalType, loading }) => {
  const [page, setPage] = useState(0);
  const [selectedImg, setSelectedImg] = useState("");
  const [selectedDoc, setSelectedDoc] = useState("");


  const title = {
    0: "Общая информация",
    1: "Подробная информация",
    2: "Информация о проблеме",
    3: "Информация о расходах",
  };
  const validationSchema = [
    //validation for step 1
    yup.object({
      photo: yup.mixed(),
      name: yup.string().required("Поле не должно быть пустым"),
      city_id: yup.string().required("Поле не должно быть пустым"),
      status: yup.string().required("Поле не должно быть пустым"),
    }),

    //validation for step 2
    yup.object({
      user_id: yup.string().required("Поле не должно быть пустым"),
      partner_id: yup.string().required("Поле не должно быть пустым"),
      start: yup.string().required("Укажите дату"),
      end: yup.string().required("Укажите дату"),
      background_of_problem: yup
        .string()
        .required("Поле не должно быть пустым"),
    }),

    //validation for step 3
    yup.object({
      description_of_problem: yup
        .string()
        .required("Поле не должно быть пустым"),
      solution: yup.string().required("Поле не должно быть пустым"),
      innovation: yup.string().required("Поле не должно быть пустым"),
    }),

    //validation for step 4
    yup.object({
      equipment: yup
        .number()
        .typeError("Укажите сумму ")
        .required("Укажите сумму"),
      transport_costs: yup
        .number()
        .typeError("Укажите сумму ")
        .required("Укажите сумму"),
      services: yup
        .number()
        .typeError("Укажите сумму ")
        .required("Укажите сумму"),
      rent: yup.number().typeError("Укажите сумму ").required("Укажите сумму"),
      raw_materials: yup
        .number()
        .typeError("Укажите сумму ")
        .required("Укажите сумму"),
      other: yup.number().typeError("Укажите сумму ").required("Укажите сумму"),
      tools: yup.string().required("Поле не должно быть пустым"),
    }),
  ];

  const currentValidationSchema = validationSchema[page];

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(currentValidationSchema),
    mode: "onChange",
  });

  const handlePrev = () => {
    setPage((prev) => prev - 1);
  };
  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setPage((prev) => prev + 1);
  };

  const onSubmit = async (data) => {
    // const formData = new FormData()
    // formData.append("photo", selectedImg);
    data = { ...data, media_file: selectedImg};
    // formData.append("photo", selectedDoc);
    data = { ...data, documents: selectedDoc };
    for(let key in data) {
      if(typeof data[key] == 'number') {
        data[key] = String(data[key])
      }
    }
    onSubmitForm(data)
  };


  return (
    <>
      <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
        <div className="sm:flex sm:items-start sm:flex-col">
          <h2 className="text-primary font-medium text-lg">
            {modalType === 'adding' ? 'Добавление проекта': 'Изменение проекта'}
          </h2>
          {modalType === 'adding' ?
          <p className="text-[#6B7280] text-sm mt-1">
            Для добавления проекта заполните формы
          </p>
          : ''}
          <form className="flex flex-col w-full" onSubmit={handleSubmit}>
            {page === 0 && (
              <ProjectFormFirstStep
                modalType={modalType}
                register={register}
                setData={setData}
                data={data}
                errors={errors}
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
                selectedDoc={selectedDoc}
                setSelectedDoc={setSelectedDoc}
              />
            )}
            {page === 1 && (
              <ProjectFormSecondStep
                register={register}
                data={data}
                errors={errors}
                watch={watch}
              />
            )}
            {page === 2 && (
              <ProjectFormThirdStep
                register={register}
                data={data}
                errors={errors}
              />
            )}
            {page === 3 && (
              <ProjectFormFourthStep
                loading={loading}
                register={register}
                data={data}
                errors={errors}
              />
            )}
          </form>
        </div>
      </div>
      <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        {page !== Object.keys(title).length - 1 && (
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-primary px-12 py-2 text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto"
            onClick={handleNext}
          >
            Далее
          </button>
        )}
        {page === Object.keys(title).length - 1 && (
          <button
            type="submit"
            disabled={loading}
            className={loading ? "opacity-50 inline-flex w-full justify-center rounded-md bg-primary px-8 py-2 text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30" :"opacity-100 inline-flex w-full justify-center rounded-md bg-primary px-8 py-2 text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"}
            onClick={handleSubmit(onSubmit)}
          >
            Сохранить
          </button>
        )}
        {page !== 0 && (
          <button
            type="button"
            className={"opacity-100 mt-3 inline-flex w-full justify-center rounded-md bg-[#FFFFFF] px-12 py-2 text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"}
            onClick={handlePrev}
          >
            Назад
          </button>
        )}
        {page === 0 && (
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-[#FFFFFF] px-11 py-2 text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          >
            Отмена
          </button>
        )}
      </div>
    </>
  );
};

export default ProjectForm;
