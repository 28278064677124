import DeleteModal from "../../modals/deleteModal/DeleteModal";

import {ReactComponent as SortIcon} from "../../../assets/icons/sort-icon.svg";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {ReportInfoModal} from "../../../pages/staff/ReportInfoModal";
import {useState} from "react";
import {UserRow} from "../../../pages/staff/UserRow";

export default function StaffTable({users, deleteUser, handleEditBtn, roles, pecName, partnerName}) {


  return (
    <>
      <div className="flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="flex items-center py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                  >
                    <span>ФИО</span>
                    <button className="ml-4">
                      <SortIcon/>
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    Организация
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    КОНТАКТЫ
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    РОЛИ
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  ></th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">

                {users.map((user) => (
                  <UserRow key={user.user.id} roles={roles} user={user} deleteUser={deleteUser} handleEditBtn={handleEditBtn}/>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </>
  );
}
