import {Transition} from "@headlessui/react";
import React from "react";
import {ReactComponent as Steps} from "../../../../assets/img/firstSteps.svg";
import {useFormContext} from "react-hook-form";
import {locations, statuses} from "../../../../components/hardCodedArrays/Arrays";
import {ProjTextInput} from "../inputs/ProjTextInput";
import {useDispatch, useSelector} from "react-redux";
import {
  setDocsFromBack,
  setSelectedDocs,
  setSelectedPhoto
} from "../../../../redux/slices/EditProjectSlice";
import {useDropzone} from "react-dropzone";

export const FirstStep = ({setPage, onClose, name, city_id, status, media_file, documents}) => {
  const dispatch = useDispatch()
  const {selectedPhoto, selectedDocs, docsFromBack} = useSelector(state => state.EditProject)
  const {formState: {errors}, handleSubmit, register} = useFormContext()

  const doc = documents?.slice(18, documents.length)


  const {getRootProps: avatarRootProps, getInputProps: avatarInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        dispatch(setSelectedPhoto(file))
      }
    }
  })


  const {getInputProps: filesInputProps, getRootProps: filesRootProps} = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        dispatch(setSelectedDocs(file))
      }
    }
  })


  const handleNextPage = () => {
    setPage(2)
  }
  const onDelete = () => {
    dispatch(setSelectedPhoto(null))
  }

  return (
    <>
      <Transition
        appear={true}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className='px-4 pt-5 pb-4 sm:pb-6'>
          <div className="col-span-full">
            <label
              htmlFor="projectTeamAvatar"
              className="block text-sm font-medium text-[#374151]"
            >
              Фото команды
            </label>
            {selectedPhoto ?
              <div className='mt-1 flex items-end gap-5'>
                <img
                  className="w-[48px] h-[48px] object-cover rounded-md"
                  src={URL.createObjectURL(selectedPhoto)}
                  alt="team-avatar"
                />
                <div>
                  <p onClick={onDelete}
                     className="cursor-pointer rounded-md bg-white px-[13px] py-[9px] text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Удалить
                  </p>
                </div>
              </div>
              :
              <div className="mt-1 flex items-end gap-5">
                <img
                  className="w-[48px] h-[48px] object-cover rounded-md"
                  src={`https://api.upshift.colibri.tj/public/storage/${media_file}`}
                  alt="team-avatar"
                />
                <div {...avatarRootProps()}>
                  <p
                    className="cursor-pointer rounded-md bg-white px-[13px] py-[9px] text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Изменить
                  </p>
                  <input
                    {...avatarInputProps()}
                    type="file"
                    id="photo"
                    name="photo"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    className="hidden"
                  />
                </div>
              </div>
            }

          </div>
          <div className="flex items-center gap-6 mt-6 sm:w-full">
            <div className="w-[33%]">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-[#374151]"
              >
                Название проекта
              </label>
              <div className="mt-1 relative">
                <ProjTextInput name='name' required_message='Заполните это поле'/>
              </div>
            </div>
            <div className="w-[33%] flex flex-col gap-1 relative">
              <label
                htmlFor="projectLocation"
                className="block text-sm font-medium text-[#374151]"
              >
                Местоположение
              </label>
              <select
                {...register('city_id',
                  {
                    required: `Выберите местоположение`
                  })}
                className='inputTextStyle'
              >
                {locations.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
              </select>
              {errors[name] && (
                <p className="text-xs text-red-600 absolute bottom-[-22px]">
                  {errors.city_id.message}
                </p>
              )}
            </div>
            <div className="w-[33%] flex flex-col gap-1 relative">
              <label
                htmlFor="status"
                className="block text-sm font-medium text-[#374151]"
              >
                Статус
              </label>
              {/*<SpecialProjSelectInput name='status' required_message='Выберите статус' options={statuses}/>*/}
              <select
                {...register('status',
                  {
                    required: `Выберите статус`
                  })}
                className='inputTextStyle'
              >
                {statuses.map((item, index) => <option key={index} value={item}>{item}</option>)}
              </select>
              {errors.status && (
                <p className="text-xs text-red-600 absolute bottom-[-22px]">
                  {errors.status.message}
                </p>
              )}
            </div>
          </div>
          <div className="col-span-full mt-[26px] sm:w-full">
            <p className="block text-sm font-medium text-[#374151]">Вложения</p>
            {selectedDocs ?
              <div>
                  <span>
                  {selectedDocs.name}
                  </span>
                <button onClick={() => dispatch(setSelectedDocs(null))}
                        className='ml-3 cursor-pointer rounded-md bg-white px-[13px] py-[9px] text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                        type='button'>Удалить
                </button>
              </div> :
              <div className='flex gap-3 items-center'>
                <span>{doc}</span>
                <div {...filesRootProps()}>
                  <p
                    className="cursor-pointer rounded-md bg-white px-[13px] py-[9px] text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Изменить
                  </p>
                  <input
                    {...filesInputProps()}
                    type="file"
                    id="photo"
                    name="photo"
                    className="hidden"
                  />
                </div>
              </div>

            }
            {/*{documents && docsFromBack ?*/}
            {/*  <div className='flex gap-3 items-center mt-2'>*/}
            {/*    <span>{doc}</span>*/}
            {/*    <button type='button'*/}
            {/*            className='ml-3 cursor-pointer rounded-md bg-white px-[13px] py-[9px] text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'*/}
            {/*            onClick={() => dispatch(setDocsFromBack(false))}>*/}
            {/*      Удалить*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*  :*/}
            {/*  selectedDocs ?*/}

            {/*    :*/}
            {/*    <label htmlFor="projectAttachments">*/}
            {/*      <div*/}
            {/*        className="mt-1 flex justify-center rounded-md border-2 border-dashed border-[#D1D5DB] px-6 py-[7px] cursor-pointer">*/}
            {/*        <div className="text-center">*/}
            {/*          <ImageIcon*/}
            {/*            className="mx-auto h-12 w-12 text-gray-300"*/}
            {/*            aria-hidden="true"*/}
            {/*          />*/}
            {/*          <div>*/}
            {/*            <div className="flex justify-center text-sm">*/}
            {/*          <span*/}
            {/*            className="relative cursor-pointer rounded-md bg-white font-semibold text-primary text-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-secondary"*/}
            {/*          >*/}
            {/*            <span>Выберите</span>*/}
            {/*          </span>*/}
            {/*              <span className="pl-1 text-[#4B5563] font-medium text-sm">*/}
            {/*          файл*/}
            {/*        </span>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*          <div  {...filesRootProps()}>*/}
            {/*            <input*/}
            {/*              {...filesInputProps()}*/}
            {/*              id="projectAttachments"*/}
            {/*              name="projectAttachments"*/}
            {/*              type="file"*/}
            {/*              className="sr-only"*/}
            {/*              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*          <span className="text-xs text-[#6B7280]">*/}
            {/*    DOC, XLSX, PPTX, PDF до 10MB*/}
            {/*  </span>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </label>}*/}
            {errors.documents && <p className='text-[red]'>{errors.documents.message}</p>}
          </div>
          <div className="flex items-center justify-center w-full mt-[55px]">
            <Steps/>
          </div>
        </div>
      </Transition>
      <div className='flex p-6 justify-end bg-[#FBF9FB] max-w-full w-full mx-auto gap-4'>
        <button type='button' onClick={onClose}
                className='px-8 py-3 rounded-md bg-white border border-[#D1D5DB] hover:bg-gray-50'>Отмена
        </button>
        <button onClick={handleSubmit(handleNextPage)}
                className='px-8 py-3 rounded-md bg-[#39044E] text-white hover:bg-secondary'>Далее
        </button>
      </div>
    </>
  )
}
