import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const PartnerForm = ({ setOpen, handleSaveBtn, data, numberError, setNumberError }) => {
  const schema = yup.object().shape({
    name: yup.string().required("Поле не должно быть пустым"),
    phone: yup
      .string()
      .required("Поле не должно быть пустым")
      .matches(/^[0-9]+$/, "Неверный формат номера")
      .min(9, "Неверная длина")
      .max(9, "Неверная длина"),
    address: yup.string().required("Поле не должно быть пустым"),
    status: yup.string().required("Поле не должно быть пустым"),
  });


  const { id, name, address, phone, status } = data;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (id) {
      handleSaveBtn(data, id);
    } else {
      handleSaveBtn(data);
    }
  };

  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  return (
    <>
      <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
        <div className="sm:flex sm:items-start sm:flex-col">
          <h2 className="text-primary font-medium text-lg">
            Добавить Партнера
          </h2>
          <p className="text-[#6B7280] text-sm mt-1">
            Для добавления партнёров заполните формы
          </p>
          <form
            className="flex flex-col w-full mt-6 mb-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex items-center gap-6 w-full">
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-[#374151]"
                >
                  Название
                </label>
                <input
                  type="text"
                  id="name"
                  defaultValue={name}
                  {...register("name")}
                  className={errors.name ? errInputText : inputTextStyle}
                />
                {errors.name && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.name.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-[#374151]"
                >
                  Номер телефона
                </label>
                <div className="flex rounded-md">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-sm">
                    +992
                  </span>
                  <input
                    type="text"
                    id="phone"
                    defaultValue={phone}
                    {...register("phone")}
                    className={
                      errors.phone
                        ? `${errInputText} rounded-l-none`
                        : `${inputTextStyle} rounded-l-none`
                    }
                  />
                </div>
                {errors.phone && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.phone.message}
                  </p>
                )}
                {numberError && <p className="text-xs text-red-600 absolute bottom-[-22px]">
                  Номер телефона уже существует
                </p>}
              </div>
            </div>
            <div className="flex items-center gap-6 w-full mt-6">
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-[#374151]"
                >
                  Адрес
                </label>
                <input
                  type="text"
                  id="address"
                  {...register("address")}
                  defaultValue={address}
                  className={errors.address ? errInputText : inputTextStyle}
                />
                {errors.address && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.address.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-[50%] relative">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-[#374151]"
                >
                  Статус
                </label>
                <select
                  id="status"
                  {...register("status")}
                  className={errors.status ? errInputText : inputTextStyle}
                >
                  <option value={status}>
                    {status === "active" ? "Активный" : "Неактивный"}
                  </option>
                  <option value={status === "active" ? "inactive" : "active"}>
                    {status === "active" ? "Неактивный" : "Активный"}
                  </option>
                </select>
                {errors.status && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.status.message}
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"
          onClick={handleSubmit(onSubmit)}
        >
          Добавить
        </button>
        <button
          onClick={() => setOpen(false)}
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-[#FFFFFF] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Отмена
        </button>
      </div>
    </>
  );
};
export default PartnerForm;
