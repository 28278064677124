import {useEffect, useState} from "react";
import {ReactComponent as DownloadIcon} from "../../assets/icons/download-icon.svg";
import ClusterReportsTable from "../../components/tables/clusterReportsTable/ClusterReportsTable";
import {useGetDirectionReportQuery} from "../../redux/api/reportsApi";
import {useGetDirectionsQuery} from "../../redux/api/directionsApi";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import 'jspdf-autotable'

const ClusterReports = () => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [directionReports, setDirectionReports] = useState([])
  const [direction, setDirection] = useState([])
  const [showButton, setShowButton] = useState(true)

  const {data: directionReportData, isLoading} = useGetDirectionReportQuery({startDate, endDate})
  const {data: directionsData, isLoading: directionsLoading} = useGetDirectionsQuery({page: 1})

  useEffect(() => {
    if (isLoading) {
      setDirectionReports([])
    }
    if (!isLoading && directionReportData) {
      setDirectionReports(directionReportData)
    }
    if (directionsLoading) {
      setDirection([])
    }
    if (!directionsLoading && directionsData) {
      setDirection(directionsData.data)
    }
  }, [startDate, endDate, isLoading, directionReportData, directionsLoading, directionsData])

  const handleConvertToPdf = (e) => {
    e.stopPropagation()
    const element = document.getElementById('direction-report')

    const pdf = new jsPDF('1', 'mm', 'a4')

    html2canvas(element).then((canvas) => {
      setShowButton(false)
      const imageData = canvas.toDataURL('image/png')

      pdf.addImage(imageData, 'PNG', 10, 10, 190, 0)

      pdf.setDisplayMode('fullpage', 'continuous');

      pdf.save('direction-report.pdf');
    })

  }

  return (
    <div className="flex flex-col h-full" id='direction-report' onClick={() => setShowButton(true)}>
      <div className="flex flex-col gap-4 mb-4">
        <h1 className="font-semibold ml-3 text-primary text-2xl">
          Отчет по направлениям
        </h1>
        <div className="flex max-w-[1300px] w-full justify-between">
          <div className="flex ml-3 gap-4 items-center">
            {showButton ? <input
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              className="block w-[50%] xl:min-w-[235px] rounded-md border-0 py-[11px] text-[25px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset"
            /> : <p>{startDate}</p>}
            {showButton ? <input
              type="date"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              className="block ml-3 w-[50%] xl:min-w-[235px] rounded-md border-0 py-[11px] text-[25px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset"
            /> : <p>{endDate}</p>}
          </div>
          <div className="flex gap-4 items-center">
            {showButton && <button onClick={handleConvertToPdf}
                                   className="exclude-from-pdf flex items-center gap-3 bg-[#EEF2FF] hover:bg-[#f5f7fc] rounded-md py-[9px] px-[15px]">
              <DownloadIcon/>
              <span className="font-medium text-primary">Скачать отчет</span>
            </button>}
            {/*<button className="flex items-center justify-center py-[9px] px-[15px] gap-3 bg-primary rounded-md hover:bg-secondary">*/}
            {/*  <PlusIcon />*/}
            {/*  <span className="text-white text-base font-medium">*/}
            {/*    Сформировать отчет*/}
            {/*  </span>*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
      <ClusterReportsTable direction={direction} reports={directionReports}/>
      {/*{totalReports > 8 && (*/}
      {/*  <Pagination*/}
      {/*    totalPage={totalReports / 8}*/}
      {/*    setCurrentPage={setCurrentPage}*/}
      {/*    totalObjects={totalReports}*/}
      {/*    to={8}*/}
      {/*    from={1}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default ClusterReports;
