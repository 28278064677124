import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("token")) || ""
  );
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );

  return (
    <AuthContext.Provider value={{ token, setToken, userInfo, setUserInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
