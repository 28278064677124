import logo from "../../assets/logo/primaryLogo.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as CrossedEyeIcon } from "../../assets/icons/crossed-eye-icon.svg";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthContext from "../../context/AuthProvider";
import * as yup from "yup";
import axiosInstance from "../../api/axios";
const LOGIN_URL = "/auth/login";

const Login = () => {
  const [errMsg, setErrMsg] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false)

  const { setToken, setUserInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Поле не должно быть пустым")
      .matches(/^[0-9]+$/, "Необходимо ввести только цифры")
      .min(9, "Нужно ввести 9 символов")
      .max(9, "Нужно ввести 9 символов"),
    password: yup
      .string()
      // .min(4, "Пароль должен содержать не менее 4 символов")
      // .required("Поле не должно быть пустым"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValidating },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const inputTextStyle =
    "block w-full rounded-r-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-r-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  useEffect(() => {
    setFocus("phone");
  }, [setFocus]);

  useEffect(() => {
    isValidating && setErrMsg("");
  }, [isValidating]);

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const response = await axiosInstance.post(
        LOGIN_URL,
        JSON.stringify(data),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user", JSON.stringify(response.data.user));
      setToken(response.data.token);
      setUserInfo(response.data.user);
      navigate(from, { replace: true });
      setLoading(false)
    } catch (error) {
      if (!error.response) {
        setErrMsg("Сервер не отвечает");
      } else if (error.response?.status === 400) {
        setErrMsg("Отсутствует номер телефона или пароль");
      } else if (error.response?.status === 422) {
        setErrMsg("Пользователь не авторизован");
      } else {
        setErrMsg("Не удалось выполнить вход");
      }
    }
    setLoading(false)
  };

  const handleEyeClick = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <div className="bg-back h-screen">
      <div className="flex min-h-full flex-col justify-center pb-12">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img src={logo} alt="unicef-logo" className="max-w-full w-full ml-[55px]" />
          <h2 className="mt-8 text-center text-3xl font-bold tracking-tight text-primary">
            Войдите в свой аккаунт
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="flex flex-col gap-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="relative">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-900"
                >
                  Номер телефона
                </label>
                <div className="mt-2 flex rounded-md">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-sm">
                    +992
                  </span>
                  <input
                    id="phone"
                    type="tel"
                    {...register("phone")}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    className={errors.phone ? errInputText : inputTextStyle}
                  />
                </div>
                {errors.phone && (
                  <p className="mt-1 text-xs text-red-600 absolute">
                    {errors.phone.message}
                  </p>
                )}
              </div>

              <div className="mt-1 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-900"
                >
                  Пароль
                </label>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    type={passwordType}
                    autoComplete="current-password"
                    required
                    {...register("password")}
                    className={
                      errors.password
                        ? `${errInputText} rounded-l-md`
                        : `${inputTextStyle} rounded-l-md`
                    }
                  />
                  <button
                    type="button"
                    className="absolute top-0 bottom-0 right-[13px]"
                    onClick={handleEyeClick}
                  >
                    {passwordType === "text" ? <CrossedEyeIcon /> : <EyeIcon />}
                  </button>
                </div>
                {errors.password && (
                  <p className="mt-1 text-xs text-red-600 absolute">
                    {errors.password.message}
                  </p>
                )}
                {errMsg && !errors.password && (
                  <p className="mt-1 text-xs text-red-600 absolute">{errMsg}</p>
                )}
              </div>

              <div className="mt-2">
                <button
                  disabled={loading}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className={loading ? "opacity-50 flex w-full justify-center rounded-md bg-primary py-2.5 px-3 text-sm font-medium text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary" : "opacity-100 flex w-full justify-center rounded-md bg-primary py-2.5 px-3 text-sm font-medium text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"}
                >
                  Войти
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
