import {useState, useEffect, useContext} from "react";
import {ReactComponent as EyeIcon} from "../../../assets/icons/eye-icon.svg";
import {ReactComponent as CrossedEyeIcon} from "../../../assets/icons/crossed-eye-icon.svg";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosInstance from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import {useGetAllRolesQuery} from "../../../redux/api/roleApi";
import {useGetAllPartnersQuery} from "../../../redux/api/partnersApi";

const StaffForm = ({setOpen, handleSaveBtn, dataData, errMsg, setErrMsg, user, loading}) => {
  const [pwdType, setPwdType] = useState("password");
  const [confirmPwdType, setConfirmPwdType] = useState("password");
  const [cdos, setCdos] = useState([]);
  const [role, setRole] = useState([])
  const [pecName, setPecName] = useState('')
  const [name, setName] = useState('')
  const [partners, setPartners] = useState([])
  const [partnerName, setPartnerName] = useState('')
  const [pecLoading, setPecLoading] = useState(false)
  const {data: rolesData, isLoading: rolesLoading} = useGetAllRolesQuery()

  const schema = yup.object().shape({
    full_name: yup.string().required("Поле не должно быть пустым"),
    phone: yup
      .string()
      .required("Поле не должно быть пустым")
      .matches(/^[0-9]+$/, "Необходимо ввести только цифры")
      .min(9, "Необходимо ввести 9 символов")
      .max(9, "Необходимо ввести 9 символов"),

    pec_id: name === 'ЦДО' || name === 'Республиканский ЦДО' ? yup.string().required("Поле не должно быть пустым") : null,
    partner_id: name !== 'ЦДО' && name !== 'Республиканский ЦДО' ? yup.string().required('Поле не должно быть пустым') : null,
    role_id: yup.string().required("Поле не должно быть пустым"),
    password: dataData?.user?.id ? yup.string() : yup.string().required("Поле не должно быть пустым").min(8, 'Минимум 8 символов'),
    password_confirmation: dataData?.user?.id ? yup
        .string()
        .oneOf([yup.ref("password"), null], "Пароли не совпадают")
      : yup
        .string()
        .oneOf([yup.ref("password"), null], "Пароли не совпадают")
        .required("Поле не должно быть пустым"),
    address: yup.string().required("Поле не должно быть пустым"),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {token} = useContext(AuthContext);


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setName(user?.role[0])
    setPartnerName(user?.partner?.name)
    setPecName(user?.pec?.name)
  }, [name])




  useEffect(() => {
    const getCdos = async () => {
      try {
        setPecLoading(true)
        const response = await axiosInstance.get(`get/all/pecs`, {
          headers: {Authorization: `Bearer ${token}`},
        });
        setCdos(response.data);
        setPecLoading(false)
      } catch (error) {
        if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          window.location.reload();
        }
        setPecLoading(false)
      }
    };
    getCdos();
  }, [token]);

  const {data: partnersData, isLoading: partnersLoading} = useGetAllPartnersQuery()

  useEffect(() => {
    if (partnersLoading) {
      setPartners([])
    }
    if (!partnersLoading && partnersData) {
      setPartners(partnersData?.data)
    }
  }, [partnersLoading, partnersData])




  useEffect(() => {
    if (!rolesLoading && rolesData) {
      setRole(rolesData?.data)
      setValue('role_id', user?.user?.role_id[0])
    }
    if (!pecLoading) {
      setValue('pec_id', user?.user?.pec?.id)
    }
  }, [rolesData, rolesLoading, pecLoading, setValue, user, setOpen])

  const filteredRolesForPec = role.filter(item => item.name === 'Учитель')
  const filteredRolesForRepublicPec = role.filter(item => item.name === 'Учитель' || item.name === 'ЦДО')
  const filteredPecsForPec = cdos.filter(item => item.name === pecName)

  const filteredPartners = partners.filter(item => item.partner.name === partnerName)
  const filteredRolesForPartner = role.filter(item => item.name === 'Партнёр' || item.name === 'Ментор')


  const onSubmit = (data) => {
    const result = {role: data.role_id, address: data.address, full_name: data.full_name}
    if (dataData?.user?.phone?.length === 0) {
      result.phone = data.phone
    }
    if (dataData?.user?.phone !== data.phone) {
      result.phone = data.phone
    }
    if (data.pec_id) {
      result.pec_id = data.pec_id
    }
    if (data.partner_id) {
      result.partner_id = data.partner_id
    }
    if (data.password && data.password_confirmation) {
      result.password = data.password
      result.password_confirmation = data.password_confirmation
    }
    if (user?.user?.id) {

      handleSaveBtn(result, user?.user?.id);
    } else {
      handleSaveBtn(result);
    }
  };

  const handlePwdEyeClick = () => {
    if (pwdType === "password") {
      setPwdType("text");
    } else {
      setPwdType("password");
    }
  };
  const handleRepeatPwdEyeClick = () => {
    if (confirmPwdType === "password") {
      setConfirmPwdType("text");
    } else {
      setConfirmPwdType("password");
    }
  };

  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  return (
    <>
      <div
        className={rolesLoading && pecLoading ? "opacity-50 bg-white px-4 pt-5 pb-4 sm:pb-6" : "opacity-100 bg-white px-4 pt-5 pb-4 sm:pb-6"}>
        <div className="sm:flex sm:items-start sm:flex-col">
          <h2 className="text-primary font-medium text-lg">
            {dataData?.user?.id ? "Редактирование" : "Добавление"} сотрудника
          </h2>
          <p className="text-[#6B7280] text-sm mt-1">
            Для {dataData?.user?.id ? "редактирования" : "добавления"} сотрудника заполните
            формы
          </p>
          <form
            className="flex flex-col w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-6 w-full mt-6 mb-2">
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="full_name"
                  >
                    ФИО
                  </label>
                  <input
                    className={errors.full_name ? errInputText : inputTextStyle}
                    type="text"
                    id="full_name"
                    defaultValue={user?.user?.full_name}
                    {...register("full_name")}
                    onKeyDown={(e) => {
                      if (e.key >= 0 && e.key <= 9 && e.key !== " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.full_name && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.full_name.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="phone"
                  >
                    Номер телефона
                  </label>
                  <div className="flex rounded-md">
                    <span
                      className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-sm">
                      +992
                    </span>
                    <input
                      className={
                        errors.phone || errMsg
                          ? `${errInputText} rounded-l-none`
                          : `${inputTextStyle} rounded-l-none`
                      }
                      type="text"
                      id="phone"
                      defaultValue={user?.user?.phone}
                      {...register("phone")}
                      onKeyDown={() => setErrMsg("")}
                    />
                  </div>
                  {errors.phone && !errMsg && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.phone.message}
                    </p>
                  )}
                  {errMsg && !errors.phone && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errMsg}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="pec_id"
                  >
                    Организация
                  </label>
                  {name === 'ЦДО' ?
                    <select
                      id="pec_id"
                      className={errors.pec_id ? errInputText : inputTextStyle}
                      {...register("pec_id")}
                    >
                      {filteredPecsForPec
                        .map((cdo, index) => {
                          return (
                            <option key={index} value={cdo.id}>
                              {cdo.name}
                            </option>
                          );
                        })}
                    </select> :
                    name === 'Республиканский ЦДО' ?
                      <select
                        id="pec_id"
                        className={errors.pec_id ? errInputText : inputTextStyle}
                        {...register("pec_id")}
                      >
                        {cdos
                          .map((cdo, index) => {
                            return (
                              <option key={index} value={cdo.id}>
                                {cdo.name}
                              </option>
                            );
                          })}
                      </select> :
                      name === 'Партнёр' ?
                        <select
                          className={errors.partner_id ? errInputText : inputTextStyle}
                          {...register("partner_id")}
                        >
                          {filteredPartners
                            .map((item, index) => {
                              return (
                                <option key={index} value={item?.partner?.id}>
                                  {item?.partner?.name}
                                </option>
                              );
                            })}
                        </select> :
                        <select
                          className={errors.partner_id ? errInputText : inputTextStyle}
                          {...register("partner_id")}
                        >
                          {partners
                            .map((item, index) => {
                              return (
                                <option key={index} value={item?.partner?.id}>
                                  {item?.partner?.name}
                                </option>
                              );
                            })}
                        </select>
                  }
                  {errors.partner_id && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.partner_id.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="role_id"
                  >
                    Роль
                  </label>
                  {name === 'Партнёр' ?
                    <select
                      id="role_id"
                      className={errors.role_id ? errInputText : inputTextStyle}
                      {...register("role_id")}
                      defaultValue={dataData?.user?.role[0]}
                    >
                      {filteredRolesForPartner.map((item, index) => <option key={index}
                                                                            value={item.id}>{item.name}</option>)}
                    </select> :
                    name === 'ЦДО' ?
                      <select
                        id="role_id"
                        className={errors.role_id ? errInputText : inputTextStyle}
                        {...register("role_id")}
                        defaultValue={dataData?.user?.role[0]}
                      >
                        {filteredRolesForPec.map((item, index) => <option key={index}
                                                                          value={item.id}>{item.name}</option>)}
                      </select> :
                      name === 'Республиканский ЦДО' ?
                        <select
                          id="role_id"
                          className={errors.role_id ? errInputText : inputTextStyle}
                          {...register("role_id")}
                          defaultValue={dataData?.user?.role[0]}
                        >
                          {filteredRolesForRepublicPec.map((item, index) => <option key={index}
                                                                                    value={item.id}>{item.name}</option>)}
                        </select>
                        :
                        <select
                          id="role_id"
                          className={errors.role_id ? errInputText : inputTextStyle}
                          {...register("role_id")}
                          defaultValue={dataData?.user?.role[0]}
                        >
                          {role.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                        </select>}
                  {errors.role_id && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.role_id.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="password"
                  >
                    Пароль
                  </label>
                  <input
                    type={pwdType}
                    id="password"
                    {...register("password")}
                    className={errors.password ? errInputText : inputTextStyle}
                  />
                  <button
                    type="button"
                    className="absolute top-[33px] right-[13px]"
                    onClick={handlePwdEyeClick}
                  >
                    {pwdType === "password" ? <EyeIcon/> : <CrossedEyeIcon/>}
                  </button>
                  {errors.password && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="password_confirmation"
                  >
                    Повторите пароль
                  </label>
                  <input
                    type={confirmPwdType}
                    id="password_confirmation"
                    className={
                      errors.password_confirmation
                        ? errInputText
                        : inputTextStyle
                    }
                    {...register("password_confirmation")}
                  />
                  <button
                    type="button"
                    className="absolute top-[33px] right-[13px]"
                    onClick={handleRepeatPwdEyeClick}
                  >
                    {confirmPwdType === "password" ? (
                      <EyeIcon/>
                    ) : (
                      <CrossedEyeIcon/>
                    )}
                  </button>
                  {errors.password_confirmation && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.password_confirmation.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-1 relative">
                <label
                  className="block text-sm font-medium text-[#374151]"
                  htmlFor="address"
                >
                  Адрес
                </label>
                <input
                  type="text"
                  id="address"
                  defaultValue={user?.user?.address}
                  className={errors.address ? errInputText : inputTextStyle}
                  {...register("address")}
                />
                {errors.address && (
                  <p className="text-xs text-red-600 absolute bottom-[-22px]">
                    {errors.address.message}
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="submit"
          className={loading ? "opacity-50 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30" : "opacity-100 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"}
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          Сохранить
        </button>
        <button
          onClick={() => setOpen(false)}
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-[#fff] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Отмена
        </button>
      </div>
    </>
  );
};

export default StaffForm;
