import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {locations} from "../../hardCodedArrays/Arrays";

const MemberForm = ({ setOpen, handleSaveBtn, datadata, errMsg, setErrMsg , readonly, loading, numberError}) => {

  const newData = datadata

  const grades = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];

  const onSubmit = (data) => {
    if (newData?.member?.id) {
      const id = newData?.member?.id
      handleSaveBtn(data, id);
    } else {
      handleSaveBtn(data);
    }
  };

  const schema = yup.object().shape({
    full_name: yup.string().required("Поле не должно быть пустым"),
    phone: yup
      .string()
      .required("Поле не должно быть пустым")
      .matches(/^[0-9]+$/, "Необходимо ввести только цифры")
      .min(9, "Необходимо ввести 9 символов")
      .max(9, "Необходимо ввести 9 символов"),
    year_of_birth: yup
      .string()
      .required("Поле не должно быть пустым")
      .matches(/^[0-9]+$/, "Необходимо ввести только цифры")
      .min(4, "Необходимо ввести 4 символа")
      .max(4, "Необходимо ввести 4 символа"),
    sex: yup.string().required("Поле не должно быть пустым"),
    school: yup.string().required("Поле не должно быть пустым"),
    grade: yup.string().required("Поле не должно быть пустым"),
    city_id: yup.string().required("Поле не должно быть пустым"),
    address: yup.string().required("Поле не должно быть пустым"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  return (
    <>
      <div className="bg-white px-4 pt-5 pb-4 sm:pb-6">
        <div className="sm:flex sm:items-start sm:flex-col">
          {readonly ? <h2 className="text-primary font-medium text-lg">Профиль участника</h2> :
            <h2 className="text-primary font-medium text-lg">
            {newData?.member?.id ? "Редактирование" : "Добавление"} участника
          </h2>}
          {!readonly && <p className="text-[#6B7280] text-sm mt-1">
            Для {newData?.member?.id ? "редактирования" : "добавления"} участника заполните
            формы
          </p>}
          <form
            className="flex flex-col w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-6 w-full mt-6 mb-2">
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="full_name"
                  >
                    ФИО
                  </label>
                  <input
                    disabled={readonly}
                    className={errors.full_name ? errInputText : inputTextStyle}
                    type="text"
                    id="full_name"
                    defaultValue={newData?.member?.full_name}
                    {...register("full_name")}
                    onKeyDown={(e) => {
                      if (e.key >= 0 && e.key <= 9 && e.key !== " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.full_name && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.full_name.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="phone"
                  >
                    Номер телефона
                  </label>
                  <div className="flex rounded-md">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-sm">
                      +992
                    </span>
                    <input
                      disabled={readonly}

                      className={
                        errors.phone
                          ? `${errInputText} rounded-l-none`
                          : `${inputTextStyle} rounded-l-none`
                      }
                      type="text"
                      id="phone"
                      defaultValue={newData?.member?.phone}
                      {...register("phone")}
                      onKeyDown={() => setErrMsg("")}
                    />
                  </div>
                  {errors.phone && !numberError && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.phone.message}
                    </p>
                  )}
                  {numberError && !errors.phone && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      Номер уже существует
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="year_of_birth"
                  >
                    Год рождения
                  </label>
                  <input
                    disabled={readonly}

                    className={
                      errors.year_of_birth ? errInputText : inputTextStyle
                    }
                    type="text"
                    id="year_of_birth"
                    defaultValue={newData?.member?.year_of_birth}
                    {...register("year_of_birth")}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.year_of_birth && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.year_of_birth.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="sex"
                  >
                    Пол
                  </label>
                  <select
                    disabled={readonly}

                    id="sex"
                    className={errors.sex ? errInputText : inputTextStyle}
                    {...register("sex")}
                    defaultValue={newData?.member?.sex}
                  >
                    <option value={newData?.member?.sex} hidden>{newData?.member?.sex}</option>
                    {newData?.member?.sex === "М" ? (
                      <option value={"Ж"}>Ж</option>
                    ) : newData?.member?.sex === "Ж" ? (
                      <option value={"М"}>М</option>
                    ) : (
                      <>
                        <option value={"М"}>М</option>
                        <option value={"Ж"}>Ж</option>
                      </>
                    )}
                  </select>
                  {errors.sex && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.sex.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="school"
                  >
                    Школа
                  </label>
                  <input
                    disabled={readonly}

                    className={errors.school ? errInputText : inputTextStyle}
                    type="text"
                    id="school"
                    defaultValue={newData?.member?.school}
                    {...register("school")}
                  />
                  {errors.school && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.school.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="grade"
                  >
                    Класс
                  </label>
                  <select
                    disabled={readonly}

                    id="grade"
                    className={errors.grade ? errInputText : inputTextStyle}
                    {...register("grade")}
                    defaultValue={newData?.member?.grade}
                  >
                    <option value={newData?.member?.grade}>{newData?.member?.grade}</option>
                    {grades
                      .map((gradeNum) => {
                        return (
                          <option key={gradeNum} value={gradeNum}>
                            {gradeNum}
                          </option>
                        );
                      })}
                  </select>
                  {errors.grade && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.grade.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="location"
                  >
                    Регион
                  </label>
                  <select
                    disabled={readonly}

                    className={errors.city_id ? errInputText : inputTextStyle}
                    {...register("city_id")}
                    defaultValue={newData?.member?.city?.id}
                  >
                    {locations
                      .map((locationName, index) => {
                        return (
                          <option key={index} value={locationName.id}>
                            {locationName.name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.city_id && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.city_id.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-[50%] relative">
                  <label
                    className="block text-sm font-medium text-[#374151]"
                    htmlFor="address"
                  >
                    Адрес
                  </label>
                  <input
                    disabled={readonly}

                    className={errors.address ? errInputText : inputTextStyle}
                    type="text"
                    id="address"
                    defaultValue={newData?.member?.address}
                    {...register("address")}
                  />
                  {errors.address && (
                    <p className="text-xs text-red-600 absolute bottom-[-22px]">
                      {errors.address.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {!readonly && <div className="bg-back px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="submit"
          disabled={loading}
          className={loading ? "opacity-50 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30" :"opacity-100 inline-flex w-full justify-center rounded-md bg-primary px-8 py-[9px] text-sm font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto disabled:opacity-30"}
          onClick={handleSubmit(onSubmit)}
        >
          Сохранить
        </button>
        <button
          onClick={() => setOpen(false)}
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-[#fff] px-11 py-[9px] text-sm font-medium text-[#374151] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Отмена
        </button>
      </div>}
    </>
  );
};

export default MemberForm;
