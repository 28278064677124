import { ReactComponent as Steps } from "../../../assets/img/thirdSteps.svg";
import { Transition } from "@headlessui/react";

const ProjectFormThirdStep = ({ register, data, errors }) => {


  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  const { description_of_problem, solution, innovation } = data;

  return (
    <Transition
      appear={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex flex-col mt-10 gap-6">
        <div className="flex flex-col w-full gap-1 relative">
          <label
            htmlFor="description_of_problem"
            className="text-[#374151] text-sm font-medium"
          >
            Описание проблемы
          </label>
          <textarea
            rows="2"
            name="description_of_problem"
            id="description_of_problem"
            defaultValue={description_of_problem}
            {...register("description_of_problem")}
            className={
              errors.description_of_problem ? errInputText : inputTextStyle
            }
          />
          {errors.description_of_problem && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.description_of_problem.message}
            </p>
          )}
        </div>
        <div className="flex flex-col w-full gap-1 relative">
          <label
            htmlFor="solution"
            className="text-[#374151] text-sm font-medium"
          >
            Решения
          </label>
          <textarea
            rows="2"
            name="solution"
            id="solution"
            defaultValue={solution}
            {...register("solution")}
            className={errors.solution ? errInputText : inputTextStyle}
          />
          {errors.solution && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.solution.message}
            </p>
          )}
        </div>
        <div className="flex flex-col w-full gap-1 relative">
          <label
            htmlFor="innovation"
            className="text-[#374151] text-sm font-medium"
          >
            Инновация
          </label>
          <textarea
            rows="2"
            name="innovation"
            id="innovation"
            defaultValue={innovation}
            {...register("innovation")}
            className={errors.innovation ? errInputText : inputTextStyle}
          />
          {errors.innovation && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.innovation.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-[42px]">
        <Steps />
      </div>
    </Transition>
  );
};

export default ProjectFormThirdStep;
