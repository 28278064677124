import {ReactComponent as SortIcon} from "../../../assets/icons/sort-icon.svg";
import {NewCourseTable} from "../../../pages/courses/components/NewCourseTable";

const CoursesTable = ({courses, roles}) => {


  return (
    <div className="flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="flex items-center py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                >
                  <span>НАЗВАНИЕ</span>
                  <button className="ml-4">
                    <SortIcon/>
                  </button>
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  УЧИТЕЛЬ
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  ПРЕДМЕТ
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  УЧАСТНИКОВ
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  ДАТА НАЧАЛА
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  ДАТА ЗАВЕРШЕНИЯ
                </th>
                <th
                  scope="col"
                  className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                >
                  СТАТУС
                </th>
                <th
                  scope="col"
                  className="relative py-4 pl-3 pr-4 sm:pr-6 text-center"
                >
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {courses.map((course, index) => (
                <NewCourseTable roles={roles} key={index} {...course}/>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesTable;
