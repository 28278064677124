import {Transition} from "@headlessui/react";
import { ReactComponent as Steps } from "../../../../assets/img/thirdSteps.svg";
import React from "react";
import {ProjTextArea} from "../inputs/ProjTextArea";

export const ThirdStep = ({setPage, innovation, solution, description_of_problem}) => {

  return (
    <Transition
      appear={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className='px-4 pt-5 pb-4 sm:pb-6'>
        <div className="flex flex-col mt-10 gap-6">
          <div className="flex flex-col w-full gap-1 relative">
            <label
              htmlFor="description_of_problem"
              className="text-[#374151] text-sm font-medium"
            >
              Описание проблемы
            </label>
            <ProjTextArea name='description_of_problem' required_message='Заполните это поле'/>
          </div>
          <div className="flex flex-col w-full gap-1 relative">
            <label
              htmlFor="solution"
              className="text-[#374151] text-sm font-medium"
            >
              Решения
            </label>
            <ProjTextArea name='solution' required_message='Заполните это поле'/>
          </div>
          <div className="flex flex-col w-full gap-1 relative">
            <label
              htmlFor="innovation"
              className="text-[#374151] text-sm font-medium"
            >
              Инновация
            </label>
            <ProjTextArea name='innovation' required_message='Заполните это поле'/>
          </div>
        </div>
        <div className="flex items-center justify-center w-full mt-[42px]">
          <Steps/>
        </div>
      </div>
      <div className='flex p-6 justify-end bg-[#FBF9FB] max-w-full w-full mx-auto gap-4'>
        <button type='button' onClick={() => setPage(2)} className='px-8 py-3 rounded-md bg-white border border-[#D1D5DB] hover:bg-gray-50'>Назад</button>
        <button type='button' onClick={() => setPage(4)} className='px-8 py-3 rounded-md bg-[#39044E] text-white hover:bg-secondary'>Далее</button>
      </div>
    </Transition>
  )
}