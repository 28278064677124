import {useEffect, useState} from "react";
import Pagination from "../../components/pagination/Pagination";
import { ReactComponent as MagnifyingGlassIcon } from "../../assets/icons/search-icon.svg";
import RolesTable from "../../components/tables/rolesTable/RolesTable";
import Modal from "../../components/modals/modal/Modal";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-icon.svg";
import {useGetRolesQuery, useGetSearchRolesQuery} from "../../redux/api/roleApi";

const Roles = () => {
  const addRole = (data) => {console.log(data)};
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [searchRoles, setSearchRoles] = useState([])

  const {data: rolesData, isLoading} = useGetRolesQuery({page})
  const {data: searchRolesData, isLoading: searchLoading} = useGetSearchRolesQuery({search})

  useEffect(() => {
    if(!isLoading && rolesData) {
      setRoles(rolesData?.data)
    }
    if(!searchLoading && searchRolesData) {
      setSearchRoles(searchRolesData?.data)
    }
  },[isLoading, rolesData, searchLoading, searchRolesData])


  const handleAddBtn = () => {
    setOpen(true);
  };

  const handleEditBtn = () => {
    setOpen(true);
  };

  return (
    <>
      <h1 className="font-semibold text-primary text-2xl">Роли</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4">
        <div className="relative w-[90%]">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            id="search-field"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="block h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm focus:outline-none rounded-md"
            placeholder="По названию"
            type="search"
            name="search"
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <button
          onClick={handleAddBtn}
          className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon />
          <span className="text-white text-base font-medium">Добавить</span>
        </button>
        <Modal
          handleSaveBtn={addRole}
          formName="roles"
          open={open}
          setOpen={setOpen}
        />
      </div>
      {search.length > 3 ? <RolesTable roles={searchRoles} handleEditBtn={handleEditBtn} /> :
      <RolesTable roles={roles} handleEditBtn={handleEditBtn} />}
       <Pagination
        totalPage={rolesData?.meta?.total / 8}
        setCurrentPage={setPage}
        totalObjects={rolesData?.meta?.total}
        to={rolesData?.meta?.to}
        from={rolesData?.meta?.from}
      />
    </>
  );
};

export default Roles;
