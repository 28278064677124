import {Fragment, useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-icon.svg";

export default function DeleteModal({ title, id, handleDelete }) {
  const [open, setOpen] = useState(false);

  const onDelete = (id) => {
    handleDelete(id);
    setOpen(false);
  };

  return (
    <>
      <button onClick={() => setOpen(true)}>
        <TrashIcon />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed z-40 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-[344px]">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-[#111827]"
                      >
                        Удаление {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-[#6B7280]">
                          Вы уверены, что хотите удалить <span>{title}</span>?
                          Вся информацию будет полностью удалена с серверов. Это
                          действие невозможно вернуть.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3 mt-5">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-[9px] font-medium text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                      onClick={() => onDelete(id)}
                    >
                      Да, удалить
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white ring-1 ring-[#D1D5DB] px-3 py-[9px] font-medium text-[#374151] shadow-sm hover:bg-[#D1D5DB] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                      onClick={() => setOpen(false)}
                    >
                      Нет, отменить
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
