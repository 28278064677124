import Modal from "../../../components/modals/modal/Modal";
import {useState} from "react";

export const MemberItem = ({full_name, id, phone, member}) => {
  const [open, setOpen] = useState(false)
  const [errMsg, setErrMsg] = useState("");
  return (
    <li
      key={id}
      className="flex items-center justify-between gap-x-6 py-4"
    >
      <div className="min-w-0">
        <div className="flex items-start">
          <p className="text-sm font-medium text-[#111827]">
            {full_name}
          </p>
        </div>
        <div className="mt-1 text-sm leading-5 text-[#6B7280]">
          <p className="whitespace-nowrap">
            +992 {phone}
          </p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <button onClick={() => setOpen(true)}
          className="hidden rounded-xl bg-white px-2.5 py-0.5 text-sm font-medium text-[#374151] shadow-sm ring-1 ring-inset ring-[#D1D5DB] hover:bg-gray-50 sm:block">
          Просмотр
        </button>
      </div>
      <Modal
        readonly={true}
        formName="members"
        open={open}
        setOpen={setOpen}
        data={member}
        errMsg={errMsg}
        setErrMsg={setErrMsg}
      />
    </li>
  )
}