import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {SkeletonTheme} from "react-loading-skeleton";
import {AuthProvider} from "./context/AuthProvider";
import {Provider} from "react-redux";
import {store} from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <SkeletonTheme highlightColor="#F0EEF7" baseColor="#fff">
        <AuthProvider>
          <App/>
        </AuthProvider>
      </SkeletonTheme>
    </Provider>
  </BrowserRouter>
);
