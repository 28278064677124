import React from 'react';
import {Modal} from "@mui/material";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";

export const SubmitModal = ({open, setOpen, onSubmit}) => {
  return (
    <Modal open={open}>
      <div className='fixed top-[30%] left-[43%]'>
        <div
          className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-[344px]">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center">
              <div
                as="h3"
                className="text-lg leading-6 font-medium text-[#111827]"
              >
                Отправка формы
              </div>
              <div className="mt-2">
                <p className="text-sm text-[#6B7280]">
                  Вы уверены, что хотите отправить <span>форму</span>?
                  Это действие нельзя будет отменить, и после отправки вам будет недоступна функция редактирования.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 mt-5">
            <button
              onClick={onSubmit || (() => {})}
              type="submit"
              className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-[9px] font-medium text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Да, отправить
            </button>
            <button
              onClick={() => setOpen(false)}
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-white ring-1 ring-[#D1D5DB] px-3 py-[9px] font-medium text-[#374151] shadow-sm hover:bg-[#D1D5DB] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Нет, отменить
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

