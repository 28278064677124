import { ReactComponent as Steps } from "../../../assets/img/fourthSteps.svg";
import { Transition } from "@headlessui/react";

const ProjectFormFourthStep = ({ register, data, errors, loading }) => {

  const handleKeyDown = (e) => {
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

    if (!numbers.includes((e.key))) {
      e.preventDefault()
    }
  }

  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";

  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";

  const {
    equipment,
    transport_costs,
    services,
    rent,
    raw_materials,
    other,
    tools,
  } = data;

  return (
    <Transition
      appear={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex flex-col gap-6 mt-6">
        <div className="flex items-center w-full gap-6">
          <div className="flex flex-col gap-1 w-[33%] relative">
            <label
              htmlFor="equipment"
              className="text-[#374151] text-sm font-medium"
            >
              Оборудования (TJS)
            </label>
            <input
              type="number"
              min="0"
              onKeyPress={handleKeyDown}
              defaultValue={equipment}
              {...register("equipment")}
              className={errors.equipment ? errInputText : inputTextStyle}
            />
            {errors.equipment && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.equipment.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1 w-[33%] relative">
            <label
              htmlFor="transport_costs"
              className="text-[#374151] text-sm font-medium"
            >
              Транспортные расходы (TJS)
            </label>
            <input
              type="number"
              min="0"
              onKeyPress={handleKeyDown}
              defaultValue={transport_costs}
              {...register("transport_costs")}
              className={errors.transport_costs ? errInputText : inputTextStyle}
            />
            {errors.transport_costs && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.transport_costs.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1 w-[33%] relative">
            <label
              htmlFor="services"
              className="text-[#374151] text-sm font-medium"
            >
              Услуги (TJS)
            </label>
            <input
              type="number"
              min="0"
              onKeyPress={handleKeyDown}
              defaultValue={services}
              {...register("services")}
              className={errors.services ? errInputText : inputTextStyle}
            />
            {errors.services && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.services.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center w-full gap-6">
          <div className="flex flex-col gap-1 w-[33%] relative">
            <label
              htmlFor="rent"
              className="text-[#374151] text-sm font-medium"
            >
              Аренда (TJS)
            </label>
            <input
              type="number"
              min="0"
              onKeyPress={handleKeyDown}
              defaultValue={rent}
              {...register("rent")}
              className={errors.rent ? errInputText : inputTextStyle}
            />
            {errors.rent && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.rent.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1 w-[33%] relative">
            <label
              htmlFor="raw_materials"
              className="text-[#374151] text-sm font-medium"
            >
              Сырье (TJS)
            </label>
            <input
              type="number"
              min="0"
              onKeyPress={handleKeyDown}
              defaultValue={raw_materials}
              {...register("raw_materials")}
              className={errors.raw_materials ? errInputText : inputTextStyle}
            />
            {errors.raw_materials && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.raw_materials.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1 w-[33%] relative">
            <label
              htmlFor="other"
              className="text-[#374151] text-sm font-medium"
            >
              Прочее (TJS)
            </label>
            <input
              type="number"
              min="0"
              onKeyPress={handleKeyDown}
              defaultValue={other}
              {...register("other")}
              className={errors.other ? errInputText : inputTextStyle}
            />
            {errors.other && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.other.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1 relative">
          <label
            htmlFor="resource"
            className="text-[#374151] text-sm font-medium"
          >
            Ресуры
          </label>
          <textarea
            rows="5"
            defaultValue={tools}
            {...register("tools")}
            className={errors.resource ? errInputText : inputTextStyle}
          />
          {errors.resource && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.resource.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-[42px]">
        <Steps />
      </div>
    </Transition>
  );
};

export default ProjectFormFourthStep;
