import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const coursesApi = createApi({
  reducerPath: 'coursesApi',
  tagTypes: 'Courses',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.upshift.colibri.tj/api/`,
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getCourses: build.query({
      query: (params) => `groups?page=${params.page}`,
      providesTags:(result) =>
        result.data
          ? [
            ...result.data.map(({id}) => ({type: 'Courses', id})),
            {type: 'Courses', id: 'LIST'},
          ]
          : [{type: 'Courses', id: 'LIST'}],
    }),
    getOneCourse: build.query ({
      query: (id) => `groups/${id}`
    }),
    getSearchCourses: build.query ({
      query: (params) => `groups?name=${params.search}`
    }),
    addMemberToCourse: build.mutation ({
      query: ({id, ...body}) => ({
        url: `groups/${id}/members`,
        method: 'POST',
        body
      })
    }),
    addCourse: build.mutation({
      query: body => ({
        url: `groups`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Courses', id: 'LIST'}]
    }),
    editCourse: build.mutation({
      query: ({id, ...body}) => ({
        url: `groups/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{type: 'Courses', id: 'LIST'}]
    })
  })
})

export const {useGetCoursesQuery, useAddCourseMutation, useGetOneCourseQuery, useGetSearchCoursesQuery, useEditCourseMutation, useAddMemberToCourseMutation} = coursesApi;
