import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const partnersApi = createApi({
  reducerPath: 'partnersApi',
  tagTypes: 'Partners',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.upshift.colibri.tj/api',
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getPartners: build.query({
      query: () => `/partners`,
      providesTags:(result) =>
        result.data
          ? [
            ...result.data.map(({id}) => ({type: 'Partners', id})),
            {type: 'Partners', id: 'LIST'},
          ]
          : [{type: 'Partners', id: 'LIST'}],
    }),
    searchPartners: build.query({
      query: (params) => `search/partners?keyword=${params.search}`,
      invalidatesTags: [{type: 'Partners', id: 'LIST'}]
    }),
    getAllPartners: build.query({
      query: () => `get/all/partners`
    }),
    editPartner: build.mutation({
      query: ({id, ...body}) => ({
        url: `partners/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{type: 'Partners', id: 'LIST'}]
    }),
  })
})

export const {useGetPartnersQuery, useEditPartnerMutation, useGetAllPartnersQuery, useSearchPartnersQuery} = partnersApi;
