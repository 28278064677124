import React, {useState} from 'react';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteModal from "../../components/modals/deleteModal/DeleteModal";
import {ReactComponent as EditIcon} from "../../assets/icons/edit-icon.svg";
import {ReportInfoModal} from "./ReportInfoModal";

export const UserRow = ({user, roles, handleEditBtn, deleteUser}) => {
  const [open, setOpen] = useState(false)

  const thisUser = JSON.parse(localStorage.getItem('user'))
  const userRole = thisUser?.role[0]
  return (
    <>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
          {user?.user?.full_name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
          {user?.user?.pec?.name || user?.user?.partner?.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
          +992 {user?.user?.phone}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm ml-3 text-center">
          <span className="text-[#6B7280]">{user?.user?.role[0]}</span>
        </td>
        <td
          className="flex justify-end items-center gap-4 relative whitespace-nowrap  py-4 pl-3 pr-4 text-sm sm:pr-6">
          {userRole === 'Администратор' || userRole === 'Республиканский ЦДО' ?
            <div className='w-[20px] h-[20px] flex items-center justify-center'
                 onClick={() => setOpen(true)}>
              <InfoOutlinedIcon sx={{color: '#39044E'}}/>
            </div> : null}
          {roles.includes('update-users') &&
            <button
              onClick={() => handleEditBtn(user)}
              className="text-primary hover:text-secondary"
            >
              <EditIcon/>
            </button>}
          {roles.includes('delete-users') &&
            <DeleteModal
              title="сотрудника"
              handleDelete={deleteUser}
              id={user?.user?.id}
            />}
        </td>

      </tr>
        <ReportInfoModal open={open} setOpen={setOpen} userId={user?.user?.id}/>
    </>
  );
};

