import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const staffApi = createApi({
  reducerPath: 'staffApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.upshift.colibri.tj/api/',
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    searchStaff: build.query({
      query: (params) => `search/users?keyword=${params.search}`
    }),
    getAllMentors: build.query({
      query: () => `get/all/mentors`
    }),
    getAllTeachers: build.query({
      query: () => `get/all/teachers`
    }),
    getStaffForPecs: build.query({
      query: (params) => ``
    }),
  })
})

export const {useSearchStaffQuery, useGetAllMentorsQuery, useGetAllTeachersQuery} = staffApi;
