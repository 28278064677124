import {useFormContext} from "react-hook-form";

export const TextInput = ({name, pattern_value, required_message, defaultValue}) => {
  const {register, formState: {errors}} = useFormContext()
  return (
    <>
      <input
        type="text"
        defaultValue={defaultValue}
        {...register(name, {
          required: `${required_message}`,
          pattern: {
            value: pattern_value,
            message: 'Введите корректное значение'
          },
        })}
        className='border rounded-md border-[#D1D5DB] max-w-full w-full flex-auto'/>
      {errors[name] && <p className='font-medium text-red-700'>{errors[name]?.message}</p>}
    </>
  )
}