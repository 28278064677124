import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const modiApi=createApi({
    reducerPath: 'modiApi',
    tagTypes: 'Modi',
    baseQuery: fetchBaseQuery({
        baseUrl: `https://api.upshift.colibri.tj/api/`,
        prepareHeaders: (headers ) => {
            const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
        }
    }),
    endpoints: build => ({
        getModi: build.query({
            query:()=> `report/table4`
        }),

        postModi: build.mutation ({
            query: (data) => ({
                url: `report/table4`,
                method: "POST",
                body: data

            })
        })

    })

})

export const { useGetModiQuery, usePostModiMutation } = modiApi
