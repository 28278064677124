import { ReactComponent as SortIcon } from "../../../assets/icons/sort-icon.svg";
import {PartnersNewTable} from "../../../pages/partners/components/PartnersNewTable";

const PartnersTable = ({ partners, handleEditBtn, roles }) => {


  return (
    <div className="flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-t-md">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="flex items-center py-4 pl-4 pr-3 text-left text-xs font-medium tracking-wider text-[#6B7280] sm:pl-6"
                  >
                    <span>НАЗВАНИЕ</span>
                    <button className="ml-4">
                      <SortIcon />
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    АДРЕС
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    НОМЕР ТЕЛЕФОНА
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  >
                    СТАТУС
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 text-center text-xs font-medium tracking-wider text-[#6B7280]"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {partners.map((partner) => (
                  <PartnersNewTable roles={roles} key={partner.partner.id} {...partner.partner} partner={partner.partner}/>
                  // <tr key={partner.partner.id}>
                  //   <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
                  //     {partner.partner.name}
                  //   </td>
                  //   <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                  //     {partner.partner.address}
                  //   </td>
                  //   <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
                  //     +992 {partner.partner.phone}
                  //   </td>
                  //   <td className="whitespace-nowrap px-3 py-4 text-xs text-center">
                  //     <span
                  //       className={
                  //         partner.partner.status === "active"
                  //           ? activeStatusStyle
                  //           : inactiveStatusStyle
                  //       }
                  //     >
                  //       {partner.partner.status === "active"
                  //         ? "Активный"
                  //         : "Неактивный"}
                  //     </span>
                  //   </td>
                  //   <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
                  //     <button
                  //       onClick={() => handleEditBtn(partner)}
                  //       className="text-primary hover:text-secondary"
                  //     >
                  //       <EditIcon />
                  //       <span className="sr-only">, {partner.name}</span>
                  //     </button>
                  //   </td>
                  // </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PartnersTable;
