import ReactDOM from "react-dom";
import {FormProvider, useForm} from "react-hook-form";
import {TextInput} from "../../../components/inputs/TextInput";
import {SelectInput} from "../../../components/inputs/SelectInput";
import {useGetDirectionsQuery} from "../../../redux/api/directionsApi";
import {useEditSubjectMutation} from "../../../redux/api/subjectsApi";

const portal = document.getElementById('portal')
export const EditModal = ({showEdit, setShowEdit, title, direction_id, refetch, id}) => {
  const methods = useForm()
  const page = 1

  const {data: directions, isLoading} = useGetDirectionsQuery({page})

  const [editSubject] = useEditSubjectMutation()

  const onSubmit = async (data) => {
    try {
      await editSubject({id: id, ...data}).unwrap()
      setShowEdit(false)
      methods.reset()
      refetch()
    } catch (err) {
      alert('Ошибка при изменении предмета')
    }
  }

  const onCancel = () => {
    setShowEdit(false)
    methods.reset()
  }

  if(isLoading){
    return <p>Загрузка...</p>
  }
  const{data:directionsData} = directions

  return ReactDOM.createPortal(
    <div className={showEdit ? 'modal active' : 'modal'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className='max-w-[800px] w-full mx-auto bg-white rounded-md'>
          <div className='pt-6 pr-6 pl-6'>
            <h3 className='font-medium text-[18px] '>Редактирование Предмета</h3>
            <p className='text-sm text-[#6B7280]'>Для редактирования предмета заполните форму</p>
            <div className='flex gap-6 items-center justify-center mt-6'>
              <div className='flex-auto'>
                <h3>Название</h3>
                <TextInput defaultValue={title} name='name' required_message='Заполните это поле' pattern_value={/^(?!.* {2})[a-zA-Zа-яА-Я0-9 ]*$/i}/>
              </div>
              <div className='flex-auto'>
                <h3>Направление</h3>
                <SelectInput name='direction_id' defaultValue={direction_id} options={directionsData} required_message='Выберите направление'/>
              </div>
            </div>
          </div>
          <div className='flex justify-end items-center gap-6 bg-[#FBF9FB] mt-4 max-w-full w-full p-4 rounded-b-md'>
            <button type='button' onClick={onCancel}
                    className='max-w-[140px] border border-[#D1D5DB] text-center bg-white px-8 py-2 rounded-md hover:bg-gray-50 transition-colors'>Отмена
            </button>
            <button type='submit'
                    className='max-w-[140px] text-center text-white bg-[#39044E] px-8 py-2 rounded-md hover:bg-secondary transition-colors'>Сохранить
            </button>
          </div>
        </form>
      </FormProvider>
    </div>, portal
  )
}