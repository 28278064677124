import {ChevronRightIcon, HomeIcon} from "@heroicons/react/20/solid";
import {ReactComponent as TeamAvatar} from "../../assets/img/big-team-avatar.svg";
import {ReactComponent as CrossIcon} from "../../assets/icons/cross-icon.svg";
import {ReactComponent as PaperClipIcon} from "../../assets/icons/paper-clip-icon.svg";
import {useNavigate, Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import axiosInstance from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import html2pdf from "html2pdf.js";
import {MemberItem} from "./components/MemberItem";
import {EditModal} from "../projects/components/editModal/EditModal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const pages = [
  {name: "Проекты", href: "/projects", current: false},
  {name: "Blend", href: "/", current: true},
];


const ProjectInfo = () => {
  const {token} = useContext(AuthContext);
  const [showEdit, setShowEdit] = useState(false)

  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(true)
  const [roles, setRoles] = useState([])
  const [loader, setLoader] = useState(false)

  const {id} = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
  }, [])

  const getInfo = async () => {
    try {
      setLoading(true)
      await axiosInstance.get(`projects/${id}`,
        {headers: {Authorization: `Bearer ${token}`}}
      ).then(res => setInfo(res.data.data))
      setLoading(false)

    } catch (err) {
      alert('Ошибка при загрузке данных')
    }
  }


  useEffect(() => {
    getInfo()
  }, [id])


  const dateObj = new Date(info.project?.start);

  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();

  const formattedStartDate = `${day}-${month}-${year}`;

  const dateEndObj = new Date(info.project?.end);

  const endDay = dateEndObj.getDate().toString().padStart(2, "0");
  const endMonth = (dateEndObj.getMonth() + 1).toString().padStart(2, "0");
  const endYear = dateEndObj.getFullYear().toString();

  const formattedEndDate = `${endDay}-${endMonth}-${endYear}`;

  const sum = Number(info.project?.equipment) + Number(info.project?.other) + Number(info.project?.raw_materials) + Number(info.project?.rent) + Number(info.project?.services) + Number(info.project?.transport_costs)

  const stats = [
    {name: "Оборудования (TJS)", stat: Number(info.project?.equipment)},
    {name: "Транспортные расходы (TJS)", stat: Number(info.project?.transport_costs), percent: ''},
    {name: "Услуги (TJS)", stat: Number(info.project?.services), percent: ''},
    {name: "Аренда (TJS)", stat: Number(info.project?.rent), percent: ''},
    {name: "Сырье (TJS)", stat: Number(info.project?.raw_materials), percent: ''},
    {name: "Прочее (TJS)", stat: Number(info.project?.other), percent: ''},
  ];


  const handleCloseBtn = () => {
    navigate(-1);
  };

  const documentName = info?.project?.documents?.slice(18, info?.project?.documents?.length)


  const handleConvertToPdf = () => {
    const element = document.getElementById('pdf-content')
    if (element) {
      html2pdf().from(element).save('exported.pdf')
    }
  }

  const converter = () => {
    setLoader(true)
    const capture = document.querySelector('.pdf-content')
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img/png')
      const doc = new jsPDF('p', 'mm', 'a2')
      const componentWidth = doc.internal.pageSize.getWidth()
      const componentHeight = doc.internal.pageSize.getHeight()
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight)
      setLoader(false)
      doc.save('project.pdf')
    })
  }

  return (
    <div className='pdf-content'>
      <header className="bg-white border-b-2">
        <div className="mx-auto flex items-center justify-end p-6 lg:p-4">
          <button onClick={handleCloseBtn}>
            <CrossIcon/>
          </button>
        </div>
      </header>
      <div className="mx-auto max-w-7xl lg:px-4 p-6 pt-8 pb-[178px]">
        <nav className="flex justify-between" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
          <div className="flex items-start gap-4">
            <button disabled={loading || loader} onClick={converter}
                    className={loading || loader ? "opacity-50 rounded-md bg-white min-w-[166px] px-7 py-2.5 text-sm leading-4 font-medium text-[#374151] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" : "opacity-100 rounded-md bg-white min-w-[166px] px-7 py-2.5 text-sm leading-4 font-medium text-[#374151] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"}>
              EXPORT TO PDF
            </button>
            {roles.some(item => item === 'update-project') &&
              <button disabled={loading} onClick={() => setShowEdit(true)}
                      className={loading ? "opacity-50 rounded-md bg-primary min-w-[166px] px-7 py-2.5 text-sm leading-4 font-medium text-white shadow-sm ring-1 ring-inset ring-primary hover:bg-secondary" : "opacity-100 rounded-md bg-primary min-w-[166px] px-7 py-2.5 text-sm leading-4 font-medium text-white shadow-sm ring-1 ring-inset ring-primary hover:bg-secondary"}>
                Изменить
              </button>}
          </div>
        </nav>

        <div className="flex flex-col mt-6 border-b-2 pb-10">
          <div className="flex gap-8">
            <div className="w-[25%] xl:w-[20%]">
              {info?.project?.media_file ? <img className='max-w-[228px] w-full h-auto'
                                                src={`https://api.upshift.colibri.tj/public/storage/${info.project?.media_file}`}
                                                alt="avatar"/> : <TeamAvatar/>}
            </div>
            <div className="w-[80%] flex flex-col">
              <h1 className="mb-4 font-extrabold text-4xl text-primary">
                {info.project?.name}
              </h1>
              <h2 className="text-[#6B7280] text-xl">
                Руководитель проекта: {info?.project?.mentor?.name}. Местоположение: {info.project?.location}
              </h2>
              <div className="flex flex-col mt-4 gap-3">
                <div className="flex items-start w-full">
                  <div className="flex flex-col w-[33%] gap-2">
                    <h3 className="text-[#6B7280] text-sm">
                      Количество участников
                    </h3>
                    <span className="text-primary font-semibold text-xl">
                      {info.project?.members.length}
                    </span>
                  </div>
                  <div className="flex flex-col w-[33%] gap-2">
                    <h3 className="text-[#6B7280] text-sm">Дата начала</h3>
                    <span className="text-primary font-semibold text-xl">
                      {formattedStartDate}
                    </span>
                  </div>
                  <div className="flex flex-col w-[33%] gap-2">
                    <h3 className="text-[#6B7280] text-sm">Партнер</h3>
                    <span className="text-primary font-semibold text-xl">
                      {info?.project?.partner?.name}
                    </span>
                  </div>
                </div>
                <div className="flex items-start w-full">
                  <div className="flex flex-col w-[33%] gap-2">
                    <h3 className="text-[#6B7280] text-sm">Бюджет (TJS)</h3>
                    <span className="text-primary font-semibold text-xl">
                      {sum}
                    </span>
                  </div>
                  <div className="flex flex-col w-[33%] gap-2">
                    <h3 className="text-[#6B7280] text-sm">Дата завершения</h3>
                    <span className="text-primary font-semibold text-xl">
                      {formattedEndDate}
                    </span>
                  </div>
                  <div className="flex flex-col w-[33%] gap-2">
                    <h3 className="text-[#6B7280] text-sm">Статус</h3>
                    <span className="text-primary font-semibold text-xl">
                      {info.project?.status}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <dl className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.name}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-base text-primary">{item.name}</dt>
                <dd className="mt-1">
                  <span className="mr-2 text-2xl font-semibold tracking-tight text-primary">
                    {item.stat}
                  </span>
                  <span className="text-[#6B7280] font-medium text-sm">
                    {Math.floor((item.stat / sum) * 100)}% от общей суммы
                  </span>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="mt-[42px]">
          <h2 className="text-primary font-semibold text-xl">Участники</h2>
          <ul className="divide-y divide-gray-100">
            {info?.project?.members?.map((member) => (
              <MemberItem {...member.member} member={member}/>
            ))}
          </ul>
        </div>

        <div className="flex flex-col w-full mt-[42px] gap-3">
          <h3 className="text-primary font-semibold text-xl">
            Предпоссылки проблемы
          </h3>
          <p className="text-[#6B7280]">
            {info.project?.background_of_problem}
          </p>
        </div>

        <div className="flex w-full mt-[47px] gap-[42px]">
          <div className="flex flex-col w-[50%] gap-[42px]">
            <div className="flex flex-col gap-3">
              <h3 className="text-primary font-semibold text-xl">
                Описание проблемы
              </h3>
              <p className="text-[#6B7280]">
                {info.project?.description_of_problem}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <h3 className="text-primary font-semibold text-xl">Инновации</h3>
              <p className="text-[#6B7280]">
                {info.project?.innovation}
              </p>
            </div>
            <div className="flex flex-col">
              <h3 className="text-primary font-semibold text-xl">Вложения</h3>
            </div>
          </div>

          <div className="flex flex-col w-[50%] gap-[42px]">
            <div className="flex flex-col gap-3">
              <h3 className="text-primary font-semibold text-xl">Решения</h3>
              <p className="text-[#6B7280]">
                {info.project?.solution}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <h3 className="text-primary font-semibold text-xl">Ресурсы</h3>
              <p className="text-[#6B7280]">
                {info.project?.tools}
              </p>
            </div>
            <div className="flex flex-col">
              <dd className="text-sm text-gray-900 w-full">
                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate text-primary">
                        {documentName}
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href={`https://api.upshift.colibri.tj/public/storage/${info.project?.documents}`}
                        className="font-medium text-primary hover:text-secondary"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                  {/*<li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">*/}
                  {/*  <div className="flex w-0 flex-1 items-center">*/}
                  {/*    <PaperClipIcon*/}
                  {/*      className="h-5 w-5 flex-shrink-0 text-gray-400"*/}
                  {/*      aria-hidden="true"*/}
                  {/*    />*/}
                  {/*    <span className="ml-2 w-0 flex-1 truncate text-primary">*/}
                  {/*      coverletter_back_end_developer.pdf*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*  <div className="ml-4 flex-shrink-0">*/}
                  {/*    <a*/}
                  {/*      href="/#"*/}
                  {/*      className="font-medium text-primary hover:text-secondary"*/}
                  {/*    >*/}
                  {/*      Download*/}
                  {/*    </a>*/}
                  {/*  </div>*/}
                  {/*</li>*/}
                </ul>
              </dd>
            </div>
          </div>
        </div>
        <EditModal setShowEdit={setShowEdit} showEdit={showEdit} id={id}/>
      </div>

    </div>
  );
};

export default ProjectInfo;
