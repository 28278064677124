import {useEffect, useState} from "react";
import {ReactComponent as DownloadIcon} from "../../assets/icons/download-icon.svg";
import CityReportsTable from "../../components/tables/cityReportsTable/CityReportsTable";
import {useGetCityReportQuery} from "../../redux/api/reportsApi";
import {useDispatch, useSelector} from "react-redux";
import {setStartDate, setEndDate} from "../../redux/slices/cityReportSlice";
import html2pdf from "html2pdf.js";

const CityReports = () => {
  const dispatch = useDispatch()
  const {endDate, startDate} = useSelector(state => state.CityReport)

  const [cityReports, setCityReports] = useState([])
  const [showButton, setShowButton] = useState(true)

  const {data: cityReportsData, isLoading} = useGetCityReportQuery({startDate, endDate})
  const handleConvertToPdf = (e) => {
    e.stopPropagation()
    const element = document.getElementById('city-report')
    if (element) {
      setShowButton(false)
      html2pdf().from(element).save('city-report.pdf')
    }
  }

  useEffect(() => {
    if (isLoading) {
      setCityReports([])
    }
    if (!isLoading && cityReportsData) {
      setCityReports(cityReportsData)
    }
  }, [startDate, endDate, isLoading, cityReportsData])
  // const CityReportsPDF = ({ cityReports }) => {
  //   return (
  //     <PDFViewer width={600} height={800}>
  //       <Page>
  //         <div className="flex flex-col h-full" id='city-report'>
  //           <div className="flex flex-col gap-4 mb-4 "  >
  //             <h1 className="ml-3 font-semibold text-primary text-2xl">
  //               Отчет по городам
  //             </h1>
  //             <div className="flex items-center justify-between">
  //               <div className="ml-3 flex gap-4 items-center">
  //                 <p id='elementToShow' className='hidden'>{startDate}</p>
  //                 <p id='elementToShow' className='hidden'>{endDate}</p>
  //               </div>
  //               <div className="flex gap-4 items-center">
  //               </div>
  //             </div>
  //           </div>
  //           <CityReportsTable reports={cityReports} />
  //         </div>
  //       </Page>
  //     </PDFViewer>
  //   );
  // };

  // const handleConvertToPdf = () => {
  //   const pdfBlob = async () => {
  //     try {
  //       const blob = await pdf(<CityReportPDF startDate={startDate} endDate={endDate}
  //                                             cityReports={cityReports}/>).toBlob()
  //       saveAs(blob, 'city-report.pdf');
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   };
  //   pdfBlob()
  // }


  return (
    <div className="flex flex-col h-full" id='city-report' onClick={() => setShowButton(true)}>
      <div className="flex flex-col gap-4 mb-4 ">
        <h1 className="ml-3 font-semibold text-primary text-2xl">
          Отчет по городам
        </h1>
        <div className="flex items-center justify-between">
          <div className="ml-3 flex gap-4 items-center">
            {showButton && <input
              type="date"
              value={startDate}
              id='elementToHide'
              onChange={e => dispatch(setStartDate(e.target.value))}
              className="block w-[50%]  min-w-[235px] rounded-md border-0 text-[25px] py-[11px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset"
            />}
            {!showButton && <p id='elementToShow'>{startDate}</p>}
            {showButton && <input
              type="date"
              value={endDate}
              id='elementToHide'
              onChange={e => dispatch(setEndDate(e.target.value))}
              className="block w-[50%]  min-w-[235px] rounded-md border-0 text-[25px] py-[11px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset"
            />}
            {!showButton && <p id='elementToShow'>{endDate}</p>}
          </div>
          <div className="flex gap-4 items-center">
            {showButton && <button id='elementToHide' onClick={handleConvertToPdf}
                     className="flex items-center gap-3 bg-[#EEF2FF] hover:bg-[#f5f7fc] rounded-md py-[9px] px-[15px]">
              <DownloadIcon/>
              <span className="font-medium text-primary">Скачать отчет</span>
            </button>}
          </div>
        </div>
      </div>
      <CityReportsTable reports={cityReports}/>
      {/*{totalReports > 8 && (*/}
      {/*  <Pagination*/}
      {/*    totalPage={totalReports / 8}*/}
      {/*    setCurrentPage={setCurrentPage}*/}
      {/*    totalObjects={totalReports}*/}
      {/*    to={8}*/}
      {/*    from={1}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default CityReports;
