import {useState, useEffect, useContext, useCallback} from "react";
import {ReactComponent as MagnifyingGlassIcon} from "../../assets/icons/search-icon.svg";
import ProjectsTable from "../../components/tables/projectsTable/ProjectsTable";
import Pagination from "../../components/pagination/Pagination";
import Modal from "../../components/modals/modal/Modal";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import axiosInstance from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from 'moment'
import {debounce} from "lodash";
import {useGetSearchProjectsQuery} from "../../redux/api/projectsApi";


const Projects = () => {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [project, setProject] = useState({});
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [totalProjects, setTotalProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [value, setValue] = useState('');
  const [roles, setRoles] = useState([])
  const [searchData, setSearchData] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
  },[])



  const handleChangeSearch = useCallback(
    debounce(str => {
      setSearch(str)
    }, 400), []
  )

  const changeSearch = (e) => {
    setValue(e.target.value)
    handleChangeSearch(e.target.value)
  }

  const {token} = useContext(AuthContext);

  const getProjectsByPage = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/projects?page=${currentPage}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      setProjects(response?.data.data);
      setTotalProjects(response?.data.meta.total);
      setTo(response.data.meta.to);
      setFrom(response.data.meta.from);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  }, [currentPage, token]);

  useEffect(() => {
    getProjectsByPage();
  }, [getProjectsByPage]);

  const {data: searchProjectsData, isLoading: searchLoading} = useGetSearchProjectsQuery(search)

  useEffect(() => {
    if(searchLoading) {
      setSearchData([])
    }
    if(!searchLoading && searchProjectsData) {
      setSearchData(searchProjectsData.data)
    }
  },[searchLoading, searchProjectsData])
  const updateProject = async (data, id) => {
    try {
      await axiosInstance.put(`project/${id}`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(true);
      getProjectsByPage();
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  };

  const addProject = async (data) => {
    try {
      setIsLoading(true)
      await axiosInstance.post("project", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOpen(false);
      setIsLoading(true);
      getProjectsByPage();
      setIsLoading(false)
    } catch (error) {
      if (error.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem("token");
        window.location.reload();
      }
      setIsLoading(false)
    }
  };

  const addMemberToProj = (member) => {
  };

  const handleAddBtn = () => {
    setProject({
      id: "",
      name: "",
      location: "Душанбе",
      status: "планирование",
      user_id: "",
      partner_id: "",
      start: "",
      end: "",
      background_of_problem: "",
      description_of_problem: "",
      solution: "",
      innovation: "",
      equipment: "",
      transport_costs: "",
      services: "",
      rent: "",
      raw_materials: "",
      other: "",
      resource: "",
      media_file: "",
      documents: ""
    });
    setOpen(true);
    setModalType("adding");
  };
  const handleEditBtn = (data) => {
    data.start = moment(data.start).format('YYYY-MM-DD')
    data.end = moment(data.end).format('YYYY-MM-DD')
    setProject(data);
    setOpen(true);
    setModalType("updating");
  };

  const handleAddMemberBtn = (id) => {
    setOpen(true);
    setModalType("memberToProj");
  };



  const onSubmitForm = async (data) => {
    setIsLoading(true)
    if (modalType === 'updating') {
      await axiosInstance.put("/projects", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data"
        },
      });
    } else {
      await axiosInstance.post("/projects", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data"
        },
      });
    }
    getProjectsByPage();
    setIsLoading(false)
    setOpen(false)
  }

  return (
    <div className="relative">
      <h1 className="font-semibold text-primary text-2xl">Проекты</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4 ">
        <div className={roles.includes('store-project') ? "relative w-[90%]" : 'relative w-[100%]'}>
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <input
            id="search-field"
            className="block flex-auto max-w-full h-full w-full border-0 py-3 pl-3.5 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-primary sm:text-sm rounded-md"
            placeholder="По названию"
            type="search"
            name="search"
            value={value}
            onChange={changeSearch}
          />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {roles.includes('store-project') && <button
          onClick={handleAddBtn}
          className="flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>}
        <Modal
          handleSaveBtn={
            modalType === "updating"
              ? updateProject
              : modalType === "adding"
                ? addProject
                : addMemberToProj
          }
          formName={
            modalType === "updating" || modalType === "adding"
              ? "projects"
              : "membersToProj"
          }
          loading={isLoading}
          modalType={modalType}
          onSubmitForm={onSubmitForm}
          open={open}
          setOpen={setOpen}
          setData={setProject}
          data={project}
        />
      </div>
      {search.length > 3 ?
        <ProjectsTable projects={searchData} roles={roles} handleEditBtn={handleEditBtn}/>
        :
        !isLoading && projects.length > 0 ? (
        <ProjectsTable
          roles={roles}
          handleEditBtn={handleEditBtn}
          handleAddMemberBtn={handleAddMemberBtn}
          projects={projects}
        />
      ) : !isLoading && projects.length === 0 ? (
        <p>Таблица пуста</p>
      ) : (
        <div className="flow-root">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <Skeleton height={40} count={10}/>
            </div>
          </div>
        </div>
      )}

      {totalProjects > 8 && (
        <Pagination
          totalPage={totalProjects / 8}
          setCurrentPage={setCurrentPage}
          totalObjects={totalProjects}
          to={to}
          from={from}
        />
      )}
    </div>
  );
};

export default Projects;
