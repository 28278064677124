import DeleteModal from "../modals/DeleteModal";
import {useState} from "react";


export const AddToCourseNewTable = ({full_name, address, phone, sex, year_of_birth, group_id, id}) => {
  const [showDelete, setShowDelete] = useState(false)
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
        {full_name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {address}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        +992 {phone}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
        <span className="text-[#6B7280]">{sex}</span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
                        <span className="text-[#6B7280]">
                          {year_of_birth}
                        </span>
      </td>
      <td className="relative curs whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
        <span className='cursor-pointer'>
          <DeleteModal member_id={id} group_id={group_id} showDelete={showDelete} setShowDelete={setShowDelete}/>
        </span>
      </td>
    </tr>
  )
}
