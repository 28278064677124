import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {FormProvider, useForm} from "react-hook-form";
import {FirstStep} from "./FirstStep";
import {SecondStep} from "./SecondStep";
import {ThirdStep} from "./ThirdStep";
import {FourthStep} from "./FourthStep";
import {useDispatch, useSelector} from "react-redux";
import {setDocsFromBack, setSelectedDocs, setSelectedPhoto} from "../../../../redux/slices/EditProjectSlice";
import {useEditDocMutation, useEditPhotoMutation, useEditProjectMutation} from "../../../../redux/api/editProjectApi";
import {useGetOneProjectQuery} from "../../../../redux/api/projectsApi";
import {useGetAllMentorsQuery} from "../../../../redux/api/staffApi";
import {useGetAllPartnersQuery} from "../../../../redux/api/partnersApi";

export const EditModal = ({showEdit, setShowEdit, id}) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [projInfo, setProjInfo] = useState([])
  const [editLoading, setEditLoading] = useState(false)
  const {selectedPhoto, selectedDocs} = useSelector(state => state.EditProject)
  const [roles, setRoles] = useState([])
  const {data: getProjInfo, isLoading: projLoading} = useGetOneProjectQuery(id)

  const [mentors, setMentors] = useState([])
  const [partners, setPartners] = useState([])
  const {data: users, isLoading: usersLoading} = useGetAllMentorsQuery()
  const {data: partnersData, isLoading: partnersLoading} = useGetAllPartnersQuery()

  useEffect(() => {
    if (!usersLoading && users) {
      setMentors(users?.data)
    }
    if (!partnersLoading && partnersData) {
      setPartners(partnersData?.data)
    }
  }, [usersLoading, users, partnersLoading, partnersData])

  const methods = useForm()

  const {setValue} = methods

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setRoles(user.permissions)
    if (!projLoading && getProjInfo) {
      setProjInfo(getProjInfo?.data)
    }
  }, [projLoading, getProjInfo])

  useEffect(() => {
    setValue('name', projInfo?.project?.name)
    setValue('city_id', String(projInfo?.project?.city?.id))
    setValue('status', projInfo?.project?.status)
    setValue('user_id', String(projInfo?.project?.mentor?.id))
    setValue('partner_id', String(projInfo?.project?.partner?.id))
    setValue('start', projInfo?.project?.start)
    setValue('end', projInfo?.project?.end)
    setValue('background_of_problem', projInfo?.project?.background_of_problem)
    setValue('description_of_problem', projInfo?.project?.description_of_problem)
    setValue('solution', projInfo?.project?.solution)
    setValue('innovation', projInfo?.project?.innovation)
    setValue('equipment', projInfo?.project?.equipment)
    setValue('transport_costs', projInfo?.project?.transport_costs)
    setValue('services', projInfo?.project?.services)
    setValue('rent', projInfo?.project?.rent)
    setValue('raw_materials', projInfo?.project?.raw_materials)
    setValue('other', projInfo?.project?.other)
    setValue('tools', projInfo?.project?.tools)
  }, [setValue, projInfo, showEdit, mentors, partners])

  const onClose = () => {
    setShowEdit(false)
    dispatch(setSelectedPhoto(null))
    dispatch(setSelectedDocs(null))
    dispatch(setDocsFromBack(true))
    setPage(1)
    methods.reset()
  }

  const [editProject] = useEditProjectMutation()
  const [editPhoto] = useEditPhotoMutation()
  const [editDoc] = useEditDocMutation()

  const onSubmit = async (data) => {
    const photoFormData = new FormData()
    photoFormData.append('media_file', selectedPhoto)

    const docFormData = new FormData()
    docFormData.append('documents', selectedDocs)
    //
    // formData.append('name', data.name)
    // formData.append('description_of_problem', data.description_of_problem)
    // formData.append('background_of_problem', data.background_of_problem)
    // formData.append('end', data.end)
    // formData.append('equipment', data.equipment)
    // formData.append('innovation', data.innovation)
    // formData.append('city_location', data.city_id)
    // formData.append('other', data.other)
    // formData.append('partner_id', data.partner_id)
    // formData.append('raw_materials', data.raw_materials)
    // formData.append('rent', data.rent)
    // formData.append('resource', data.resource)
    // formData.append('services', data.services)
    // formData.append('solution', data.solution)
    // formData.append('start', data.start)
    // formData.append('status', data.status)
    // formData.append('transport_costs', data.transport_costs)
    // formData.append('user_id', data.user_id)
    try {
      setEditLoading(true)
      await editProject({id, ...data}).unwrap()
      if (selectedPhoto) {
        await editPhoto({id, photoFormData}).unwrap()
      }
      if (selectedDocs) {
        await editDoc({id, docFormData}).unwrap()
      }
      setShowEdit(false)
      setEditLoading(false)
      setPage(1)
      window.location.reload()
    } catch (err) {
      alert('Ошибка при изменении проекта')
      console.log(err)
      setEditLoading(false)
    }
  }


  return (
    <Transition.Root show={showEdit} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[907px]">

                <div className="bg-white  ">
                  <div className="sm:flex sm:items-start sm:flex-col">
                    <h2 className="text-primary font-medium text-lg px-6 py-3">
                      Изменение проекта
                    </h2>
                    <FormProvider {...methods}>
                      <form className="flex flex-col w-full" onSubmit={methods.handleSubmit(onSubmit)}
                            encType="multipart/form-data">
                        {page === 1 && (
                          <FirstStep
                            setPage={setPage}
                            onClose={onClose}
                            {...projInfo?.project}
                          />
                        )}
                        {page === 2 && (
                          <SecondStep
                            setPage={setPage}
                            project={projInfo?.project}
                            {...projInfo?.project}
                            mentors={mentors}
                            partners={partners}
                          />
                        )}
                        {page === 3 && (
                          <ThirdStep
                            setPage={setPage}
                            {...projInfo?.project}
                          />
                        )}
                        {page === 4 && (
                          <FourthStep
                            editLoading={editLoading}
                            setPage={setPage}
                            onClose={onClose}
                            {...projInfo?.project}
                          />
                        )}
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}