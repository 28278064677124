import {ReactComponent as EditIcon} from "../../../assets/icons/edit-icon.svg";
import {useState} from "react";
import {EditModal} from "./EditModal";

export const SubjectsTable = ({name, direction, direction_id, refetch, id, roles}) => {

  const [showEdit, setShowEdit] = useState(false)

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-primary sm:pl-6 text-left">
        {name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-[#6B7280] text-center">
        {direction}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-end text-sm sm:pr-6">
        {roles.includes('update-subject') && <button
          onClick={() => setShowEdit(true)}
          className="text-primary hover:text-secondary"
        >
          <EditIcon/>
        </button>}
        <EditModal id={id} direction_id={direction_id} refetch={refetch} title={name} showEdit={showEdit} setShowEdit={setShowEdit}/>
      </td>
    </tr>
  );
};
