import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  tagTypes: 'Reports',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.upshift.colibri.tj/api/',
    prepareHeaders: (headers, {getState}) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers;
    },
  }),
  endpoints: build => ({
    getCityReport: build.query({
      query: (params) => `report/city?from_date=${params.startDate}&to_date=${params.endDate}`,
    }),
    getDirectionReport: build.query({
      query: (params) => `report/direction?from_date=${params.startDate}&to_date=${params.endDate}`
    }),
    getSubjectReport: build.query({
      query: (params) => `report/subject?from_date=${params.startDate}&to_date=${params.endDate}`
    })
  })
})

export const {useGetCityReportQuery, useGetDirectionReportQuery, useGetSubjectReportQuery} = reportsApi;
