import React from "react";
import { ReactComponent as AvatarIcon } from "../../../assets/icons/avatar-icon.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icons/image-icon.svg";
import { ReactComponent as Steps } from "../../../assets/img/firstSteps.svg";
import { Transition } from "@headlessui/react";
import {locations} from "../../hardCodedArrays/Arrays";

const commentInput = React.createRef();

const ProjectFormFirstStep = ({
  register,
  data,
  setData,
  errors,
  selectedImg,
  setSelectedImg,
  selectedDoc,
  setSelectedDoc,
  modalType
}) => {
  const statuses = [
    "планирование",
    "исследование",
    "создание",
    "тестирование",
    "завершение",
  ];


  const { name, city_id, status } = data;
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const clearDoc = () => {
    setSelectedDoc("")
    setData({...data, documents: ""})
    commentInput.current.value = ''
  };
  const changePhoto = (e) => {
    setSelectedImg(e.target.files[0])
    setData({...data, media_file: e.target.files[0]})
  }
  const changeDoc = (e) => {
    setSelectedDoc(e.target.files[0])
    setData({...data, documents: e.target.files[0]})
  }

  const inputTextStyle =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm";
  const errInputText =
    "block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm";
  return (
    <Transition
      appear={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="col-span-full mt-6">
        <label
          htmlFor="projectTeamAvatar"
          className="block text-sm font-medium text-[#374151]"
        >
          Фото команды
        </label>
        <div className="mt-1 flex items-end gap-5">
          {data.media_file ? (
            <img
              className="w-[48px] h-[48px] object-cover rounded-md"
              src={modalType === 'adding' ? URL.createObjectURL(data.media_file) : data.media_file}
              alt="team-avatar"
            />
          ) : (
            <AvatarIcon />
          )}
          <label
            className="cursor-pointer rounded-md bg-white px-[13px] py-[9px] text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            htmlFor="photo"
          >
            {data.media_file ? "Изменить" : "Выбрать"}
          </label>
          <input
            {...register("photo")}
            onChange={changePhoto}
            type="file"
            id="photo"
            name="photo"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            className="hidden"
          />
        </div>
      </div>
      <div className="flex items-center gap-6 mt-6 sm:w-full">
        <div className="w-[33%]">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-[#374151]"
          >
            Название проекта
          </label>
          <div className="mt-1 relative">
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={name}
              {...register("name")}
              className={errors.name ? errInputText : inputTextStyle}
            />
            {errors.name && (
              <p className="text-xs text-red-600 absolute bottom-[-22px]">
                {errors.name.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-[33%] flex flex-col gap-1 relative">
          <label
            htmlFor="projectLocation"
            className="block text-sm font-medium text-[#374151]"
          >
            Местоположение
          </label>
          <select
            defaultValue={city_id}
            {...register("city_id")}
            className={errors.city_id ? errInputText : inputTextStyle}
          >
            <option value='' hidden>Выберите местоположение</option>
            {locations
              .map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {capitalizeFirstLetter(item.name)}
                  </option>
                );
              })}
          </select>
          {errors.city_id && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.city_id.message}
            </p>
          )}
        </div>
        <div className="w-[33%] flex flex-col gap-1 relative">
          <label
            htmlFor="status"
            className="block text-sm font-medium text-[#374151]"
          >
            Статус
          </label>
          <select
            id="status"
            name="status"
            defaultValue={status}
            {...register("status")}
            className={errors.status ? errInputText : inputTextStyle}
          >
            <option value={status}>{capitalizeFirstLetter(status)}</option>
            {statuses
              .filter((item) => item !== status)
              .map((item, i) => {
                return (
                  <option key={i} value={item}>
                    {capitalizeFirstLetter(item)}
                  </option>
                );
              })}
          </select>
          {errors.status && (
            <p className="text-xs text-red-600 absolute bottom-[-22px]">
              {errors.status.message}
            </p>
          )}
        </div>
      </div>
      <div className="col-span-full mt-[26px] sm:w-full">
        <p className="block text-sm font-medium text-[#374151]">Вложения</p>
        <label htmlFor="projectAttachments">
          <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-[#D1D5DB] px-6 py-[7px] cursor-pointer">
            <div className="text-center">
              <ImageIcon
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
              <div>
                {!data.documents ? (
                  <div className="flex justify-center text-sm">
                    <label
                      htmlFor="projectAttachments"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-primary text-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-secondary"
                    >
                      <span>Выберите</span>
                    </label>
                    <span className="pl-1 text-[#4B5563] font-medium text-sm">
                      файл
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className="text-[#4B5563] font-medium text-sm">
                      Файл загружен!
                    </p>
                    <p className="text-[#4B5563] font-medium text-sm">
                      {data.documents.name}
                    </p>
                  </div>
                )}
              </div>
              <input
                id="projectAttachments"
                name="projectAttachments"
                type="file"
                ref={commentInput}
                onChange={changeDoc}
                className="sr-only"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
              />
              <span className="text-xs text-[#6B7280]">
                DOC, XLSX, PPTX, PDF до 10MB
              </span>
            </div>
          </div>
        </label>
        {data.documents ? (
          <p
            className="inline-block cursor-pointer mt-4 rounded-md bg-white px-[13px] py-[9px] text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={clearDoc}
          >
            Удалить выбранный документ
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="flex items-center justify-center w-full mt-[55px]">
        <Steps />
      </div>
    </Transition>
  );
};

export default ProjectFormFirstStep;
