import {useGetAllMembersQuery, useGetSearchMembersQuery} from "../../redux/api/membersApi";
import {useEffect, useState} from "react";
import {ReactComponent as MagnifyingGlassIcon} from "../../assets/icons/search-icon.svg";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import {AddToCourseTable} from "./components/AddToCourseTable";
import Select from "react-select";
import {useAddMemberToCourseMutation, useGetCoursesQuery, useGetOneCourseQuery} from "../../redux/api/coursesApi";
import {useParams} from "react-router-dom";


export const AddToCoursePage = () => {
  const {id} = useParams()
  const [search, setSearch] = useState('')
  const [students, setStudents] = useState([])
  const [searchStudents, setSearchStudents] = useState([])
  const [inputStuds, setInputStuds] = useState([])
  const [courseMembers, setCourseMembers] = useState([])
  const {data: membersData, isLoading: membersLoading} = useGetAllMembersQuery()
  const {data: searchData, isLoading: searchLoading} = useGetSearchMembersQuery({search})
  const {data: oneCourse, isLoading: oneCourseLoading, refetch: courseListRefetch} = useGetOneCourseQuery(id)
  const {refetch: groupsRefetch} = useGetCoursesQuery({page: 1})
  const [addMember, {isLoading: addMemberLoading}] = useAddMemberToCourseMutation()

  useEffect(() => {
    if(membersLoading) {
      setStudents([])
    }
    if(searchLoading) {
      setSearchStudents([])
    }
    if(!membersLoading && membersData) {
      const memObj = membersData.data.map(item => ({label: item.member.full_name, value: item.member.id}))
      setStudents(memObj)
    }
    if(!searchLoading && searchData) {
      const memObj = searchData.data.map(item => ({label: item.member.full_name, value: item.member.id}))
      setSearchStudents(memObj)
    }
    if(oneCourseLoading) {
      setCourseMembers([])
    }
    if(!oneCourseLoading && oneCourse) {
      setCourseMembers(oneCourse.data.group.members)
    }
  },[search, searchLoading, membersLoading, searchData, membersData, oneCourseLoading, oneCourse])

  const filteredOptions = students.filter(option => {
    return !courseMembers.some(item => item.member.id === option.value)
  })

  const filteredSearch = searchStudents.filter(option => {
    return !courseMembers.some(item => item.member.id === option.value)
  })

  const onSubmit = async () => {
    const result = {
      member_ids: inputStuds.map(item => item.value)
    }
    try {
      await addMember({id: id, ...result})
      setInputStuds([])
      courseListRefetch()
      groupsRefetch()
    } catch (err) {
      alert('Ошибка при добавлении участника в группу')
    }
  }

  return (
    <div>
      <h1 className="font-semibold text-primary text-2xl">Добавление в группу</h1>
      <div className="mt-4 flex gap-4 flex-1 mb-4">
        <div className="relative w-[90%]">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <Select
            placeholder='Поиск'
            isMulti
            options={search.length > 3 ? filteredSearch : filteredOptions}
            onChange={setInputStuds}
            inputValue={search}
            value={inputStuds}
            onInputChange={setSearch}
            />
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 top-0 right-[60px] pb-1 mr-3.5 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <button onClick={onSubmit} disabled={inputStuds.length === 0 || addMemberLoading}
          className={inputStuds.length === 0 || addMemberLoading ? " opacity-50 flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md" :" opacity-100 flex items-center justify-center flex-1 py-[9px] px-4 gap-3 bg-primary rounded-md hover:bg-secondary"}
        >
          <PlusIcon/>
          <span className="text-white text-base font-medium">Добавить</span>
        </button>
      </div>
        <AddToCourseTable
          members={courseMembers}
          group_id ={id}
        />

      {/*{totalMembers > 8 && (*/}
      {/*  <Pagination*/}
      {/*    setCurrentPage={setCurrentPage}*/}
      {/*    totalPage={totalMembers / 8}*/}
      {/*    totalObjects={totalMembers}*/}
      {/*    to={to}*/}
      {/*    from={from}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  )
}