import React from "react";
import {useFormContext} from "react-hook-form";

export const NumberInput = ({name, required_message, default_value}) => {
  const {register, formState: {errors}} = useFormContext()
  return (
    <>
      <input
        type="number"
        min="0"
        className='inputTextStyle'
        defaultValue={default_value}
        {...register(name,
          {
            required: `${required_message}`
          })}
      />
      {errors[name] && (
        <p className="text-xs text-red-600 absolute bottom-[-22px]">
          {errors[name].message}
        </p>
      )}
    </>
  )
}